import React, { useState, useEffect, useRef } from "react";
import "./BranchesAdmin.css";
import "../MainStyle.css";
import { Button, Input, Popconfirm, Tooltip, Table, notification, Space, Spin, Select, Badge, Dropdown } from "antd";
import Icon, { PlusSquareOutlined, EditOutlined, DeleteOutlined, BarsOutlined, SearchOutlined, EllipsisOutlined } from "@ant-design/icons";
import $ from 'jquery'
import { registerLocale } from "react-datepicker";
import Highlighter from 'react-highlight-words';
import moment from "moment";
import urlAPI from "../urlAPI/urlAPI.json";
import es from "date-fns/locale/es";
import { userService } from "../services/services";
import { useNavigate } from 'react-router-dom';
import imgLogo from '../../img/favicon.png';
import imgTimbra from '../../img/timbra.png'
import DrawerClient from "./DrawerClient";
import dayjs from "dayjs";
import ModalMessage from "./ModalMessage";
import ModalHist from "./ModalHist";
import DrawerConfronta from "./DrawerConfronta";
import BrancesAbrhil from "./BrancesAbrhil";
registerLocale("es", es);

function ContracsTable(){
  
  const [itemsBranches, setItemsBranches] = useState([]);
  const [itemsBranchesAbrhil, setItemsBranchesAbrhil] = useState([]);
  const [branchMovement, setBranchMovement] = useState([]);
  const [itemsCompanies, setItemsCompanies] = useState([]);
  const [itemsRelBranchRFC, setItemsRelBranchRFC] = useState([]);
  const [itemsYearMonth, setItemsYearMonth] = useState([]);

  //Modales
  const [openModNuevo, setOpenModNuevo] = useState(false);
  const [openModEditBranchAbr, setOpenModEditBranchAbr] = useState(false);
  const [openModComment, setOpenModComment] = useState(false);
  const [openModHistMove, setOpenModHistMove] = useState(false);
  const [openModConfront, setOpenModConfront] = useState(false);
  const [openModYearMonths, setOpenModYearMonths] = useState(false);
  const [openModBranchesAbrhil, setOpenModBranchesAbrhil] = useState(false);

  // Variables del la tabla catBranch
  const [_idSucursal,set_idSucursal] = useState("");
  const [_descripcion,set_descripcion] = useState("");
  const [_alias,set_alias] = useState("");
  const [_inicioContrato,set_inicioContrato] = useState(dayjs());
  const [_finContrato,set_finContrato] = useState(dayjs());
  const [_numEmpleados,set_numEmpleados] = useState("");
  const [_maxEmpleados,set_maxEmpleados] = useState("");
  const [_maxEmpleadosBefore,set_maxEmpleadosBefore] = useState("");
  const [_bloqueo,set_bloqueo] = useState(0);
  const [_activo,set_activo] = useState(true);
  const [_notifMensual,set_notifMensual] = useState(true);
  const [_plusList,set_plusList] = useState(true);
  const [_pais,set_pais] = useState("MEX");
  const [_idioma,set_idioma] = useState("ES");
  const [_noLimit,set_noLimit] = useState(false);
  const [_rfcEmisor,set_rfcEmisor] = useState("");
  const [_localApiUrl,set_localApiUrl] = useState("");
  const [_realDemoBranch,set_realDemoBranch] = useState("1");

  // Variables para modificar sucursalAbrhil
  const [_idSucursalAbr,set_idSucursalAbr] = useState("");
  const [_clvSucursalAbr,set_clvSucursalAbr] = useState("");
  const [_nombreAbr,set_nombreAbr] = useState("");
  const [_inicioContratoAbr,set_inicioContratoAbr] = useState("");
  const [_finContratoAbr,set_finContratoAbr] = useState("");
  const [_maxEmpleadosAbr,set_maxEmpleadosAbr] = useState("");
  const [_bloqueoAbr,set_bloqueoAbr] = useState("");
  const [_activoAbr,set_activoAbr] = useState(true);
  
  // 0=Sin programación 1= programado 2= en instalación 3=Liberado
	const [_statusProject, set_statusProject] = useState(0);
  // 0=Acumulado 1=Fijo con acumulado 2=Fijo sin acumulado
	const [_typeInvoice, set_typeInvoice] = useState(0);
  //
	const [_invoiceComent, set_invoiceComent] = useState('');
  const [_requireComent, set_requireComent] = useState(false)

  // Variables para verificar si hubo cambios
  const [_origInicioContrato,set_origInicioContrato] = useState("");
  const [_origFinContrato,set_origFinContrato] = useState("");
  const [_origMaxEmpleados,set_origMaxEmpleados] = useState("");
  const [_origBloqueo,set_origBloqueo] = useState("");
  const [_comment,set_comment] = useState("");
  const [_folioHelpDesk,set_folioHelpDesk] = useState("");
  const [_dataChanged,set_dataChanged] = useState(0);

  // Variable para requerir el folio de HelpDesk

  const [_folioRequired,set_folioRequired] = useState(false);

  //valores para Confronta
  const [_idConfrontBranchRFC,set_idConfrontBranchRFC] = useState("");
  const [_confrontRFC,set_confrontRFC] = useState("");
  const [_confrontActiveAll,set_confrontActiveAll] = useState(false);
  const [_timbraActiveAll,set_timbraActiveAll] = useState(false);
  const [_confrontActiveRow,set_confrontActiveRow] = useState(false);
  const [_timbraActiveRow,set_timbraActiveRow] = useState(false);
  const [_idBranchForConfront,set_idBranchForConfront] = useState("");
  const [_idBranForConfrontAux,set_idBranForConfrontAux] = useState("");
  const [_years,set_years] = useState("");
  const [_months,set_months] = useState("");

  const [_yearDisabled,set_yearDisabled] = useState(true);
  const [_saveYearMonthDisabled,set_saveYearMonthDisabled] = useState(true);

  // const [_noDataConfront,sett [_noDataConfront] = useState(false);

  const [_idRelBranchRFC,set_idRelBranchRFC] = useState("");


  // Variables para ligar mensaje
  const [_itemsMsg, set_itemsMsg] = useState([]);
  const [_asociateMsg, set_asociateMsg] = useState([])
  const [_idRFC, set_idRFC] = useState('');
  const [_modalMsg, set_modalMsg] = useState(false);
  const [_statusAsociateMsg, set_statusAsociateMsg] = useState(0);
  
  // VARIABLES PARA MOSTRAR MENSAJES
  const [respMessage, setRespMessage ] = useState("");
  const [colorMessage, setColorMessage ] = useState(0);

  // VARIABLE PARA GUARDAR EL VALOR DEL DROPDOWN SELECCIONADO
  const [_dropValueSelectedCorp, set_dropValueSelectedCorp ] = useState("");

  //PERMISOS DE USUARIO
  const [rights, setRights ] = useState([]);
  const [isEdit, setIsEdit ] = useState(false)

  //activar o no el botón nuevo en modal de años
  const [_activeNewYear, set_activeNewYear ] = useState(false);
  const [ _idYearMonth, set_idYearMonth ] = useState('');

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [loading, setLoading] = useState(false);

  const [statusEmployMax, setStatusEmployMax ] = useState('')

  const [itemsRFCNotAssigned, setItemsRFCNotAssigned] = useState([]);
  const [openDrawerRFC, setOpenDrawerRFC] = useState(false)

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

  function editarSucursalModal(props) {
      let urlAPI = '';
      switch(props.localApiUrl){
        case 'null': urlAPI=''; break;
        case 'undefined': urlAPI=''; break;
        default: urlAPI = props.localApiUrl; break;
      }
      
      set_idSucursal( props.idBranch);
      set_descripcion( props.description);
      set_alias( props.alias);
      set_inicioContrato( props.startDate);
      set_finContrato( props.endDate);
      set_numEmpleados( props.numEmployees);
      set_maxEmpleados( props.maxEmployees);
      set_maxEmpleadosBefore( props.maxEmpBefore);
      set_bloqueo( props.lock);
      set_activo( activoToBoolean(props.active));
      set_notifMensual( activoToBoolean(props.monthlyNotify));
      set_plusList( props.bListPlus);
      set_pais( props.country);
      set_idioma( props.language);
      set_rfcEmisor( props.rfcEmisor);
      set_localApiUrl( urlAPI );
      set_realDemoBranch( props.realDemoBranch);

      // Se guardan los valores originales para su futura comparación
      set_origInicioContrato( props.startDate);
      set_origFinContrato( props.endDate);
      set_origMaxEmpleados( props.maxEmployees);
      set_origBloqueo( props.lock);

      set_statusProject(props.statusProyect)
      set_typeInvoice(props.typeInvoice)
      set_invoiceComent(props.commentInvoice)

      setIsEdit(true)
      setOpenModNuevo(true)
    //showEditar();
  }

  /**
   * GET Sucursales de Abrhil
   */
  function getBranchesAbrhil(idSucursalAbr){
    
        let token = localStorage.getItem("x-access-token");
        setLoading(true)
        fetch(urlAPI.url + `branchesAbrhil/${idSucursalAbr}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .catch((err) => console.log(err))
        .then((res) => res.json())
        .then((json) => {
        if (json.status) {
            setItemsBranchesAbrhil(json.data[0])
        }
        setLoading(false)
        });
  };

  function editarSucursalAbrModal(props) {
    
    set_idSucursalAbr(props.idBranchAbrhil)
    set_clvSucursalAbr(props.clv_Sucursal)
    set_nombreAbr(props.nombre)
    set_inicioContratoAbr(props.startDate)
    set_finContratoAbr(props.endDate)
    set_maxEmpleadosAbr(props.maxEmployees)
    set_bloqueoAbr(props.lock)
    set_activoAbr(props.active)
      
    showEditBranchAbr();
  }

  function saveChangeBranchAbr(){
    
    let token = localStorage.getItem("x-access-token");
    let userLogged = localStorage.getItem("username");

    let nuevaSucursalAbr = {
      idBranchAbrhil: _idSucursalAbr,
      endDate: dayjs(_finContratoAbr).format("YYYYMMDD"),
      maxEmployees: _maxEmpleadosAbr,
      lock: _bloqueoAbr ? '1' : '0',
      active: _activoAbr ? '1' : '0',
      userCap: userLogged,
    };
    setLoading(true)
    fetch(urlAPI.url + `branchesAbrhil/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(nuevaSucursalAbr),
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          getBranchesAbrhil(_idSucursal)
					setRespMessage(json.data[0].mensaje)
					setColorMessage(tipoMensaje(json.data[0].numMensaje))
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        closeEditBranchAbr();
        limpiarMensaje();
        setLoading(false)
      });
  
  }
  
  function closeEditBranchAbr(){
    set_idSucursalAbr('');
    set_clvSucursalAbr('');
    set_nombreAbr('');
    set_inicioContratoAbr('');
    set_finContratoAbr('');
    set_maxEmpleadosAbr('');
    set_bloqueoAbr('');
    set_activoAbr('');

    set_statusProject(0);
    set_typeInvoice(0);
    set_invoiceComent('');

    setOpenModBranchesAbrhil(false)
  }

  // HANDLERS DE LOS INPUTS
  function descripcionHandler(e) {
    set_descripcion((e.target.value).toUpperCase())
  }

  function aliasHandler(e) {
    set_alias(e.target.value)
  }

	function rangeDays(data){
		set_inicioContratoAbr(dayjs(data[0]).format('YYYYMMDD'));
		set_finContratoAbr(dayjs(data[1]).format('YYYYMMDD'));
	}

	function rangeDaysClient(data){
		set_inicioContrato(dayjs(data[0]).format('YYYYMMDD'));
		set_finContrato(dayjs(data[1]).format('YYYYMMDD'));
	}

  function numEmployeesHandler(e) {
    set_numEmpleados(e.target.value)
  }

  function maxEmployeesHandler(e) {
    set_maxEmpleados(e.target.value)
  }

  function maxEmployeesHandlerAbr(e) {
    set_maxEmpleadosAbr(e.target.value)
  }

  function lockHandler(value) {
    set_bloqueo(value)
  }

  function lockHandlerAbr(value) {
    set_bloqueoAbr(value)
  }

  const handlerActiveAbr = (check) => {
    set_activoAbr(check)
  }

  function countryHndlr(value) {
    set_pais(value)
  }

  function languageHndlr(value) {
    set_idioma(value);
  }

  function realDemoBranchHndlr(value) {
    set_realDemoBranch(value);
  }

  function rfcEmisorHndlr(value) {
    set_rfcEmisor(value);
  }

  const localApiUrlHndlr = (e) => set_localApiUrl(e.target.value);

  const chkNotifyHndlr = (check) => {
    set_notifMensual(check);
  }

  const togglePlusList = (check) =>{
    set_plusList(check);
  }

  // CONCEPTOS
  function commentHndlr(e) {
    set_comment(e.target.value)
  }

  function folioHelpDeskHndlr(e) {
    set_folioHelpDesk(e.target.value)
  }

  const toggleActive = (check) =>{
    set_activo(check);
  }

  const stausProjectHandler = (selected) => set_statusProject(selected);
  const invoiceTypeHandler = (selected) => set_typeInvoice(selected);
  const invoiceComentHandler = (e) => set_invoiceComent(e.target.value);

  useEffect(()=>_typeInvoice === 2 ? set_requireComent(true) : set_requireComent(false),[_typeInvoice])  

  useEffect(()=>{

    if (_noLimit) {
      set_maxEmpleados("10000")
    } else {
      set_maxEmpleados("")
    }
  },[_noLimit])

  const toggleNoLimit = (check) => {
    set_noLimit(check);
  };

  const toggleConfrontActiveAll = (check) => {
    set_confrontActiveAll(check);
    postBranchConfrontAll(check);
  };

  const toggleTimbraActiveAll = (check) => {
    set_timbraActiveAll(check);
    postBranchTimbratAll(check);
  };
  
  function toggleConfrontActiveRow(data, active ){
    set_confrontActiveRow(active);
    postBranchConfront(data, active, 0);
  };

  function toggleTimbraActiveRow(data, active ){
    set_timbraActiveRow(active);
    postBranchConfront(data, active, 1);
  };

  function companyHandler(value) {
    set_dropValueSelectedCorp(value)
  }

  function mayusculas(e) {
    e.target.value = e.target.value.toUpperCase();
  }

  function yearHandler(value) {
    set_years(value)
  }

  useEffect(() => {
    loadBranches();
    corporativos();
    getMessages();
  },[])

  const modalMsgHandler = (id,idMsg,isBlock) => {
    set_modalMsg(true); 
    set_idRFC(id); 
    getMsgBranch(idMsg,isBlock);
  };

  function asignarMensaje(idRFC, idMsg, blockade){
    
    let token = localStorage.getItem("x-access-token");

    const data = {
      idconfrontBranchRFC:idRFC, 
      idMessage:idMsg,
      blockade:blockade ? 1 : 0
    }
    setLoading(true)

    fetch(urlAPI.url + `RFCMessage/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
					setRespMessage(json.data[0].mensaje)
					setColorMessage(tipoMensaje(json.data[0].numMensaje));
          set_modalMsg(false);
          getBranchRFC(_idBranchForConfront);
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        
        setLoading(false)
      });
  }

  function assingMsg(idMessage,blockade ){
    asignarMensaje(_idRFC, idMessage, blockade);
  }

  function unassignMsg(){
    asignarMensaje(_idRFC, 0, 0);
  }

  const blockadeMSg = (check, idMessage) => {
    set_statusAsociateMsg(check ? 1 : 0)
    asignarMensaje(_idRFC, idMessage, check);
  }

  function getMsgBranch(idMessage, status) {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `messages/${idMessage}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				set_asociateMsg(json.data[0]); 
        set_statusAsociateMsg(status ? true : false)
			} 
			setLoading(false)
		});
  }

  function getMessages(){
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'messagesTimbra/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				set_itemsMsg(json.data[0]);
			} 
			setLoading(false)
		});

  }

  function loadBranches(){

    let stringUserRights = localStorage.getItem("userRights");
    let userRights = JSON.parse(stringUserRights);
    
    setRights(userRights)

    let token = localStorage.getItem("x-access-token");
    setLoading(true)
    fetch(urlAPI.url + "branches/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          setItemsBranches(json.data[0])
        }
        setLoading(false)
      });
  }

  function corporativos() {
    let token = localStorage.getItem("x-access-token");
    setLoading(true)
    fetch(urlAPI.url + "companies/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.aut === undefined) {
          if (json.aut === false) logout();
          if (json.status) {
            let arrCompanies = [];
            let data = json.data[0];
            arrCompanies.push({
              value: 0,
              label: "Selecciona un corporativo",
            });

            data.forEach((element) => {
              arrCompanies.push({
                value: element.idCompany,
                label: element.codeClient,
              });
            });
            setItemsCompanies(arrCompanies)
          }
        } else {
          logout();
        }
        setLoading(false)
      });
  }

  function agregarSucursal() {
    
    let token = localStorage.getItem("x-access-token");
    let userLogged = localStorage.getItem("username");
    if(_descripcion === '' || _alias === '' ){
      setRespMessage('Los campos son requeridos')
      setColorMessage('error')
      return
    }
    if(_typeInvoice === 2 && _invoiceComent === ''){
      setRespMessage('Los campos son requeridos')
      setColorMessage('error')
      return
    }

    let nuevaSucursal = {
      idBranch: isEdit ? _idSucursal : "",
      description: _descripcion,
      alias: _alias,
      startDate: moment(_inicioContrato).format("YYYYMMDD"),
      endDate: moment(_finContrato).format("YYYYMMDD"),
      numEmployees: _numEmpleados,
      maxEmployees: _noLimit ? "10000" : _maxEmpleados,
      lock: _bloqueo,
      active: activoTrueFalse(_activo),
      monthlyNotify: activoTrueFalse(_notifMensual),
      listPlus: activoTrueFalse(_plusList),
      country: _pais,
      language: _idioma,
      dataChanged: _dataChanged,
      comment: _comment,
      folioHelpDesk: _folioRequired
        ? _folioHelpDesk
        : "Sin folio",
      userCap: userLogged,
      idCorp: _dropValueSelectedCorp,
      rfcEmisor: _rfcEmisor,
      realDemoBranch: _realDemoBranch,
      localApiUrl:_localApiUrl,
      statusProyect:_statusProject,
      typeInvoice:_typeInvoice,
      commentInvoice:_invoiceComent,
    };
    setLoading(true)
    fetch(urlAPI.url + `branches/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(nuevaSucursal),
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          loadBranches()
					setRespMessage(json.data[0].mensaje)
					setColorMessage(tipoMensaje(json.data[0].numMensaje))
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        closeNuevo();
        cleanEdit();
        limpiarMensaje();
        setLoading(false)
      });
  }

  function cleanEdit(){
    set_comment('')
    setStatusEmployMax('')
    setOpenModComment(false);
  }

  const activoTrueFalse = (campo) => {
    switch (campo) {
      case false: {
        return 0;
      }
      case true: {
        return 1;
      }
      default: {
        return 1;
      }
    }
  };

  const activoTrueFalseAllRFC = (campo) => {
    switch (campo) {
      case false: {
        return 0;
      }
      case true: {
        return 1;
      }
      default: {
        return 0;
      }
    }
  };

  const activoToBoolean = (campo) => {
    switch (campo) {
      case 0: {
        return false;
      }
      case 1: {
        return true;
      }
      default: {
        return true;
      }
    }
  };

  function eliminarSucursal(idSucursal) {
    
    let token = localStorage.getItem("x-access-token");
    let id = idSucursal;
    setLoading(true)
    fetch(urlAPI.url + `branches/${id}`, {
      method: "DELETE",
      headers: {
        "x-access-token": token,
      },
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
					setRespMessage(json.data[0].mensaje)
					setColorMessage(tipoMensaje(json.data[0].numMensaje))
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        loadBranches();
        closeEliminar();
        limpiarMensaje();
        setLoading(false)
      });
  }

  function branchMovementFunc(idBranch, description) {
    let token = localStorage.getItem("x-access-token");
    
    set_descripcion(description)
    setLoading(true)
    fetch(urlAPI.url + `branchMovement/${idBranch}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
    .catch((err) => console.log(err))
    .then((res) => res.json())
    .then((json) => {
      if (json.status) {
        setBranchMovement(json.data[0])
      } 
      setLoading(false)
    });
    showBranchMovement();
  }

  function branchConfrontFunc(idBranch, description) {
    set_idBranchForConfront(idBranch);
    set_idBranForConfrontAux(idBranch);
    set_descripcion(description)
    getBranchRFC(idBranch);
  }

  function getBranchRFC(idBranch) {
    let token = localStorage.getItem("x-access-token");
    setLoading(true)
    fetch(urlAPI.url + `branchConfront/${idBranch}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
    .catch((err) => console.log(err))
    .then((res) => res.json())
    .then((json) => {
      if (json.status) {
        setItemsRelBranchRFC(json.data[0])
        let datos = json.data[0]
        let inactive  = datos.filter((f)=>{
          return !f.active 
        }).length
        let inactiveTimbra = datos.filter((f)=>{
          return !f.activeTimbra 
        }).length
        inactive === 0 ? set_confrontActiveAll(true) : set_confrontActiveAll(false) 
        inactiveTimbra === 0 ? set_timbraActiveAll(true) : set_timbraActiveAll(false) 
      } 
      showBranchConfront();
      setLoading(false)
    });
  }

  function viewNotAssigned(){
    getRFCNotAssigned();
    setOpenDrawerRFC(true);
  }

  function closeViewNotAssigned(){
    setOpenDrawerRFC(false);
  }

  function getRFCNotAssigned(){
    let token = localStorage.getItem("x-access-token");
    setLoading(true)
    fetch(urlAPI.url + `BranchTimbraNotView/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
    .catch((err) => console.log(err))
    .then((res) => res.json())
    .then((json) => {
      if (json.status) {
        setItemsRFCNotAssigned(json.data[0])
      } 
      setLoading(false)
    });

  }

  function assingRFC(idRFC){
		
		let token = localStorage.getItem('x-access-token');
    
    if(_idBranchForConfront === '')return

		let newRelBranchRFC = {
			idconfrontBranchRFC: _idBranchForConfront,
      idRFC:idRFC
		};
    
		setLoading(true)
		fetch(urlAPI.url + 'relBranchRFC/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(newRelBranchRFC),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				getBranchRFC(_idBranchForConfront);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			closeViewNotAssigned();
			setLoading(false)
		});
  }

  function newYearMonth() {
    
    set_idYearMonth("")
    // _idConfrontBranchRFC: '',
    set_years("")
    set_months("")
    set_yearDisabled(false)
    set_saveYearMonthDisabled(false)
  }

  function showYearMonths(idConfrontBranchRFC, rfc, activeNewYear) {
    set_idConfrontBranchRFC(idConfrontBranchRFC)
    set_confrontRFC(rfc)
    set_activeNewYear(activeNewYear)
    getYearMonth(idConfrontBranchRFC);
  }

  function getYearMonth(idConfrontBranchRFC) {
    let token = localStorage.getItem("x-access-token");
    setLoading(true)
    fetch(urlAPI.url + `confrontYearMonthPerm/${idConfrontBranchRFC}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
    .catch((err) => console.log(err))
    .then((res) => res.json())
    .then((json) => {
      if (json.status) {
        setItemsYearMonth(json.data[0])
      }
      showYearMonthModal();
      setLoading(false)
    });
    limpiarMensaje();
  }

  function postBranchConfront(data, active, type) {
    let token = localStorage.getItem("x-access-token");
    
    setRespMessage("")
    setColorMessage("")
    let state = active ? 1 : 0
    let timbraStatus = data.activeTimbra ? 1 : 0
    let confrontaStatus = data.active ? 1 : 0
    let confrontData = {
      idBranch: _idBranchForConfront,
      allRFC: 0,
      idRelBranchRFC: data.idRelBranchRFC,
      active: type === 0 ? state : confrontaStatus ,
      activeTimbra: type === 1 ? state : timbraStatus,
    };
    
    setLoading(true)
    fetch(urlAPI.url + "branchConfront/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(confrontData),
    })
    .catch((err) => console.log(err))
    .then((res) => res.json())
    .then((json) => {
      if (json.status) {
          setRespMessage(json.data[0].mensaje)
          setColorMessage(tipoMensaje(json.data[0].numMensaje))
          getBranchRFC(_idBranchForConfront);
      } else {
        setRespMessage(json.mensaje)
        setColorMessage('error')
      }
      setLoading(false)
    });
  }

  function postBranchConfrontAll(check) {
    let token = localStorage.getItem("x-access-token");
    if(_idBranchForConfront === '') return;
    setRespMessage("")
    setColorMessage("")
    let confrontData = {
      idBranch: _idBranchForConfront,
      allRFC: 1,
      idRelBranchRFC: _idRelBranchRFC,
      active: activoTrueFalseAllRFC(check),
    };

    setLoading(true)
    fetch(urlAPI.url + "branchConfront/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(confrontData),
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          setRespMessage(json.data[0].mensaje)
          setColorMessage(tipoMensaje(json.data[0].numMensaje))
          getBranchRFC(_idBranForConfrontAux);
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        setLoading(false)
      });
    getBranchRFC(_idBranForConfrontAux);
  }
  
  function postBranchTimbratAll(check) {
    let token = localStorage.getItem("x-access-token");
    if(_idBranchForConfront === '') return;
    
    setRespMessage("")
    setColorMessage("")
    let confrontData = {
      idBranch: _idBranchForConfront,
      allRFC: 1,
      idRelBranchRFC: _idRelBranchRFC,
      activeTimbra: activoTrueFalseAllRFC(check),
    };

    setLoading(true)
    fetch(urlAPI.url + "branchRFC/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(confrontData),
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          setRespMessage(json.data[0].mensaje)
          setColorMessage(tipoMensaje(json.data[0].numMensaje))
          getBranchRFC(_idBranForConfrontAux);
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        setLoading(false)
      });
    getBranchRFC(_idBranForConfrontAux);
  }

  function deleteYearMonth(idYearMonth, idConfrontBranchRFC) {
    
    set_idYearMonth(idYearMonth)

    let token = localStorage.getItem("x-access-token");
    setRespMessage("")
    setColorMessage("")
    setLoading(true)
    fetch(urlAPI.url + `confrontYearMonthPerm/${idYearMonth}`, {
      method: "DELETE",
      headers: {
        "x-access-token": token,
      },
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          setRespMessage(json.data[0].mensaje)
          setColorMessage(tipoMensaje(json.data[0].numMensaje))
          getYearMonth(idConfrontBranchRFC);
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        setLoading(false)
      });
    cleanFieldsYearMonth();
  }

  function postYearMonth() {

    let token = localStorage.getItem("x-access-token");
    let userLogged = localStorage.getItem("username");
    let idConfrontBranchRFC = _idConfrontBranchRFC;

    setRespMessage("")
    setColorMessage("")

    let yearMonthData = {
      idYearMonth: _idYearMonth,
      idConfrontBranchRFC: _idConfrontBranchRFC,
      year: _years.toString(),
      //month: _months.toString(),
      userCap: userLogged,
    };
    setLoading(true)
    fetch(urlAPI.url + "confrontYearMonthPerm/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(yearMonthData),
    })
      .catch((err) => console.log(err))
      .then((res) => res.json())
      .then((json) => {
        if (json.status) {
          setRespMessage(json.data[0].mensaje)
          setColorMessage(tipoMensaje(json.data[0].numMensaje))
          getYearMonth(idConfrontBranchRFC);
        } else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
        }
        setLoading(false)
      });
    cleanFieldsYearMonth();
  }

  function limpiaCamposContrato() {
      set_idSucursal("")
      set_descripcion("")
      set_alias("")
      set_inicioContrato(dayjs())
      set_finContrato(dayjs())
      set_numEmpleados("")
      set_maxEmpleados("")
      set_maxEmpleadosBefore("")
      set_bloqueo(0)
      set_activo(false)
      set_notifMensual(true)
      set_plusList(true)
      set_pais("MEX")
      set_idioma("ES")
      set_dropValueSelectedCorp("")
      set_rfcEmisor("")
      set_localApiUrl("")
      set_realDemoBranch("1")
      setStatusEmployMax('')
      set_statusProject(0);
      set_typeInvoice(0);
      set_invoiceComent("")
  }

  function cleanFieldsConfront() {
      set_confrontActiveAll("")
  }

  function cleanAllFieldsYearMonth() {
      set_idYearMonth("")
      set_idConfrontBranchRFC("")
      set_years("")
      set_months("")
      set_yearDisabled(true)
      set_saveYearMonthDisabled(true)
  }

  function cleanFieldsYearMonth() {
    
      set_idYearMonth("")
      set_years("")
      set_months("")
      set_yearDisabled(true)
      set_saveYearMonthDisabled(true)
      
  }

  function cleenFieldsOrig() {
    
    set_origInicioContrato("")
    set_origFinContrato("")
    set_origMaxEmpleados("")
    set_origBloqueo("")
    set_comment("")
    set_folioHelpDesk("")
    set_dataChanged(0)
  }

  function showNuevo(){
    corporativos();
    set_activo(true)
    setOpenModNuevo(true)
    set_noLimit(false)
  };

  function closeNuevo(){
    setOpenModNuevo(false)
    set_bloqueo(0)
    setIsEdit(false);
    set_noLimit(false)
    limpiaCamposContrato();
  };

  function showEditBranchAbr(){
    setOpenModEditBranchAbr(true)
  }

  function closeEliminar(){
    limpiaCamposContrato();
  };

  // Muestra el modal comentario
  function showComment(){
    if (dataChanged() === 1) {
      setOpenModComment(true)
      set_dataChanged(1)
    } else {
      set_dataChanged(0)
      agregarSucursal()
    }
  };

  // Cierra el modal de comentario si borrar la info
  function cancelComment(){
    
    setOpenModComment(false)
    set_dataChanged(0)
    set_folioRequired(false)
    limpiaCamposContrato()
    cleenFieldsOrig()
  };

  // Detecta los cambios de los parametros de interes
  function dataChanged() {
    let dataChanged = 0;

    if (_origInicioContrato !== _inicioContrato) {
      dataChanged = 1;
      set_folioRequired(true)
    }

    if (_origFinContrato !== _finContrato) {
      dataChanged = 1;
      set_folioRequired(true)
    }

    if (_origMaxEmpleados !== _maxEmpleados) {
      dataChanged = 1;
      set_folioRequired(true)
    }

    if (_origBloqueo !== _bloqueo) {
      dataChanged = 1;
    }

    return dataChanged;
  }

  function showBranchMovement(){
    setOpenModHistMove(true )
  }

  function closeBranchMovement(){
    set_idBranchForConfront('');
    set_idBranForConfrontAux('');
    set_descripcion('');

    setOpenModHistMove(false)
  }

  function showBranchConfront(){
    setOpenModConfront(true)
  };

  function closeBranchConfront() {
    set_idBranchForConfront('');
    set_idBranForConfrontAux('');
    set_descripcion('');
    setOpenModConfront(false)
    cleanFieldsConfront()
  }

  function showYearMonthModal(){
    setOpenModYearMonths(true)
  }

  function closeYearMonthModal(){
    setOpenModYearMonths(false)
    cleanAllFieldsYearMonth()
  }

  function limpiarMensaje(){
    setTimeout(() => {
      setRespMessage("")
      setColorMessage("")
    }, 6000);
  };
  function tipoMensaje(numMensaje){
    switch (numMensaje) {
      case 0: {
        return "success";
      }
      case 1: {
        return "warning";
      }
      case 2: {
        return "error";
      }
      default: {
        return "success";
      }
    }
  };

  function closeBranchesAbrhil(){
    set_idBranchForConfront('');
    set_idBranForConfrontAux('');
    set_descripcion('');
    setOpenModBranchesAbrhil(false)
  }

    const companyOptions = itemsCompanies;

    function validLock(campo){
      switch (campo) {
        case 0: {
          return "No bloquear";
        }
        case 1: {
          return "RRHH";
        }
        case 2: {
          return "Nómina";
        }
        case 3: {
          return "Todo";
        }
        case 4: {
          return "Mod. Admin.";
        }
        default: {
          return "N/A";
        }
      }
    };

    function addRFC(newRFC){

        let token = localStorage.getItem('x-access-token');
        
        if(newRFC === ''){
          setColorMessage('error')
          setRespMessage('El campo RFC es obligatorios');
          return
        }
        let data = {
          idRFC: '',
          rfc: newRFC,
        };
            
        setLoading(true)
        fetch(urlAPI.url + 'rfc/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
          body: JSON.stringify(data),
        })
        .catch((err) => console.log(err))
        .then((res) => res.json())
        .then((json) => {
          if (json.status) {
            setRespMessage(json.data[0].mensaje);
            setColorMessage(tipoMensaje(json.data[0].numMensaje));
            getRFCNotAssigned();
          } else {
            setRespMessage(json.mensaje);
            setColorMessage('error');
          }
          setLoading(false)
        });
    }

    function unassignRFC(idRFC){
      let token = localStorage.getItem("x-access-token");

      setLoading(true)
      fetch(urlAPI.url + `unassignRFC/${idRFC}`, {
        method: "DELETE",
        headers: { 
          "x-access-token": token, 
        },
      })
        .catch((err) => console.log(err))
        .then((res) => res.json())
        .then((json) => {
          if (json.status) {
            setRespMessage(json.data[0].mensaje)
            setColorMessage(tipoMensaje(json.data[0].numMensaje))
            getBranchRFC(_idBranForConfrontAux);
          } else {
            setRespMessage(json.mensaje)
            setColorMessage('error')
          }
          setLoading(false)
        });
    }

    //Filtrar
	  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
      if(selectedKeys === '' ){clearFilters && handleReset(clearFilters);}
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
	  };

	  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText(''); 
	  };

	  const getColumnSearchProps = (dataIndex) => ({
		  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			  <Input
				ref={searchInput}
				placeholder={`Buscar ${dataIndex}`}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
				style={{ marginBottom: 8, display: 'block', }}
			  />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  icon={<SearchOutlined />}
				  size="small"
				  style={{
					width: 90,
				  }}
				  className="bg-[#1677ff]"
				>
				  Buscar
				</Button>
				<Button
				  onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				  size="small"
				  style={{
					width: 90,
				  }}
				>
				  Borrar
				</Button>
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					confirm({
					  closeDropdown: false,
					});
					setSearchText(selectedKeys[0]);
					setSearchedColumn(dataIndex);
				  }}
				>
				  Filtrar
				</Button>
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					close();
				  }}
				>
				  Cerrar
				</Button>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered) => (
        filtered ?
        (<Badge className="animate-pulse" dot>
          <SearchOutlined
            className='w-[20px] h-[20px] text-[18px] '
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />
        </Badge>):(<SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />)
        ),
		  onFilter: (value, record) =>{
			  if(record[dataIndex]) {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }else{
          return ''.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
		  onFilterDropdownOpenChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current?.select(), 100);
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{
				  backgroundColor: '#ffc069',
				  padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			  />
			) : (
			  text
			),
		});

    const optionsFilter = [
      { label: "TODOS", value: "" },
      { label: "MEX", value: "MEX" },
      { label: "CHL", value: "CHL" },
      { label: "CRI", value: "CRI" },
      { label: "JAM", value: "JAM" },
      { label: "DOM", value: "DOM" },
      { label: "COL", value: "COL" },
      { label: "PER", value: "PER" },
      { label: "LCA", value: "LCA" },
      { label: "GRD", value: "GRD" },
    ];
  
    const getColumnCountryProps = (dataIndex) => ({
		  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			  <Select
          style={{ marginBottom: 8, display: 'block', }}
          options={optionsFilter}
          onChange={(e) => setSelectedKeys(e ? [e] : []) }
        />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  icon={<SearchOutlined />}
				  size="small"
				  style={{
					width: 90,
				  }}
				  className="bg-[#1677ff]"
				>
				  Buscar
				</Button>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered) => (
        filtered ?
        (<Badge className="animate-pulse" dot>
          <SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />
        </Badge>):(<SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />)
		  ),
		  onFilter: (value, record) =>{
			  if(record[dataIndex]) {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }else{
          return ''.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
		  onFilterDropdownOpenChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current?.select(), 100);
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{
				  backgroundColor: '#ffc069',
				  padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			  />
			) : (
			  text
			),
		});

    const newIcon =  () => (
      <img src={imgLogo} className="w-flex max-w-[25px]" />
    )

    const newIconTimbra =  () => (
      <img src={imgTimbra} className="w-flex max-w-[25px]" />
    )

    const columnas = [
      {
        title: "Corporativo",
        dataIndex: "codeClient",
        className:"select-none",
        width:120,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
        onFilter:(x,y,z)=>{},
        ...getColumnSearchProps('codeClient'),
      },
      {
        title: "Descripción",
        //dataIndex: "description",
        className:"select-none",
				ellipsis: true,
        onFilter:(x,y,z)=>{},
        ...getColumnSearchProps('description'),
        render: (colProps) => {
          return (
          <Tooltip trigger={['hover', 'click']} placement="top" 
          title={`${colProps.idCompany ? colProps.idCompany : ''}-${colProps.idBranch}`}>
              {colProps.description}
          </Tooltip>)
        },
      },
      {
        title: "País",
        dataIndex: "country",
        align:"center",
        className:"select-none",
        width:80,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
        onFilter:(x,y,z)=>{},
        ...getColumnCountryProps('country'),
      },
      {
        title: "Inicio Contrato",
        dataIndex: "startDate",
        align:"right",
        className:"select-none",
        width:120,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
        render: (props) => {
          return convertToDate(props);
        },
      },
      {
        title: "Fin Contrato",
        dataIndex: "endDate",
        align:"right",
        className:"select-none",
        width:120,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
        render: (props) => {
          return convertToDate(props);
        },
      },
      {
        title: "#Emp. Vig.",
        dataIndex: "numEmployees",
        align:"right",
        className:"select-none",
        width:100,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
      },
      {
        title: "Max Emp Perm",
        dataIndex: "maxEmployees",
        align:"right",
        className:"select-none",
        width:100,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
      },
      {
        title: "Bloquear",
        dataIndex: "lock",
        align:"center",
        className:"select-none",
        width:80,
				ellipsis: true,
				responsive: ['xxl', 'xl'],
        render: (props) => {
          return validLock(props);
        },
      },
      {
        title: "Activo",
        dataIndex: "active",
        align:"center",
        className:"select-none",
        width:80,
				ellipsis: true,
        render: (props) => {
          return validaBlocking(props);
        },
      },
      {
        title: "Idioma",
        dataIndex: "language",
        align:"center",
        className:"select-none",
        width:80,
				ellipsis: true,
				responsive: ['xxl', 'xl'],
      },
      {
        title: "Acciones",
        align:"center",
        width:100,
				ellipsis: true,
				responsive: ['xs'],
        render: (props) => {
          return (<div className="gap-2 bg-red select-none">
            <Dropdown 
            menu={{
                items: [
                  findUserRights("59") ? ({
                  label: 'Sucursales de Abrhil',
                  key: '1',
                  icon:<Icon component={newIcon} className="bg-white select-none" />,
                  onClick:()=>{
                    getBranchesAbrhil(props.idBranch);
                    set_idSucursal(props.idBranch);
                    set_nombreAbr(props.description);
                    setOpenModBranchesAbrhil(true)
                  }
                }):null,
                findUserRights("57") ? ({
                  label: 'Timbrasoft',
                  key: '2',
                  icon:<Icon component={newIconTimbra}  className="bg-white select-none" />,
                  onClick:()=>{
                    branchConfrontFunc(
                      props.idBranch,
                      props.description
                    );
                  }
                }):null,
                findUserRights("56") ? ({
                  label: 'Historial',
                  key: '3',
                  icon: <BarsOutlined  className="text-black select-none" />,
                  onClick:()=>{
                    branchMovementFunc(
                      props.idBranch,
                      props.description
                    );
                  }
                }):null,
                findUserRights("24") ? ({
                  label: 'Editar',
                  key: '4',
                  icon:<EditOutlined className="text-black select-none"  />,
                  onClick:() => {
                    editarSucursalModal(props);
                  }
                }):null,
                findUserRights("25") ? ({
                  label: 
                  <Popconfirm
                    title="Borrar mensaje"
                    description={'¿Desea eliminar la sucursal "'+ props.codeClient +'" ?'}
                    onConfirm={() => {
                      eliminarSucursal(
                        props.idBranch
                      );
                    }}
                    onCancel={()=>{}}
                    okText="Eliminar"
                    cancelText="Cancelar"
                    okButtonProps={{className:'bg-[#1677ff]'}}
                  >
                    <Button className="flex justify-center items-center bg-red-200 w-full" >
                      <DeleteOutlined className="text-black" /> Eliminar
                    </Button>
                  </Popconfirm>,
                  key: '5',
                  danger: true,
                }):null,
              ]
              }}>
                <EllipsisOutlined />
              </Dropdown>
          </div>)
        }
      },
      {
        title: "Acciones",
        align:"center",
        className:'bg-white select-none ',
        selections:'false',
        width:200,
				responsive: ['xxl', 'xl', 'lg', 'md'],
				ellipsis: true,
        render: (props) => {
          return (
            <div className="gap-2 bg-white select-none flex">
              {findUserRights("59") ? (
              <Tooltip trigger={['hover', 'click']} placement="top" title={'Sucursales de Abrhil'}>
                <Button
                  className="flex justify-center items-center bg-white p-0 select-none text-sm"
                  ghost={true}
                  onClick={() => {
                    getBranchesAbrhil(props.idBranch);
                    set_idSucursal(props.idBranch);
                    set_nombreAbr(props.description);
                    setOpenModBranchesAbrhil(true)
                  }}
                >
                  <Icon component={newIcon} className="bg-white select-none" />
                </Button>
              </Tooltip>
              ) : (null)
              }
              {findUserRights("57") ? (
              <Tooltip trigger={['hover', 'click']} placement="top" title={'Timbrasoft'}>
                <Button
                  className="flex justify-center items-center bg-white p-0 select-none text-sm" 
                  ghost={true}
                  onClick={() => {
                    branchConfrontFunc(
                      props.idBranch,
                      props.description
                    );
                  }}
                >
                  <Icon component={newIconTimbra}  className="bg-white select-none" />
                </Button>
              </Tooltip>
              ) : (null )
              }
              {findUserRights("56") ? (
              <Tooltip trigger={['hover', 'click']} placement="top" title={'Historial'}>
                <Button
                  className="flex justify-center items-center bg-yellow-200 p-2 select-none"
                  onClick={() => {
                    branchMovementFunc(
                      props.idBranch,
                      props.description
                    );
                  }}
                >
                  <BarsOutlined  className="text-black select-none" />
                </Button>
              </Tooltip>
              ) : null}

              {findUserRights("24") ? (
                <Tooltip trigger={['hover', 'click']} placement="top" title={'Editar'}>
                <Button
                  className="flex justify-center items-center bg-blue-200 p-2 select-none"
                  onClick={() => {
                    editarSucursalModal(props);
                  }}
                >
                  <EditOutlined className="text-black select-none"  />
                </Button>
              </Tooltip>
              ) : null}

              {findUserRights("25") ? (
                <Tooltip trigger={['hover', 'click']} placement="top" title={'Eliminar'}>
                <Popconfirm
                  title="Borrar mensaje"
                  description={'¿Desea eliminar la sucursal "'+ props.codeClient +'" ?'}
                  onConfirm={() => {
                    eliminarSucursal(
                      props.idBranch
                    );
                  }}
                  onCancel={()=>{}}
                  okText="Eliminar"
                  cancelText="Cancelar"
                  okButtonProps={{className:'bg-[#1677ff]'}}
                >
                  <Button className="flex justify-center items-center bg-red-200 p-2" >
                    <DeleteOutlined className="text-black" />
                  </Button>
                </Popconfirm>
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
    ];

    function validaBlocking(campo){
      switch (campo) {
        case 0: {
          return "NO";
        }
        case 1: {
          return "SI";
        }
        default: {
          return "N/A";
        }
      }
    };

    function convertToDate(date){
      let fecha = dayjs(date).format("DD/MM/YYYY");
      return fecha;
    };

    const year = new Date().getFullYear();
    const optionsYear = [];

    for (var i = 2020; i < year + 1; i++) {
      optionsYear.push({ key: i, value: i, text: i });
    }

    function findUserRights(findRight){
      let userRights = rights;
      for (let i in userRights) {
        let x = userRights[i].idOperation;

        if (x == findRight) {
          return true;
        }
      }
    };
    const alertMessage = (type) =>{
      switch(type){
        case 'success': return 'Correcto';
        case 'warning': return 'Advertencia';
        case 'error': return 'Error';
      }
    }
		//Alert
		const [api, contextHolder] = notification.useNotification();
		useEffect(()=>{if(respMessage!=='')openNotification()},[respMessage])
		function openNotification () {
			api[colorMessage]({
			message: alertMessage(colorMessage),
			description: respMessage,
			placement:'bottomRight',
			});
			setColorMessage('')
			setRespMessage('')
		}
    useEffect(()=>{},[itemsRelBranchRFC])
    return (
      <div>
			{contextHolder}
  
      <Spin spinning={loading} size='large'>
        <Table
					bordered={true}
          columns={columnas}
          dataSource={itemsBranches}
          className="max-w-[1500px] headerTable branchAdminTable select-none flex"
          rowClassName={'p-0 text-xs'}
          size='small'
          rowKey={(r,i) => 'bat_'+i}
          onRow={(record,i)=>{
              return{
                  onClick:(event) => {
                      $('.branchAdminTable table tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                      $(`[data-row-key="bat_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                  }
              }
          }}	
        />

        <div className="row padding-btn-agregar select-none">
          {findUserRights("23") ? (
            <Button 
						icon={<PlusSquareOutlined className='text-xl '/>}
						onClick={()=>{ setIsEdit(false); showNuevo()}}
						type='primary'
						className='bg-[#1677ff] flex justify-center items-center select-none'
						>
						Nuevo cliente
						</Button>
          ) : null}
        </div>
        <DrawerClient 
          title={isEdit ? "Editar cliente":  "Agregar cliente" }
          onClose={closeNuevo}
          open={openModNuevo}
          data={{
            mayusculas:mayusculas,
            statusEmployMax:statusEmployMax,
            descripcionHandler:descripcionHandler,
            aliasHandler:aliasHandler,
            maxEmployeesHandler:maxEmployeesHandler,
            description: _descripcion,
            alias:_alias,
            isEdit:isEdit,
            rangePicker: {
              value:[dayjs(_inicioContrato), dayjs(_finContrato)],
              onChange:(data)=>{if(data)rangeDaysClient(data)}
            },
            tope:{
              onChange:toggleNoLimit,
              _noLimit:_noLimit,
              _maxEmpleados:_maxEmpleados,
              _maxEmpleadosBefore:_maxEmpleadosBefore,
              _numEmpleados:_numEmpleados,
              numEmployeesHandler:numEmployeesHandler,
            },
            blocked:{
              _bloqueo:_bloqueo,
              _activo:_activo,
              toggleActive:toggleActive,
              lockHandler:lockHandler,
            },
            plusList:{
              _plusList:_plusList,
              togglePlusList:togglePlusList,
            },
            notification:{
              notification:_notifMensual,
              chkNotifyHndlr:chkNotifyHndlr
            },
            country:{
              countryHndlr:countryHndlr,
              _pais:_pais,
            },
            language:{
              languageHndlr:languageHndlr,
              _idioma:_idioma,
            },
            corporation:{
              companyOptions:companyOptions,
              _dropValueSelectedCorp:_dropValueSelectedCorp,
              companyHandler:companyHandler,
            },
            rfc:{
              rfcEmisorHndlr:rfcEmisorHndlr,
              _rfcEmisor:_rfcEmisor,
            },
            enterprise:{
              realDemoBranchHndlr:realDemoBranchHndlr,
              _realDemoBranch:_realDemoBranch,
            },
            api:{
              localApiUrlHndlr:localApiUrlHndlr,
              _localApiUrl:_localApiUrl,
            },
            invoice:{
              stausProjectHandler:stausProjectHandler,
              _statusProject : _statusProject,
              invoiceTypeHandler:invoiceTypeHandler,
              _typeInvoice : _typeInvoice,
              invoiceComentHandler:invoiceComentHandler,
              _invoiceComent : _invoiceComent,
              _requireComent:_requireComent
            }
          }}
          extra={
            <Space className="flex flex-col flex-wrap">
              <Button onClick={closeNuevo}>Cancelar</Button>
              <Button onClick={isEdit ? showComment : agregarSucursal} type="primary" className="bg-[#1677ff]" 
                disabled={
                  !_descripcion ||
                  !_alias ||
                  !_maxEmpleados
                  }>
                Guardar
              </Button>
            </Space>
          }
        />
        <ModalMessage  
          open={openModComment} 
          onOk={agregarSucursal} 
          onCancel={cancelComment} 
          data={{
            commentHndlr:commentHndlr,
            _comment:_comment,
            folioHelpDeskHndlr:folioHelpDeskHndlr,
            _folioRequired:_folioRequired,
          }}
        />
        <ModalHist 
          title={"Historia de movimientos - "+ _descripcion}
          open={openModHistMove}
          onOk={closeBranchMovement}
          onCancel={closeBranchMovement}
          dataSource={branchMovement} 
        />

        <DrawerConfronta 
          className="flex"
          title={" Permiso para Timbrasoft - "+ _descripcion}
          open={openModConfront}
          onClose={closeBranchConfront}
          dataSource={itemsRelBranchRFC} 
          data={{
            findUserRights:findUserRights,
            showYearMonths:showYearMonths,
            switch:{
              _confrontActiveAll:_confrontActiveAll,
              toggleConfrontActiveAll:toggleConfrontActiveAll,
              _timbraActiveAll:_timbraActiveAll,
              toggleTimbraActiveAll:toggleTimbraActiveAll,
            },
            activo:{
              _confrontActiveRow:_confrontActiveRow,
              toggleConfrontActiveRow:toggleConfrontActiveRow,
            },
            activoTimbra:{
              _timbraActiveRow:_timbraActiveRow,
              toggleTimbraActiveRow:toggleTimbraActiveRow,
            },
            drawer:{
              open:openModYearMonths,
              onClose:closeYearMonthModal,
              title:'Permisos para ' + _descripcion+' - '+_confrontRFC,
              select:{
                value:_years,
                onChange:yearHandler,
                disabled:_yearDisabled,
              },
              btnNew:{
                onClick:newYearMonth,
                disabled:_activeNewYear,
              },
              btnSave:{
                onClick:postYearMonth,
                disabled:_saveYearMonthDisabled,
                _months:_months,
              },
              dataSource:itemsYearMonth,
              deleteYearMonth:deleteYearMonth,
            },
            drawerRFC:{
              title:'Asignar RFC',
              open:openDrawerRFC,
              onClose:closeViewNotAssigned,
              viewNotAssigned:viewNotAssigned,
              dataSource:itemsRFCNotAssigned,
              assingRFC:assingRFC,
              unassignRFC:unassignRFC,
              addRFC:addRFC,
            },
            drawerMsg:{
              title:'Asignar mensaje',
              open:_modalMsg,
              onClose:()=>set_modalMsg(false),
              dataSource:_itemsMsg,
              assingMsg:assingMsg,
              unassignMsg:unassignMsg,
              modalMsgHandler:modalMsgHandler,
              _asociateMsg:_asociateMsg,
              _statusAsociateMsg:_statusAsociateMsg,
              blockadeMSg:blockadeMSg
            }
          }}
        />
        <BrancesAbrhil
          title={"Sucursales de Abrhil para "+_nombreAbr}
          open={openModBranchesAbrhil}
          onClose={closeBranchesAbrhil}
          findUserRights={findUserRights}
          itemsBranchesAbrhil={itemsBranchesAbrhil}
          editarSucursalAbrModal={editarSucursalAbrModal}
          drawer={{
            open:openModEditBranchAbr,
            onClose:closeEditBranchAbr,
            title:'Permisos para ' + _nombreAbr,
            data:{
              _idSucursalAbr:_idSucursalAbr,
              _clvSucursalAbr:_clvSucursalAbr,
              _nombreAbr:_nombreAbr,
              _inicioContratoAbr:_inicioContratoAbr,
              _finContratoAbr:_finContratoAbr,
              _maxEmpleadosAbr:_maxEmpleadosAbr,
              _bloqueoAbr:_bloqueoAbr,
              _activoAbr:_activoAbr,
            },
            actions:{
              rangeDays:rangeDays,
              lockHandlerAbr:lockHandlerAbr,
              handlerActiveAbr:handlerActiveAbr,
              maxEmployeesHandlerAbr:maxEmployeesHandlerAbr,
            }
          }}
          extra={
            <Space  className="flex flex-col flex-wrap">
              <Button onClick={()=>{closeEditBranchAbr()}}>Cancelar</Button>
              <Button onClick={()=>{saveChangeBranchAbr()}} type="primary" className='bg-[#1677ff] flex justify-center items-center'>Guardar</Button>
            </Space>
          }
        />
			</Spin>
      </div>
    );
}

export default React.memo(ContracsTable)
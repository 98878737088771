import React, { useState, useEffect } from 'react';
import MainMenu from '../menu/Menu';
import { Tabs, Card, Row, Col } from 'antd';


function NotPermission() {
	const [rights, setRights] = useState([])
	let stringUserRights, userRights;

	useEffect(() => {
		stringUserRights = localStorage.getItem('userRights');
		userRights = JSON.parse(stringUserRights);
		setRights(userRights)
	},[]);

	return (
		<div className='w-full flex flex-col gap-5'>
			{<MainMenu activeItem={'workers'} />}
			<div className='w-full flex justify-center px-4'>
				<Card className="w-full flex justify-center items-center px-4" title="">
					404 - pagina no encontrada
				</Card>
			</div>
		</div>
	);
}
export default React.memo(NotPermission); 
import React from "react";
import { Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const SelectPers = (props) => (
    <Select 
        showSearch
        options={props.options} 
        className={props.className} 
        onChange={props.onChange} 
        allowClear 
        onClear={props.onClear} 
        clearIcon={<CloseOutlined className="text-black " />}
        value={props.value} 
        placeholder="Selecciona un corporativo"
        
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toUpperCase().localeCompare((optionB?.label ?? '').toUpperCase())
        }
        
    />
);

export default React.memo(SelectPers)
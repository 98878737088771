import React, { Component, useEffect, useState } from 'react';
import './BranchNotiReport.css';
import '../MainStyle.css';
import $ from 'jquery';
import ReactTable from 'react-table';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { CSVLink, CSVDownload } from 'react-csv';
//import { Grid, Dropdown, Button, Icon } from 'semantic-ui-react';
import { Card, Select, Spin } from 'antd';
import Table from '../../@ui/Table';
import Button from '../../@ui/Button';
import { CloseOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import NotAllow from '../../@ui/NotAllow';

function BranchNotiReport(){
		
	//Filtro por año
	const year = (new Date()).getFullYear();
	const yearFilters= [];	

	for(var i=2019; i<year + 1; i++){
		yearFilters.push({ value:i, label:i});
	}
	const actMonth = ((new Date()).getMonth() === 0 ? 12 : (new Date()).getMonth());

	const headerWorker = [
		{ label: 'Clv Sucursal', key: 'idBranch' },
		{ label: 'Sucursal', key: 'description' },
		{ label: 'Inicio Contrato', key: 'startDate' },
		{ label: 'Fin Contrato', key: 'endDate' },
		{ label: '#Emple Vig del Mes', key: 'EmpVigDelMes' },
		{ label: 'Emplelados Actuales', key: 'EmpActual' },
	]

	const headerStamp = [
		{ label: 'Clv Sucursal', key: 'idBranch' },
		{ label: 'Sucursal', key: 'description' },
		{ label: 'Inicio Contrato', key: 'startDate' },
		{ label: 'Fin Contrato', key: 'endDate' },
		{ label: 'Total Timbre', key: 'TotTimbre' },
		{ label: 'Total Cancelado', key: 'TotCancelado' },
		{ label: 'Emplelados Actuales', key: 'EmpActual' },
	]

	const [ itemsBranches, setItemsBranches ] = useState([])
	const [ itemsCorporate, setItemsCorporate ] = useState([]);
	const [ branchesToFilter, setBranchesToFilter ] = useState([]);

	// VALORES PARA LOS FILTROS
	const [ _corpValue, set_corpValue ] = useState('');
	const [ _branchValue, set_branchValue ] = useState('');
	const [ _yearValue, set_yearValue ] = useState(year);
	const [ _monthValue, set_monthValue ] = useState(actMonth);
	const [ _type, set_type ] = useState(0);

	const [ _filteredData, set_filteredData ] = useState([]);
	const [loading, setLoading] = useState(false);

	const [ rights, setRights] = useState([])
		//{ label: 'IdSucursal', key: 'idBranch' },

	useEffect(()=>{ componentDidMount(); 
		let stringUserRights = localStorage.getItem('userRights')
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);},[])

	function componentDidMount() {
		let token = localStorage.getItem('x-access-token');

		let filterParams = {
			idCompany: '',
			idBranch: '',
			year: '',
			month: '',
		};
		setLoading(true);
		fetch(urlAPI.url + `reportMonthNotify/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(filterParams),
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut == undefined) {
					if (json.aut == false) logout();
					if (json.status) {
						setItemsBranches(json.data[0]);
						var filteredData = JSON.parse(JSON.stringify(json.data[0]));
						filteredData.map((element)=>{
							element.startDate=convertToDate(element.startDate);
							element.endDate=convertToDate(element.endDate);
						})
						set_filteredData(filteredData)
					}
				} else {
					logout();
				}
				setLoading(false);
			});
		loadCorporate();
	}

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	function loadCorporate() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true);
		fetch(urlAPI.url + 'companiesView/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut === undefined) {
					if (json.aut === false) logout();
					if (json.status) {
						let arrCorporate = [];
						let data = json.data[0];
						data.forEach((element) => {
							arrCorporate.push({
								value: element.idCompany,
								label: element.codeClient,
							});
						});
						setItemsCorporate(arrCorporate)
					} 
				} else {
					logout();
				}
				setLoading(false);
			});
		loadBranches();
	}

	function filterBranches() {
		
		let token = localStorage.getItem('x-access-token');

		let filterParams = {
			idCompany: _corpValue === undefined ? '' : _corpValue,
			idBranch: _branchValue === undefined ? '' : _branchValue,
			year: _yearValue === undefined ? '' : _yearValue,
			month: _monthValue === undefined ? '' : _monthValue,
			type: _type === undefined ? 0 : _type,
		};
		
		setLoading(true);
		fetch(urlAPI.url + `reportMonthNotify/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(filterParams),
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut === undefined) {
					if (json.aut === false) logout();
					if (json.status) {
						setItemsBranches(json.data[0]);
						var filteredData = JSON.parse(JSON.stringify(json.data[0]));
						set_filteredData(filteredData)
					}
				} else {
					logout();
				}
				setLoading(false);
			});
	}


	function loadBranches() {
		let token = localStorage.getItem('x-access-token');
		let idCorp = _corpValue;

		if (idCorp == undefined || idCorp == '') idCorp = 0;

		setLoading(true);
		fetch(urlAPI.url + `corpBranches/${idCorp}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut === undefined) {
					if (json.aut === false) logout();
					if (json.status) {
						let arrBranch = [];
						let data = json.data[0];
						data.forEach((element) => {
							arrBranch.push({
								value: element.idBranch,
								label: element.description,
							});
						});
						setBranchesToFilter(arrBranch)
					} 
				} else {
					logout();
				}
				setLoading(false);
			});
	}

	const corporateOptions = itemsCorporate;
	const branchOptions = branchesToFilter;
	let filteredData = _filteredData;

	const columnas = [
		{
			title: 'Sucursal',
			dataIndex: 'description',
			ellipsis: true,
		},
		{
			title: 'Inicio Contrato',
			dataIndex: 'startDate',
			align: 'right',
			width: 150,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
		{
			title: 'Fin Contrato',
			dataIndex: 'endDate',
			align: 'right',
			width: 150,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		}
	];

	const gridWorker = [
		{
			title: '#Empleados Vig del Mes',
			dataIndex: 'EmpVigDelMes',
			align: 'right',
			width: 100,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'Empleados Acutales',
			dataIndex: 'EmpActual',
			align: 'right',
			width: 100,
		}		
	]

	const gridStamp = [
		{
			title: 'Total Timbre',
			dataIndex: 'TotTimbre',
			align: 'right',
			width: 100,
		},
		{
			title: 'Total Cancelado',
			dataIndex: 'TotCancelado',
			align: 'right',
			width: 100,
		},
		{
			title: 'Empleados Acutales',
			dataIndex: 'EmpActual',
			align: 'right',
			width: 100,
			responsive: ['xxl', 'xl', 'lg'],
		}	
	]

	function convertToDate(date){return dayjs(date).format('DD/MM/YYYY');};

	const monthFilters = [
		{
			value: 1,
			label: 'Enero',
		},
		{
			value: 2,
			label: 'Febrero',
		},
		{
			value: 3,
			label: 'Marzo',
		},
		{
			value: 4,
			label: 'Abril',
		},
		{
			value: 5,
			label: 'Mayo',
		},
		{
			value: 6,
			label: 'Junio',
		},
		{
			value: 7,
			label: 'Julio',
		},
		{
			value: 8,
			label: 'Agosto',
		},
		{
			value: 9,
			label: 'Septiembre',
		},
		{
			value: 10,
			label: 'Octubre',
		},
		{
			value: 11,
			label: 'Noviembre',
		},
		{
			value: 12,
			label: 'Diciembre',
		},
	];

	const typeFilters = [
		{
			value: 0,
			label: 'Trabajador',
		},
		{
			value: 1,
			label: 'Timbrado',
		}
	]

	useEffect(()=>{loadBranches()},[_corpValue])
	//Filtro corporativo
	function dropCorpHandlr(value) { set_corpValue(value); set_branchValue(''); }
	function clearDropCorporate(){ set_corpValue(''); set_branchValue(''); };

	//Filtro sucursal
	function dropBranchHandlr(value) { set_branchValue(value); }
	function clearDropBranch(){ set_branchValue(''); };

	function dropYearHndlr(value) { set_yearValue(value); }
	function clearDropYear(){ set_yearValue(''); };

	//Filtro por mes
	function dropMonthHndlr(value) { set_monthValue(value);	}
	function clearDropMonth(){ set_monthValue('') };

	const typeHandler = (type) => { set_type(type); setItemsBranches([]); }
	const cleanType = () => { set_type(0); setItemsBranches([]); }

	function parseReport(){
		let dataOrginal = _filteredData;

		dataOrginal.forEach((elemOri) => {
			elemOri.startDate = convertToDate(elemOri.startDate);
			elemOri.endDate = convertToDate(elemOri.endDate);
		});
		set_filteredData(dataOrginal)
	};

	let findUserRights = (findRight) => {		
		let userRights = rights;

		for (let i in userRights) {			
			let x = userRights[i].idOperation;

			if(x === findRight){					
				return true
			}
		}
	}

	return (
		<div className='flex'>
			<Spin spinning={loading} size='large'>
			<Card title="" className='flex flex-col flex-wrap 
				3xl:items-start 2xl:items-start xl:items-start lg:items-start md:items-start sm:items-center xs:items-center items-center
				3xl:justify-end 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center xs:justify-center justify-center'>
				{ findUserRights(67) ? <div className='flex flex-col gap-4'>
					<div className="flex flex-wrap gap-4 items-center">
						{/* FILTRO POR CORPORATIVO */}
						<div className="flex gap-2 justify-center items-center">
							<label>&nbsp;Corporativo</label>
							<Select
								options={corporateOptions}
								value={_corpValue}
								onChange={dropCorpHandlr}
								className="dropdow-corporate-filter"
								placeholder="Selecciona un corporativo"
								allowClear
								clearIcon={<CloseOutlined className='text-[14px] text-black' />}
								onClear={clearDropCorporate}
								showSearch
								optionFilterProp="children"
								filterOption={(input, corporateOptions) => ((corporateOptions?.label).toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
							/>
						</div>

						{/* FILTRO POR SUCURSAL */}
						<div className="flex gap-2 justify-center items-center">
							<label>&nbsp;Sucursal</label>
							<Select
								options={branchOptions}
								value={_branchValue}
								onChange={dropBranchHandlr}
								className="dropdow-corporate-filter"
								placeholder="Selecciona una sucursal"
								allowClear
								clearIcon={<CloseOutlined className='text-[14px] text-black' />}
								onClear={clearDropBranch}
								showSearch
								optionFilterProp="children"
								filterOption={(input, branchOptions) => ((branchOptions?.label).toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
							/>
						</div>

						{/* FILTRO POR AÑO */}
						<div className="flex gap-2 justify-center items-center">
							<label>Año</label>
							<Select
								value={_yearValue}
								options={yearFilters}
								onChange={dropYearHndlr}
								className="dropdow-year-filter"
								placeholder="Selecciona un año"
								allowClear
								clearIcon={<CloseOutlined className='text-[14px] text-black' />}
								onClear={clearDropYear}
							/>
						</div>

						{/* FILTRO POR MES */}
						<div className="flex gap-2 justify-center items-center">
							<label>Mes</label>
							<Select
								value={_monthValue}
								options={monthFilters}
								onChange={dropMonthHndlr}
								className="dropdow-month-filter "
								placeholder="Selecciona un mes"
								allowClear
								clearIcon={<CloseOutlined className='text-[14px] text-black'/>}
								onClear={clearDropMonth}
							/>
						</div>

						{/* FILTRO POR TIPO */}
						<div className="flex gap-2 justify-center items-center">
							<label>Tipo</label>
							<Select
								value={_type}
								options={typeFilters}
								onChange={typeHandler}
								className="dropdow-month-filter "
								placeholder="Selecciona un mes"
								allowClear
								clearIcon={<CloseOutlined className='text-[14px] text-black'/>}
								onClear={cleanType}
							/>
						</div>

						{/* BOTÓN FILTRAR */}
						<div width={1} className="flex justify-center">
							<Button 
								text='Filtrar' 
								className="bg-[#1890ff] text-white flex justify-center items-center" 
								onClick={(e) => filterBranches(e)}
								icon={<SearchOutlined className='text-xl'/>}
							/>
						</div>
					</div>
					
					<Table 
						columns={columnas.concat(_type === 0 ? gridWorker : gridStamp )}
						dataSource={itemsBranches}
						className="headerTable w-full branchNotiReport"
						size="small"
						rowKey={(r,i) => 'branchNotiReport_'+i}
						onRow={(record,i)=>{
							return{
								onClick:(event) => {
									$('.branchNotiReport tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
									$(`[data-row-key="branchNotiReport_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
								}
							}
						}}	
					/>
					<CSVLink data={filteredData} headers={_type === 0 ? headerWorker : headerStamp} onClick={parseReport} className='flex w-fit'
						filename={_type == 0 ? "Trabajador.csv" : "Timbrado.csv"}
					>
						<Button 
							text='Exportar'
							icon={<DownloadOutlined className='text-xl'/>}
							className='text-white bg-[#1890ff] flex justify-center items-center'
						/>
					</CSVLink>
				</div> : <NotAllow />}
			</Card>
			</Spin>
		</div>
	);
}
export default React.memo(BranchNotiReport)
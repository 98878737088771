import React, { Component } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import StartPage from './components/startPage/StartPage';
import Catalogue from './components/catalogue/Catalogue';
import Modules from './components/modules/Modules'
import UsersAdmin from './components/usersAdmin/UsersAdmin';
import Reporting from './components/reporting/Reporting';
import RolesAdmin from './components/rolesAdmin/RolesAdmin';
import Workers from './components/workers/';
import NotAllowed from './components/notAllowed/NotAllowed';
import 'react-table';
import './components/menu/Menu.css';
import 'react-datepicker/dist/react-datepicker.css';
import NotPermission from './components/notPermission';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeItem: 'Modulos',
			showMenu: false,
		};
	}
	
	render() {

		return (
			<div>		
				<Routes>
					<Route exact path="/rolesAdmin" element={<RolesAdmin/>} />
					<Route exact path="/notAllowed" element={<NotAllowed/>} />
					<Route exact path="/startPage" element={<StartPage/>} />
					<Route exact path="/reporting" element={<Reporting/>}	/>
					<Route exact path="/usersAdmin" element={<UsersAdmin/>} />
					<Route exact path="/catalogue" element={<Catalogue/>} />
					<Route exact path="/workers" element={<Workers/>} />
					<Route exact path="/" element={<Modules />} />
					<Route path="*" element={<NotPermission />} />
					<Route path="/login" element={<Login />} />
				</Routes>
			</div>
		);
	}
}

export default App;

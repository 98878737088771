import urlAPI from '../urlAPI/urlAPI.json';

export const userService = {
	login,
	logout,
};

function login(username, password) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ username, password }),
	};

	return fetch(urlAPI.url + 'login/', requestOptions)
		.then(handleResponse)
		.then((resp) => {
			return resp;
		});
}

// ELIMINA EL TOKEN Y EL USUARIO DEL LOCAL STORAGE
function logout() {
	localStorage.removeItem('x-access-token');
	localStorage.removeItem('username');
	localStorage.removeItem('userRights');
	localStorage.removeItem('type');
	localStorage.removeItem('country');
	localStorage.removeItem('access');
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);

		if (!response.ok) {
			if (response.status === 401) {
				// AUTO LOGOUT SI LA RESPUESTA DE LA API ES 401
				logout();
				window.location.reload(true);
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}


import React, { useState, useRef } from "react";
import { Card, Table, Switch, Tooltip, Drawer, Descriptions, Input, Space, Badge, Button, Pagination } from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import dayjs from "dayjs";
import $ from 'jquery';
import UIButton from '../../@ui/Button'
import DateRangerPicker from '../../@ui/DateRangerPicker'
function  BranchesAbrhil(props){

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);

  //Filtrar
  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    if(selectedKeys === '' ){clearFilters && handleReset(clearFilters);}
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText(''); 
  };
  
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
      <Input
      ref={searchInput}
      placeholder={`Buscar ${dataIndex}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
      style={{ marginBottom: 8, display: 'block', }}
      />
      <Space>
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{
        width: 90,
        }}
        className="bg-[#1677ff]"
      >
        Buscar
      </Button>
      <Button
        onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
        size="small"
        style={{
        width: 90,
        }}
      >
        Borrar
      </Button>
      <Button
        type="link"
        size="small"
        onClick={() => {
        confirm({
          closeDropdown: false,
        });
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        }}
      >
        Filtrar
      </Button>
      <Button
        type="link"
        size="small"
        onClick={() => {
        close();
        }}
      >
        Cerrar
      </Button>
      </Space>
    </div>
    ),
    filterIcon: (filtered) => (
      filtered ?
      (<Badge className="animate-pulse" dot>
        <SearchOutlined
          className='w-[20px] h-[20px] text-[18px] '
          style={{
          color: filtered ? '#1890ff' : undefined,
          }}
        />
      </Badge>):(<SearchOutlined
          className='w-[20px] h-[20px] text-[18px]'
          style={{
          color: filtered ? '#1890ff' : undefined,
          }}
        />)
      ),
    onFilter: (value, record) =>{
      if(record[dataIndex]) {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      }else{
        return ''.toString().toLowerCase().includes(value.toLowerCase())
      }
    },
    onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
    },
    render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069',
        padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
  });
    
    const columnsBranchesAbrhil = [
        {
          title: "Clave",
          dataIndex: "clv_Sucursal",
          className:"select-none",
          align:'center',
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:80,
        },
        {
          title: "Nombre",
          dataIndex: "nombre",
          className:"select-none",
          ellipsis: true,
          ...getColumnSearchProps('nombre'),
        },
        /*{
          title: "Alias",
          dataIndex: "alias",
        },*/
        {
          title: "Inicio contrato",
          dataIndex: "startDate",
          align:'right',
          className:"select-none",
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:100,
          render: (props) => {
            return dayjs(props).format("DD/MM/YYYY");
          },
        },
        {
          title: "Fin contrato",
          dataIndex: "endDate",
          className:"select-none",
          align:'right',
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:100,
          render: (props) => {
            return dayjs(props).format("DD/MM/YYYY");
          },
        },
        {
          title: "#Emp. Vig.",
          dataIndex: "numEmployees",
          align:'right',
          className:"select-none",
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:100,
        },
        {
          title: "Max Emp Perm",
          dataIndex: "maxEmployees",
          align:'right',
          className:"select-none",
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:120,
        },
        {
          title: "Bloquear",
          dataIndex: "lock",
          align:'center',
          className:"select-none",
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:80,
          render: (props) => {
            return props === 0 ? 'NO' : 'SI'
          },
        },
        {
          title: "Activo",
          dataIndex: "active",
          align:'center',
          className:"select-none",
          width:60,
          ellipsis: true,
          render: (props) => {
            return props ? 'SI' : 'NO'
          },
        },
        {
          title: "Editar",
          align:'center',
          width:80,
          ellipsis: true,
          render: (ActionProps) => {
            return (
              <div className="flex justify-center select-none">
                {props.findUserRights("60") ? (
                  <Tooltip placement="top" title={'Editar'} >
                  <UIButton
                    className="flex justify-center items-center bg-blue-200 p-2 select-none"
                    onClick={() => {
                      props.editarSucursalAbrModal( ActionProps );
                    }}
                    icon={<EditOutlined className="select-none" />}
                  />
                </Tooltip>
                ) : null}
              </div>
            );
          },
        },
      ];
      
    return (<>
        <Drawer 
            title={props.title} 
            open={props.open} 
            onClose={props.onClose}
            width={"80%"}
        >
            <Card title="Surcursales asignadas" size="small" className="flex flex-col w-11/12">
                <div className="flex flex-col gap-4 select-none">
                    <Table
                        bordered={true}
                        columns={columnsBranchesAbrhil}
                        dataSource={props.itemsBranchesAbrhil}
                        className="w-full headerTable drawerAbrhil select-none 
                        3xl:min-w-[750px] 2xl:min-w-[750px] xl:min-w-[750px] lg:min-w-[550px] md:min-w-[200px] sm:min-w-[200px] xs:min-w-[200px] min-w-[200px]  "
                        rowKey={(r,i) => 'drawerAbrhil_'+i}
                        onRow={(record,i)=>{
                            return{
                                onClick:(event) => {
                                    $('.drawerAbrhil tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                                    $(`[data-row-key="drawerAbrhil_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                                }
                            }
                        }}	
                        size="small"
                        pagination={{
                          size: 'small',
                          position: ['bottomRight'],
                          defaultPageSize: 10,
                          current: (props.itemsBranchesAbrhil)?.currentPage,
                          total: (props.itemsBranchesAbrhil)?.totalRecordsCount,
                          showTotal: (total) => `Total ${total} sucursales`
                        }}
                    />
                </div>
            </Card>
            <Drawer 
              open={props.drawer.open}
              title={props.drawer.title} 
              onClose={props.drawer.onClose}
              className="3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[70%] sm:w-[70%] xs:w-[70%] w-[70%]"
              extra={props.extra}
            >
              <Card title="" className="flex 3l:max-w-[400px]">
                <div className="flex flex-col gap-4 select-none">
                  <div className="flex gap-4 select-none flex-wrap max-w-[400px]">
                    <Descriptions className="flex gap-4 select-none">
                      <Descriptions.Item label="Clave" key="Clave" 
                      className="3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12">
                        {props.drawer.data._clvSucursalAbr}</Descriptions.Item>
                      <Descriptions.Item label="Nombre" key="Nombre" 
                      className="3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12">
                        {props.drawer.data._nombreAbr}
                        </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div className="flex gap-4 items-end select-none">
                    <DateRangerPicker 
                      title={"Fecha de inicio y fin de contrato"}
                      placeholder={["Fecha inicio", "Fecha fin"]}
                      format="DD/MM/YYYY"
                      value={[dayjs(props.drawer.data._inicioContratoAbr), dayjs(props.drawer.data._finContratoAbr)]}
                      onChange={(data)=>{
                        if(data)props.drawer.actions.rangeDays(data)
                      }}
                    />
                  </div>
                  <div className="flex flex-wrap gap-4 items-end">
                    <div className="flex flex-col gap-2">
                      <label>Emp. permitidos</label>
                      <Input onChange={props.drawer.actions.maxEmployeesHandlerAbr} value={props.drawer.data._maxEmpleadosAbr}/>
                    </div>
                    <div className="flex gap-2">
                      <label>Activo</label>
                      <Switch onChange={props.drawer.actions.handlerActiveAbr} checked={props.drawer.data._activoAbr} className="bg-slate-200" />
                    </div>
                    <div className="flex gap-2">
                      <label>Bloqueado</label>
                      <Switch onChange={props.drawer.actions.lockHandlerAbr} checked={props.drawer.data._bloqueoAbr} className="bg-slate-200" />
                    </div>
                  </div>
                </div>
              </Card>
            </Drawer>
        </Drawer>
    </>);
}
export default React.memo(BranchesAbrhil)



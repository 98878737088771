import React, { useEffect, useState } from 'react';
//import { Loader, Button, Form, Grid, Header, Message, Segment, Image } from 'semantic-ui-react';
import { Spin, Button, Form, Input, Alert, Card, Modal, notification } from "antd";
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { useNavigate } from 'react-router-dom';

function Login() {
	const [username, setUsetname] = useState('');
	const [password, setPassword] = useState('');
	const [_key, set_key] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [messageError, setMessageError] = useState('');
	const [_modalMsj, set_modalMsj] = useState(false);

	const [respMessage,setRespMessage] = useState('')
	const [colorMessage,setColorMessage] = useState('')

	let navigate = useNavigate();

	const handlerUsername = (e) => {
		let user = e.target.value;
		user = user.replace(/\s+/g, '');
		e.target.value = user
		setUsetname(user);
	}

	const handlerPassword = (e) => setPassword(e.target.value);

	const handlerKey = (e) => set_key(e.target.value);

	const handleCancel = () => set_modalMsj(false); 

	const handleOk = () => {
		let token = localStorage.getItem('x-access-token');		
		setLoading(true)
		fetch(urlAPI.url + 'loginMsj/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify({key: _key}),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				access(json);
			}else{
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			setLoading(false)
		});
	}

	const access = (resp) => {
		let token = resp.data[0].token;
		let userType = resp.data[0].userType;
		localStorage.setItem('x-access-token', token);
		localStorage.setItem('username', username);
		localStorage.setItem('type', userType);
		localStorage.setItem('country', resp.data[0].country);
		userRights();
		setLoading(false);
		navigate("/startPage");
	}

	function validity(){
		if(username==="" ){
			setMessageError('El campo de usuario es obligatorio');
			return;
		}

		if(password==="" ){
			setMessageError('El campo de contraseña es obligatorio');
			return;
		}
	}

	function onSubmit(e) {
		e.preventDefault();
		validity();
		setLoading(true);
		setMessageError('');
		userService.login(username, password).then(
			(resp) => {
				if (resp.status) {
					if(resp.data[0].type === 7 && !resp.data[0].access){
						set_modalMsj(true);
						let token = resp.data[0].token;
						localStorage.setItem('x-access-token', token);
					}else access(resp);
				} else {
					localStorage.removeItem('x-access-token');
					localStorage.removeItem('username');
					localStorage.removeItem('type');
					setMessageError(resp.mensaje);
				}
				setLoading(false);
			},
			(error) => {setError(error);setMessageError('No se ha establecido una conexión con el servidor');setLoading(false);}
		);
	}

	// Se guardar los permisos del usuario en el localStorage
	function userRights() {
		let token = localStorage.getItem('x-access-token');

		fetch(urlAPI.url + `userRights/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.status) {
					let rights = json.data[0];
					localStorage.setItem('userRights', JSON.stringify(rights));
					localStorage.setItem('access',json.access);
				}
			});
	}
	
	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])

	const alertMessage = (type) =>{
	switch(type){
		case 'success': return 'Correcto';
		case 'warning': return 'Advertencia';
		case 'error': return 'Error';
		default: return 'Advertencia';
	}
	}

	function openNotification () {
		api[colorMessage]({
		  message: alertMessage(colorMessage),
		  description: respMessage,
		  placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

	return (
		<div className={`w-full min-h-screen flex items-center justify-center bg-[url('./img/back.png')] bg-no-repeat`}>
		{contextHolder}
			<Spin spinning={loading} size='large'>
			<Card className='w-2/5 min-w-[350px] h-content py-5 flex flex-col items-center justify-center bg-white/50 gap-4' title="Iniciar sesión" >
				<Form className='flex flex-col gap-4'>
					<Input placeholder="Usuario" onChange={(e) => handlerUsername(e)} onPressEnter={(e) => onSubmit(e) } 
					className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'/>
					<Input.Password placeholder="Contraseña" onChange={(e) => handlerPassword(e)} onPressEnter={(e) => onSubmit(e) } 
					className='w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
					
						<Button type="primary" className='bg-[#1677ff] flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={(e) => onSubmit(e)}  disabled={loading?true:null}>
							Ingresar
						</Button>
				</Form>
				{messageError ? <Alert className='mt-4' type='error' description={[messageError]} /> : null}
			</Card>

			<Modal 
				title="Autentificación de doble factor" 
				open={_modalMsj} onOk={_key === "" ? '' : handleOk} 
				onCancel={handleCancel}
				okText="Guardar"
				cancelText="Cancelar"
				okButtonProps={{className:'bg-[#1677ff]'}}
        className='flex justify-center items-center'>
					<div className='flex flex-col gap-2  min-w-[350px]'>
						<Input
							value={_key}
							placeholder="Clave"
							onChange={(e) => {handlerKey(e)}}
							maxLength={10}
							status={_key === '' ? 'error' : 'success'}
						/>
						<label className=''>
							Se ha enviado un msj de texto con la clave de ingreso, esta clave sera valida por 5 minutos 
							</label>
					</div>
			</Modal>
			</Spin>
		</div>
	);

}
export default React.memo(Login)

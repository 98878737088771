import React, { useState, useEffect, useRef} from "react";
import { Input, Card, Switch, Table, Space, Badge} from "antd";
import { SearchOutlined} from '@ant-design/icons';
import $ from 'jquery';
import Highlighter from 'react-highlight-words';
import urlAPI from '../../components/urlAPI/urlAPI.json';
import { userService } from '../../components/services/services';
import Button from "../Button";
import { useNavigate } from 'react-router-dom';
import Select from "../Select";

function ClientTable( props ){
	const [_showAllBranches,set_showAllBranches] = useState(false);
	const [itemsCompanies,setItemsCompanies] = useState([]);
	const [itemsBranches,setItemsBranches] = useState([]);
	const [itemsRelBranchComputer,setItemsRelBranchComputer] = useState([]);
	const [_idBranch ,set_idBranch] = useState(0);

	//VARIABLE PARA DESHABILITAR EL Select
	const [dropDisabled,setDropDisabled] = useState(false);
	// VARIABLE PARA LIMPIAR EL Select
	const [cleanDrop,setCleanDrop] = useState(false);
	// VARIABLE PARA GUARDAR EL VALOR DEL Select SELECCIONADO
	const [dropValueSelected,setDropValueSelected] = useState();
	const [_idCompanyBranch,set_idCompanyBranch] = useState('');
	const [_idCompany,set_idCompany] = useState();
    
	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);

	//PERMISOS DE USUARIO
	const [rights, setRights] = useState([]);

	// Llamadas al API
	useEffect(() => {
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		//this.setState({ rights: userRights });
		setRights(userRights)
		getCompanies()
	},[])

	function getCompanies(){
		let token = localStorage.getItem('x-access-token');

		fetch(urlAPI.url + 'companiesView/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					let arrCompanies = [];
					let data = json.data[0];

					data.forEach((element) => {
						arrCompanies.push({
							value: element.idCompany,
							label: element.codeClient,
						});
					});
					setItemsCompanies(arrCompanies)
				}
			} else {
				logout();
			}
		});
	}

	const toggleBranches = (checked) => {
		set_showAllBranches(checked)
		setItemsRelBranchComputer([]);
	};
	useEffect(()=>{validaAllBranches();},[_showAllBranches]);

	function validaAllBranches(){
		$('.clientTable tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
		if (_showAllBranches === true) {
			loadAllBranches();
			setDropDisabled(true);
			set_idCompanyBranch('');
			setDropValueSelected();
			props.clearDropDown() 
		} else {
			setItemsBranches([]);
			setItemsRelBranchComputer([]);
			setDropDisabled(false);
			set_idCompanyBranch('');
			setDropValueSelected();
			props.clearDropDown() 
		}
	};

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	function loadAllBranches() {
		let token = localStorage.getItem('x-access-token');

		fetch(urlAPI.url + `branchesView/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.status) {
					setItemsBranches(json.data[0]);
				}
			});
	}

	function loadBranches(idCompany) {
		let id = idCompany;
		let token = localStorage.getItem('x-access-token');

		fetch(urlAPI.url + `companyBranch/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.status) {
					setItemsBranches(json.data[0]);
				}
			});
	}

	const companyHandler = (value) => {
		toggleBranches(false)
		setCleanDrop(false);
		props.clearDropDown() 
		$('.clientTable tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
		if (value === '' || value === 0 || value === undefined) {
			set_idCompanyBranch('');
			setItemsBranches([]);
			setItemsRelBranchComputer([]);
			setDropValueSelected()
		} else {
			setDropValueSelected(value);
			loadBranches(value);
		}
	}

	let clearDropDown = () => {
		toggleBranches(false);
		setDropDisabled(false);
		setCleanDrop(true);
		setItemsBranches([]);
		setItemsRelBranchComputer([]);
		set_idCompanyBranch('');
	};


	//Filtros
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	  };
	  const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	  };
	  const getColumnSearchProps = (dataIndex) => ({
		  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			  <Input
				ref={searchInput}
				placeholder={`Buscar ${dataIndex}`}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
				style={{ marginBottom: 8, display: 'block', }}
			  />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  icon={<SearchOutlined />}
				  size="small"
				  style={{
					width: 90,
				  }}
				  className="bg-[#1677ff]"
				  text="Buscar"
				/>
				
				<Button
				  onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				  size="small"
				  style={{
					width: 90,
				  }}
				  text="Borrar"
				/>
				
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					confirm({
					  closeDropdown: false,
					});
					setSearchText(selectedKeys[0]);
					setSearchedColumn(dataIndex);
				  }}
				  text="Filtrar"
				/>
				  
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					close();
				  }}
				  text="Cerrar"
				/>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered) => (
			filtered ?
			(<Badge className="animate-pulse" dot>
			  <SearchOutlined
				className='w-[20px] h-[20px] text-[18px] '
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />
			</Badge>):(<SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />)
		  ),
		  onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		  onFilterDropdownOpenChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current?.select(), 100);
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{
				  backgroundColor: '#ffc069',
				  padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			  />
			) : (
			  text
			),
		});


	const columnsBranches = [
		{
			title: 'Cliente',
			dataIndex: 'description',
			key: 'description',
			className:'p-[10px]',
			...getColumnSearchProps('description'),
		},
	];

	const options = itemsCompanies
    
    return(
        <div className="max-w-[350px]">
            <Card>
							<div className='flex flex-col gap-2 min-w-[300px]'>
								<div className="flex gap-2 w-full">
										<Switch onChange={toggleBranches} checked={_showAllBranches} className="bg-slate-200"/>
										<label className="">Todos los clientes</label>
								</div>
								<div className="flex flex-col gap-2 w-full"> 
												<label>Seleciona un corporativo</label>
                        <Select options={options} 
													className="w-full flex justify-center items-center" 
													onChange={(val)=>{companyHandler(val)}} 
													onClear={()=>{
														clearDropDown() 
														props.clearDropDown() 
													}} 
													value={dropValueSelected}
												/>
								</div>
								<Table 
										selections={false}
										bordered={true}
										columns={columnsBranches} 
										dataSource={itemsBranches} 
										rowKey={(r, i) => 'client_'+i}
										rowClassName={'text-xs cursor-pointer ' }
										size='small'
										className="headerTable clientTable"
										onRow={(record,i)=>{
												return{
														onClick:(event) => {
																$('.clientTable tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
																$(`[data-row-key="client_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');
																props.onRowClickBranches(record,itemsBranches)									
														}
												}
										}}	
										
								/>
							</div>
            </Card>
        </div>
    )
}

export default React.memo(ClientTable)
import React, { useState, useEffect, useRef } from 'react';
import urlAPI from '../urlAPI/urlAPI.json';
import Highlighter from 'react-highlight-words';
import { userService } from '../services/services';
import { Button, Popconfirm, Modal, Input, notification, Tooltip, Card, Steps, Space, Spin, Badge } from "antd";
import dayjs from 'dayjs';
import $ from 'jquery'
import { PlusSquareOutlined, UserOutlined, ApiOutlined, SearchOutlined } from '@ant-design/icons'
import Table from '../../@ui/Table';
import { useNavigate } from 'react-router-dom';

function Structure (){

	const [itemsCompanies,setItemsCompanies] = useState([])
	const [itemsBranches,setItemsBranches] = useState([])

	const [tableCompanies,setTableCompanies] = useState([])
	const [tableBranches,setTableBranches] = useState([])

	const [_idCompanyBranch,set_idCompanyBranch] = useState('')
	const [_idCompany,set_idCompany] = useState('')
	const [_idBranch,set_idBranch] = useState('')

	const [_idCompanyTable,set_idCompanyTable] = useState('')
	const [_idBranchTable,set_idBranchTable] = useState('')

	const [_idCompanyAux,set_idCompanyAux] = useState('')

	const [openModAddCompany,setOpenModAddCompany] = useState(false)
	const [openModAddBranch,setOpenModAddBranch] = useState(false)

	const [openModDelBranch,setOpenModDelBranch] = useState(false)

	const [openModEditUser,setOpenModEditUser] = useState(false)

	//USUARIO DE LA SUCURSAL
	const [_idUserCompanyBranch,set_idUserCompanyBranch] = useState('')
	const [_branchUser,set_branchUser] = useState('')
	const [_branchPass,set_branchPass] = useState('')

	// _showPCs,set] false,
	const [_codeClient,set_codeClient] = useState('')
	const [_branchDesc,set_branchDesc] = useState('')

	// VARIABLES PARA MOSTRAR MENSAJES
	const [existMessage,setExistMessage] = useState(false)
	const [respMessage,setRespMessage] = useState('')
	const [colorMessage,setColorMessage] = useState('')

	const [classMessage,setClassMessage] = useState(0)

	// NO EXISTE USUARIO
	const [_noUser,set_noUser] = useState(false)

	//variables para seleccion de fila
	const [rowSelectedCompany,setRowSelectedCompany] = useState(-1)
	const [rowSelectedBranch,setRowSelectedBranch] = useState(-1)
	const [rowSelectedTblCompany,setRowSelectedTblCompany] = useState(-1)
	const [rowSelectedTblBranch,setRowSelectedTblBranch] = useState(-1)

	//PERMISOS DE USUARIO
	const [rights,setRights] = useState([])

	const [current, setCurrent] = useState(0);


	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [loading, setLoading] = useState(false);

	useEffect(() =>{
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
		loadCompanies()
	},[])

	useEffect(()=>{},[_idCompanyBranch])
	useEffect(()=>{},[_idUserCompanyBranch])
	useEffect(()=>{},[tableCompanies])

	const next = () => {
		setCurrent(current + 1);
	};
	
	const prev = () => {
		setCurrent(current - 1);
	};

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	function loadCompanies() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + 'companiesView/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsCompanies(json.data[0])
				let data = itemsCompanies;
				let found = false;
				data.forEach((element) => {
					if (element.idCompany === _idCompanyAux) {
						found = true;
						return found;
					} else {
						found = false;
						return found;
					}
				});

				if (found) {
					loadBranches(_idCompanyAux);
				} else {
					set_idCompanyAux('');
					set_idCompanyBranch('');
					set_idCompany('');
					set_idCompanyTable('');
					setItemsBranches([]);
				}
			}
			setLoading(false)
		});
	}

	function loadBranches(idCompany) {
		let id = idCompany;
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `companyBranch/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsBranches(json.data[0]);
			} 
			setLoading(false);
		});
	}

	function loadUser(props) {
		let id = props.idCompanyBranch;
		let token = localStorage.getItem('x-access-token');
		set_idCompanyBranch(id)
		
		setLoading(true)
		fetch(urlAPI.url + `userCompanyBranch/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				if (json.data[0].length === 0) {
					set_noUser(true);
				} else {
					set_idUserCompanyBranch(json.data[0][0].idUserCompanyBranch);
					set_branchUser(json.data[0][0].nameUser);
					set_branchPass('----------');
					set_noUser(false)
				}
			} 
			setLoading(false)
		});
	}

	function insertUpdateUser() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevaRelacion = {
			idUserCompanyBranch: _idUserCompanyBranch,
			idCompanyBranch: _idCompanyBranch,
			nameUser: _branchUser,
			password: _branchPass,
			userCap: userLogged,
		};
		
		setLoading(true)
		fetch(urlAPI.url + 'userCompanyBranch/', {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevaRelacion),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setExistMessage(true);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true);
				setRespMessage(json.mensaje);
				setColorMessage('error')
			}
			cleanFieldsUser();
			closeEditUser();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function deleteUserBranch() {
		
		let token = localStorage.getItem('x-access-token');
		let id = _idUserCompanyBranch;

		setLoading(true)
		fetch(urlAPI.url + `userCompanyBranch/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setExistMessage(true);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setExistMessage(true);
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			cleanFieldsUser();
			closeEditUser();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function loadTableCompanies() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `companiesNotView/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setTableCompanies(json.data[0]);
			}
			setLoading(false)
		});
	}

	function loadTableBranches() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `branchesNotView/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setTableBranches(json.data[0]);
			} 
			setLoading(false)
		});
	}

	function addRelCompanyBranch() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevaRelacion = {
			idCompanyBranch: '',
			idCompany: _idCompanyTable,
			idBranch: _idBranchTable,
			userCap: userLogged,
		};

		setLoading(true)
		fetch(urlAPI.url + 'companyBranch/', {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevaRelacion),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				//componentDidMount();
				loadCompanies();
				loadBranches(_idCompanyTable);
				
				setExistMessage(true);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setExistMessage(true);
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			
			set_idBranch('');
			set_idBranchTable('');
			set_idCompanyBranch('');
			closeNuevaSucursal();
			transitionMsn();
			limpiarMensaje();
			closeAddStructure();
			setLoading(false)
		});
	}

	function delRelCompBranch(idCompanyBranch) {
		
		let token = localStorage.getItem('x-access-token');
		let id = idCompanyBranch;

		setLoading(true)
		fetch(urlAPI.url + `companyBranch/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.error(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadCompanies();
				setExistMessage(true);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setExistMessage(true);
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			
			set_idBranch('');
			set_idBranchTable('');
			set_idCompanyBranch('');
			closeEliminarSucursal();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	// EMPRESAS
	function showNuevaEmpresa (){
		setOpenModAddCompany(true);
		set_idCompany('');
		set_idCompanyTable('');
		setItemsBranches([]);
		//set_idBranch('');
		//setv_idBranchTable('');
		loadCompanies();
		loadTableCompanies();
	};

	// SUCURSALES
	function showNuevaSucursal (){
		setCurrent(1)
		setOpenModAddCompany(true);
		set_idBranch('');
		set_idBranchTable('');
		loadTableBranches();
	};

	function closeNuevaSucursal() {
		setOpenModAddBranch(false);
		//set_idCompanyBranch('');
		//set_idCompany('');
		set_idBranch('');
		set_idBranchTable('');
		//setItemsBranches([]);
		//setRowSelectedCompany(-1);
		setRowSelectedTblCompany(-1);
		setRowSelectedTblBranch(-1);
	};

	//MODALS
	function closeEliminarSucursal() {
		setOpenModDelBranch(false);
		set_idCompanyBranch('');
		//set_idCompany('');
		set_idBranch('');
		set_idBranchTable('');
		set_branchDesc('');
		setRowSelectedBranch(-1);

	};

	// USUARIO
	function showEditUser(props) {
		set_idCompanyBranch(props.idCompanybranch);
		loadUser(props);
		setOpenModEditUser(true);
	};

	function closeEditUser() {
		setOpenModEditUser(false);
		cleanFieldsUser();
	};

	//HANDLERS
	function userHandler(e) {
		set_branchUser(e.target.value);
	}

	function passHandler(e) {
		set_branchPass(e.target.value);
	}

	function limpiaCampos() {
		set_idCompanyBranch('');
		set_idCompany('');
		set_idCompanyTable('');
		set_idBranch('');
		set_idBranchTable('');
	}

	function cleanFieldsUser() {
		set_branchUser('');
		set_branchPass('');
	}

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function transitionMsn() {
		setTimeout(() => {
			setClassMessage(1);
		}, 4000 );		
	};

	function limpiarMensaje(){
		setTimeout(() => {
			setExistMessage(false);
			setRespMessage('');
			setColorMessage('');
			setClassMessage(0)
		}, 6000);
	};
	

	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	  };

	  const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	  };

	  const getColumnSearchProps = (dataIndex) => ({
		  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			  <Input
				ref={searchInput}
				placeholder={`Buscar ${dataIndex}`}
				value={selectedKeys[0]}
				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
				style={{ marginBottom: 8, display: 'block', }}
			  />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  icon={<SearchOutlined />}
				  size="small"
				  style={{
					width: 90,
				  }}
				  className="bg-[#1677ff]"
				>
				  Buscar
				</Button>
				<Button
				  onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				  size="small"
				  style={{
					width: 90,
				  }}
				>
				  Borrar
				</Button>
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					confirm({
					  closeDropdown: false,
					});
					setSearchText(selectedKeys[0]);
					setSearchedColumn(dataIndex);
				  }}
				>
				  Filtrar
				</Button>
				<Button
				  type="link"
				  size="small"
				  onClick={() => {
					close();
				  }}
				>
				  Cerrar
				</Button>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered) => (
			filtered ?
			(<Badge className="animate-pulse" dot>
			  <SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />
			</Badge>):(<SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />)
		  ),
		  onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		  onFilterDropdownOpenChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current?.select(), 100);
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{
				  backgroundColor: '#ffc069',
				  padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			  />
			) : (
			  text
			),
		});

	const columnsCompanies = [
		{
			title: 'Corporativo',
			dataIndex: 'codeClient',
			key: 'codeClient',
			ellipsis: true,
			...getColumnSearchProps('codeClient'),
		},
	];

	const columnsBranches = [
		{
			title: 'Cliente',
			dataIndex: 'description',
			key: 'description',
			ellipsis: true,
			...getColumnSearchProps('description'),
		},
		{
			title: 'Clave',
			dataIndex: 'keyClient',
			key: 'keyClient',
			align:'center',
			width:100,
			responsive: ['xxl', 'xl', 'lg'],
			ellipsis: true,
			...getColumnSearchProps('keyClient'),
		},
		{
			title: 'Usuario',
			key: 'usuario',
			align:'center',
			className:'p-0',
			width:80,
			ellipsis: true,
			render: (props) => {
				return (
					<div className='flex justify-center p-0'>
						{findUserRights('15') ? (
							<Tooltip title='Editar'>
								<Button
									className="flex justify-center items-center bg-green-200 w-2/3"
									onClick={() => {showEditUser(props)}}
								>
									<UserOutlined className=' 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-lg md:text-md sm:text-md xs:text-md text-md' />
								</Button>
							</Tooltip>
						) : null}
					</div>
				);
			},
		},
		{
			title:'Desligar',
			key:'desligar',
			align:'center',
			className:'p-0',
			width:80,
			ellipsis: true,
			render: (props) => {
				return (
					<div className='flex justify-center p-0'>
					{findUserRights('14') ? (
						<Popconfirm 
							title='¿ Desea desligar el siguiente cliente ?'
							description={props.description}
							onConfirm={() => { 
								delRelCompBranch(props.idCompanyBranch)
							}}
							okText='Desligar'
							cancelText='Cancelar'
							overlayClassName='text-ellipsis text-xs w-[300px] max-h-[150px]'
							okButtonProps={{'className':'bg-[#1677ff]'}}
						>
							<Tooltip title='Desligar'>
								<Button className="flex justify-center items-center bg-red-200 w-2/3" >
									<ApiOutlined className='3xl:text-lg 2xl:text-lg xl:text-lg lg:text-lg md:text-md sm:text-md xs:text-md text-md '/>
								</Button>
							</Tooltip>
						</Popconfirm>
				) : null}
				</div>
				)
			}
		}
	];

	const columnsTableCompanies = [
		{
			title: 'Empresa',
			key: 'codeClient',
			dataIndex: 'codeClient',
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'Descripción',
			key: 'descCompany',
			dataIndex: 'descCompany',
			align:'center',
			ellipsis: true,
		},
	];

	const columnsTableBranches = [
		{
			title: 'Descripción',
			dataIndex: 'description',
			key: 'description',
			ellipsis: true,
		},
		{
			title: 'Inicio Contrato',
			dataIndex: 'startDate',
			key: 'startDate',
			align:'right',
			width:120,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
		{
			title: 'Fin Contrato',
			dataIndex: 'endDate',
			key: 'endDate',
			align:'right',
			width:120,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
	];

	let convertToDate = (date) => {
		let fecha = dayjs(date).format('DD/MM/YYYY');
		return fecha;
	};

	const onRowClickCompany = (rowInfo) => {
		
		set_idCompany(rowInfo.idCompany);
		set_idCompanyTable(rowInfo.idCompany);
		set_idCompanyAux(rowInfo.idCompany);
		set_codeClient(rowInfo.codeClient);
		set_idBranch('');
		set_idCompanyBranch('');
		loadBranches(rowInfo.idCompany);
	};

	//CONTROLES DE LAS TABLAS DE LOS MODALES
	const onRowClickTableCompany = (rowInfo) => {
		set_idCompanyTable(rowInfo.idCompany);
		loadTableBranches();
	};

	const onRowClickTableBranch = ( rowInfo ) => {
		set_idBranchTable(rowInfo.idBranch)
	};

	function closeAddStructure(){
		setOpenModAddCompany(false)
		set_idCompanyTable('')
		set_idBranchTable('')
		setCurrent(0)
	}

	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};

	let steps = [
		{
			title:'Seleccionar la empresa',
			description:[
				<Table 
					bordered={true}
					columns={columnsTableCompanies}
					dataSource={tableCompanies}
					className="w-11/12 headerTable"
					rootClassName='cursor-pointer'
					size='small'
					pagination={false}
					rowKey={(a,b)=> 'addCompany_'+b}
					onRow={(record, index)=>{
						return{
							onClick:(event)=>{
								$('tr').removeClass('bg-blue-400');
								$('tr[data-row-key=addCompany_'+index+']').addClass('bg-blue-400');
								onRowClickTableCompany(record)
							}
						}
					}}
				/>
			],
		},
		{
			title:'Seleccionar la sucursal',
			description:[
				<Table 
					bordered={true}
					columns={columnsTableBranches}
					dataSource={tableBranches}
					className="w-11/12 headerTable"
					rootClassName='cursor-pointer'
					size='small'
					pagination={false}
					rowKey={(a,b)=>'addBranch_'+b}
					onRow={(record, index)=>{
						return{
							onClick:(event)=>{
								$('tr').removeClass('bg-blue-400');
								$('tr[data-row-key=addBranch_'+index+']').addClass('bg-blue-400');
								onRowClickTableBranch(record);
							}
						}
					}}
				/>
			],
		}
	]

	const items =  steps.map((item) => ({
		key: item.title,
		title: item.title,
	  }));
	
	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])

	const alertMessage = (type) =>{
	switch(type){
		case 'success': return 'Correcto';
		case 'warning': return 'Advertencia';
		case 'error': return 'Error';
	}
	}

	function openNotification () {
		api[colorMessage]({
		  message: alertMessage(colorMessage),
		  description: respMessage,
		  placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

	return (
		<div className='flex gap-2'>
			{contextHolder}
			<Spin spinning={loading} size='large'>	
			<div className='flex gap-2 flex-wrap'>
				<Card title="" className='flex'>
					<Table
						bordered={true}
						columns={columnsCompanies}
						dataSource={itemsCompanies}
						className="max-w-[400px] min-w-[250px] headerTable"
						rootClassName='cursor-pointer'
						rowKey={(a,b)=> 'company_'+b }
						size='small'
						onRow={(record, index)=>{
							return{
								onClick:(event)=>{
									$('tr').removeClass('bg-blue-400');
									$('tr[data-row-key=company_'+index+']').addClass('bg-blue-400');
									onRowClickCompany(record)
								}
							}
						}}
					/>

					<div className="">
						{findUserRights('13') ? (
							<Button 
							icon={<PlusSquareOutlined className='text-xl'/>}
							onClick={()=>{showNuevaEmpresa()}}
							type='primary'
							className='bg-[#1677ff] flex justify-center items-center'
							>
							Agregar
							</Button>
						) : null}
					</div>
				</Card>

				{/* TABLA SUCURSALES RELACIONADAS	*/}
				<Card title="" className='flex'>
					<Table
						bordered={true}
						columns={columnsBranches}
						dataSource={itemsBranches}
						rowClassName='text-xs'
						className="headerTable sucRel flex max-w-[900px] 3xl:min-w-[900px] 2xl:min-w-[900px] xl:min-w-[900px] lg:min-w-[700px] md:min-w-[500px] sm:min-w-[300px] min-w-300px]"
						size='small'
						rowKey={(a,i)=> 'branch_'+i }
						onRow={(record,i)=>{
							return{
								onClick:(event) => {
									$('.sucRel tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
									$(`[data-row-key="branch_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
								}
							}
						}}	
					/>

					<div className="">
						<div className='flex justify-between'>
							<div className="">
								{findUserRights('13') ? (
								<Button 
								icon={<PlusSquareOutlined className='text-xl'/>}
								disabled={!_idCompany && !_idCompanyBranch}
								onClick={()=>{showNuevaSucursal()}}
								type='primary'
								className='bg-[#1677ff] flex justify-center items-center'
								>
								Agregar
								</Button>
								) : null}
							</div>
						</div>
					</div>
				</Card>
			
			{/* MODAL GUARDAR - EDITAR - ELIMINAR USUARIO */}
			<Modal 
				title='Editar usuario'
				open={openModEditUser} 
				onCancel={closeEditUser}
				cancelText='Cancelar'
				okText='Guardar'
				okButtonProps={{className:'bg-[#1677ff]'}}
				footer={[
					<Button key="back" onClick={closeEditUser}>
					  Cancelar
					</Button>,
					<Popconfirm
						title='Usuario'
						description='¿ Guardar cambios en el usuario ?'
						onConfirm={insertUpdateUser}
						okText='Guardar'
						cancelText='Cancelar'
						okButtonProps={{className:'bg-[#1677ff]'}}
						>
						<Button key="submit" type="primary" className='bg-[#1677ff]'>
						  Guardar
						</Button>
					</Popconfirm>
				]}
			>

				<div>
					<div className="flex flex-col gap-4">
						<label>Usuario</label>
						<Input
							placeholder="Ingrese un usuario"
							onChange={(e) => userHandler(e)}
							value={_branchUser}
						/>
						<label>Contraseña</label>
						<Input
							type="password"
							placeholder="Agregue una contraseña"
							onChange={(e) => passHandler(e)}
							value={_branchPass}
						/>
						{_noUser === false ? (
							<Popconfirm
								title='Confirmar eliminar usuario'
								description='¿ Seguro desea eliminar el usuario ?'
								onConfirm={deleteUserBranch}
								okText='Eliminar'
								cancelText='Cancelar'
								okButtonProps={{className:'bg-red-700 text-white'}}
							>
								<Button className='bg-red-700 text-white w-1/3'	>
									Eliminar usuario
								</Button>
							</Popconfirm>

						) : null}
						<div className="padding-mensage-error">
							{_noUser ? (
								<label> 
									No existe ningun usuario, favor de crearlo
								</label> 
							) : null}
						</div>
					</div>
				</div>
			</Modal>

			{/* MODAL PARA ESTRUCTURA */}
			<Modal open={openModAddCompany} footer={[]} onCancel={()=>{closeAddStructure()}} 
			className='p-5 3xl:min-w-[800px] 2xl:min-w-[800px] xl:min-w-[800px] lg:min-w-[700px] md:min-w-[500px] sm:min-w-[300px] sx:min-w-[300px] min-w-[300px]' >
				<div className='p-5 w-full'>

				<Steps current={current} items={items}/>
				<div className='flex justify-center items-center mt-4'>
					{steps[current].description}
				</div>
					<div style={{ marginTop: 24 }}>
					  {current < steps.length - 1 && (
						<Button type="primary" onClick={() => next()} className='bg-[#1677ff]' disabled={!_idCompanyTable}>
						  Siguiente
						</Button>
					  )}
					  {current === steps.length - 1 && (
						<Button type="primary" className='bg-[#1677ff]' onClick={() => addRelCompanyBranch()} disabled={!_idBranchTable}>
						  Guardar
						</Button>
					  )}
					  {current > 0 && (
						<Button style={{ margin: '0 8px' }} onClick={() => {
							set_idCompanyTable('');
							prev();
						}}>
						  Anterior
						</Button>
					  )}
					</div>
				</div>
			</Modal>
			</div>
			</Spin>
		</div>
	);
}

export default React.memo(Structure)

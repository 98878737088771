import React from 'react';
import logo from '../img/start.svg';
import './StartPage.css';
//import { Grid, Image } from 'semantic-ui-react';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';

function StartPage(){    
    const { Meta } = Card;
    let navigate = useNavigate();
    setTimeout(() => {
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		let access = userRights.map((operation) => {return operation['idOperation']});
        access.includes(10) ? navigate("/catalogue") : navigate("/workers");
    },[2500]);
    return (
        <div className='w-full min-h-screen flex flex-col justify-center items-center'>
            <Card cover={<img src={logo} className="w-1/4" alt="logo" />} className=' p-20 flex flex-col justify-center items-center 
            3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
                <Meta title="Bienvenido al Web Service" />                
            </Card>	
        </div>
    );
}

export default React.memo(StartPage)
import React, { useState, useEffect, useRef } from 'react';
import { Button, Tag, Input, notification, Space, Table, Card, Tooltip, Popconfirm, Spin, Badge} from "antd";
import { PlusSquareOutlined, FormOutlined, ApiOutlined, AppstoreAddOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import urlAPI from '../urlAPI/urlAPI.json';
import ClientTable from '../../@ui/ClientTable'
import $ from 'jquery'
import dayjs from 'dayjs';
import NotAllow from '../../@ui/NotAllow';
import MultiDrawer from './AbrhilMessage.jsx'

function RelBranchMessage() {
	const [itemsRelBranchMsn, setItemsRelBranchMsn] = useState([]);
	const [itemsCatMessages, setItemsCatMessages] = useState([]);
	const [_itemsBranches, set_itemsBranches ] = useState([]);
	const [_itemsBranchArbhil, set_itemsBranchArbhil ] = useState([]);
	// Variables para seleccionar que mensaje tiene la empresa o contrato
	const [_idCompanyBranchMessage, set_idCompanyBranchMessage] = useState('');
	const [_idCompanyBranch, set_idCompanyBranch] = useState('');
	const [_idBranch ,set_idBranch] = useState(0);
	const [_idBranchAbrhil ,set_idBranchAbrhil] = useState(0);
	const [_idCompany, set_idCompany] = useState('');

	// Variables para la tabla de mensajes
	const [_idMessageTbl, set_idMessageTbl] = useState('');
	const [_messageTbl, set_messageTbl] = useState('');
	const [_startDateTbl, set_startDateTbl] = useState('');
	const [_endDateTbl, set_endDateTbl] = useState('');
	const [_blockingTbl, set_blockingTbl] = useState(false);
	const [_msnForAllBranches, set_msnForAllBranches] = useState(false);
	const [_typeMessageTbl, set_typeMessageTbl] = useState();

	const [ arrOpt, setArrOpt ] = useState([])

	//Variables para crear un nuevo mensaje
	const [_messageNew, set_messageNew] = useState('');
	const [_bloqueoNew, set_bloqueoNew] = useState(false);

	// Variables para abrir y cerrar los modales de mensajes
	const [openModNewMsn, setOpenModNewMsn] = useState(false);
	const [openModEditMsn, setOpenModEditMsn] = useState(false);
	const [openModDelMsn, setOpenModDelMsn] = useState(false);
	const [openModAddMsn, setOpenModAddMsn] = useState(false);
	const [isEditMsn, setIsEditMsn] = useState(false);
	const [isNewMsn, setIsNewMsn] = useState(false)

	// Variable para abrir el modal para crear una computadora
	const [openModCreateMsn, setoOpenModCreateMsn] = useState(false);

	// VARIABLES PARA MOSTRAR MENSAJES
	const [existMessage, setExistMessage] = useState(false);
	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');

	//PERMISOS DE USUARIO
	const [rights, setRights] = useState([]);
	useEffect(()=>{setRights(JSON.parse(localStorage.getItem('userRights'))); set_multiIdBranchAbrhil([])},[])
	// Variable para guardar los id's de las sucursales al enviar mensaje
	const [_ddBranchesValueSelected, set_ddBranchesValueSelected] = useState('');

	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])

	//multiCRUD branchMessage
	const [_multiItems, set_multiItems] = useState([]);
	const [openMultiBranchDrawer, setOpenMultiBranchDrawer] = useState(false);
	const [_multiIdBranchAbrhil, set_multiIdBranchAbrhil] = useState([]);
	const [openMultiIUDrawer, setOpenMultiIUDrawer] = useState(false);
	const [_isMultiEdit, set_isMultiEdit] = useState(false);
	const [_countId, set_countId] = useState(0);
	//form multiple
	const [_idMsjMulti, set_idMsjMulti ] = useState('');
	const [_msjMulti, set_msjMulti ] = useState('');
	const [_blockMulti, set_blockMulti ] = useState(false);
	const [_openFormMulti, set_openFormMulti ] = useState(false);
	const [_startDateMulti, set_startDateMulti] = useState('');
	const [_endDateMulti, set_endDateMulti] = useState('');
	const [formMultiEdit, setFormMultiEdit] = useState(false);
	const [_multiBranch, set_multiBranch] = useState([]);
	const [_multiBranchMessage, set_multiBranchMessage] = useState([]);


	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [loading, setLoading] = useState(false);

	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
	clearFilters();
	setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Input
			ref={searchInput}
			placeholder={`Buscar ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ marginBottom: 8, display: 'block', }}
			/>
			<Space>
			<Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
			>
				Buscar
			</Button>
			<Button
				onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				size="small"
				style={{
				width: 90,
				}}
			>
				Borrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				confirm({
					closeDropdown: false,
				});
				setSearchText(selectedKeys[0]);
				setSearchedColumn(dataIndex);
				}}
			>
				Filtrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				close();
				}}
			>
				Cerrar
			</Button>
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
			filtered ?
			(<Badge className="animate-pulse" dot>
			  <SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />
			</Badge>):(<SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />)
		),
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});
	
		//multiple function
	function loadMultiMessages(idCompanyBranch) {
	
		let token = localStorage.getItem('x-access-token');
		
		setLoading(true)
		fetch(urlAPI.url + `multiBranchMessage/${idCompanyBranch}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				set_multiItems(json.data[0])
			} 
			setLoading(false)
		});
	}

	function handlerCloseBMultiBranch(){setOpenMultiBranchDrawer(false);}

	function handlerMultiBranch(){
		setOpenMultiBranchDrawer(true);
		loadMultiMessages(_idCompanyBranch);
	}

	function handlerMultiIU(){
		setOpenMultiIUDrawer(true);
		loadCatMsn();
		set_idCompanyBranchMessage("");
	}

	function addMultiRelMsjAbr(idMsj, msj, block){
		set_idMsjMulti(idMsj);
		set_msjMulti(msj);
		set_blockMulti(block);
		set_openFormMulti(true);
		set_isMultiEdit(true)
		set_startDateMulti(new Date());
		set_endDateMulti(new Date());
	}

	function closeFormMulti(){
		set_idMsjMulti('');
		set_msjMulti('');
		set_startDateMulti(new Date());
		set_endDateMulti(new Date());
		set_blockMulti(false);
		set_isMultiEdit(false)
		set_openFormMulti(false);
	}

	const toggleBlockMuli = (check) => {set_blockMulti(check);}

	function messageMultiHandler(e) { set_msjMulti(e.target.value) }
	
	function rangeDaysMulti(data){
		set_startDateMulti(dayjs(data[0]).format('YYYYMMDD'));
		set_endDateMulti(dayjs(data[1]).format('YYYYMMDD'));
	}

	const handlerCloseMultiBranch = () => {
		setFormMultiEdit(false);
		set_idMsjMulti('');
		set_msjMulti('');
		set_blockMulti(false);
		set_startDateMulti(new Date);
		set_endDateMulti(new Date);
		set_multiBranch([]);
		set_isMultiEdit(false);
	}

	function handlerMultiEdit(data){
		set_isMultiEdit(true);
		setFormMultiEdit(true);
		set_idMsjMulti(data.idMessage);
		set_msjMulti(data.message); 
		set_startDateMulti(data.startDate)
		set_endDateMulti(data.endDate)
		set_blockMulti(data.blockade); 
		set_multiBranch(data.relData);
	}

	function handlerMultiDelete(data){
		
		let arrId = (data.relData).map((item)=>item.idCompanyBranchMessage);

		let token = localStorage.getItem('x-access-token');
		
		setLoading(true)
		fetch(urlAPI.url + `MultiBranchMessage/${arrId.toString()}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadMultiMessages(_idCompanyBranch)
				loadMessages(_idCompanyBranch)
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			handlerCloseMultiBranch();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function handlerEditMultiBranch(){
		let token = localStorage.getItem('x-access-token');
		let multiEdit = {
			idData: _multiBranch,
			idMessage: _idMsjMulti,
			idCompanyBranch:_idCompanyBranch,
			startDate: dayjs(_startDateMulti).format('YYYYMMDD'),
			endDate: dayjs(_endDateMulti).format('YYYYMMDD'),
			blockade: _blockMulti ? 1 : 0,
		};
		
		setLoading(true)
		fetch(urlAPI.url + 'MultiBranchMessage/', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(multiEdit),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadMultiMessages(_idCompanyBranch)
				loadMessages(_idCompanyBranch)
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			closeFormMulti();
			limpiarMensaje();
			handlerCloseMultiBranch();
			setLoading(false)
		});

	}

	function saveMultiRelMsjAbr(){		
		let token = localStorage.getItem('x-access-token');
		let multiId = _multiIdBranchAbrhil.map((item,index)=>{return item.idBranchAbrhil});

		let nuevoRelMsn = {
			idCompanyBranchMessage: _isMultiEdit ? _idCompanyBranchMessage: null,
			idCompanyBranch: _idCompanyBranch,
			idBranch: _multiIdBranchAbrhil[0].idBranch,
			idMessage: _idMsjMulti,
			startDate: dayjs(_startDateMulti).format('YYYYMMDD'),
			endDate: dayjs(_endDateMulti).format('YYYYMMDD'),
			blockade: _blockMulti ? 1 : 0,
			idBranchAbrhil:multiId,
		};
		
		setLoading(true)
		fetch(urlAPI.url + 'MultiBranchMessage/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevoRelMsn),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadBranchAbrhil({idBranch: _multiIdBranchAbrhil[0].idBranch});
				loadMessages(_idCompanyBranch)
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			closeFormMulti();
			limpiarMensaje();
			handlerCloseBMultiBranch();
			setLoading(false)
		});
	}

	function loadBranchAbrhil(rowInfo){
		
		let idBranch = rowInfo.idBranch;
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `branchAbrhil/${idBranch}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				set_itemsBranchArbhil(json.data[0])
			} 
			setLoading(false)
		});
	}

	function loadMessages(idCompanyBranch) {
		
		let token = localStorage.getItem('x-access-token');
		
		setLoading(true)
		fetch(urlAPI.url + `companyBranchMessage/${idCompanyBranch}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsRelBranchMsn(json.data[0])
			} 
			setLoading(false)
		});
	}

	function loadCatMsn() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `messages/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsCatMessages(json.data[0])
			} 
			setLoading(false)
		});
	}

	function relCompBranchMsn() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let idCompanyBranches = _ddBranchesValueSelected.toString();
		
		if(idCompanyBranches === ""){
			idCompanyBranches = _idCompanyBranch
		}
		
		let nuevoRelMsn = {
			idCompanyBranchMessage: isEditMsn ? _idCompanyBranchMessage: null,
			idCompanyBranch: _msnForAllBranches
				? idCompanyBranches
				: _idCompanyBranch,
			idMessage: isEditMsn ? _idMessageTbl : null,
			startDate: dayjs(_startDateTbl).format('YYYYMMDD'),
			endDate: dayjs(_endDateTbl).format('YYYYMMDD'),
			blockade: _blockingTbl ? 1 : 0,
			userCap: userLogged,
			idBranchAbrhil:_idBranchAbrhil,
			allBranches: _msnForAllBranches ? 1 : 0,
		};
		
		setLoading(true)
		fetch(urlAPI.url + 'companyBranchMessage/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevoRelMsn),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadMessages(_idCompanyBranch);
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			cleanFieldsNewRelMsn();
			closeEditar();
			limpiarMensaje();

			//cerrar drawer
			setOpenModAddMsn(false);
			setOpenModNewMsn(false);
			setLoading(false)
		});
	}

	function eliminarMensaje(idCompanyBranchMessageDel) {
		
		let id = idCompanyBranchMessageDel;
		let token = localStorage.getItem('x-access-token');
		
		setLoading(true)
		fetch(urlAPI.url + `companyBranchMessage/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadMessages(_idCompanyBranch);
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			cleanFieldsNewRelMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function addNewMsn() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		if(_messageTbl===""){
			setExistMessage(true)
			setRespMessage('No hay un mensaje para guardar')
			setColorMessage('error')
			return
		}

		let nuevoMensaje = {
			idMessage: '',
			message: _messageTbl,
			blockade: _bloqueoNew ? 1 : 0,
			userCap: userLogged,
		};

		setLoading(true)
		fetch(urlAPI.url + 'messages/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevoMensaje),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadCatMsn();
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			closeModCreateMsn();
			limpiarMensaje();
			closeModalMsn();
			setLoading(false)
		});
	}

	function agregarMsn(idMessage, message, blocking) {
		set_idMessageTbl(idMessage);
		set_messageTbl(message);
		set_blockingTbl(blocking);
		set_startDateTbl(new Date())
		set_endDateTbl(new Date())
		setIsEditMsn(true)
		setOpenModNewMsn(true);
		setOpenModAddMsn(true);
	}

	useEffect(()=>{},[_startDateTbl]);
	useEffect(()=>{},[_endDateTbl]);

	function editarMensajeModal(record) {
		set_idCompanyBranchMessage(record.idCompanyBranchMessage)
		set_idMessageTbl(record.idMessage)
		set_idBranchAbrhil(record.idBranchAbrhil)
		set_messageTbl(record.message)
		set_startDateTbl(record.startDate)
		set_endDateTbl(record.endDate)
		set_blockingTbl(record.blockade)
		setIsEditMsn(true)
		setOpenModNewMsn(true);
		setOpenModAddMsn(true);
	}

	function closeModalMsn(){
		set_idCompanyBranchMessage('')
		set_idBranchAbrhil('')
		set_idMessageTbl('')
		set_messageTbl('')
		set_startDateTbl(new Date())
		set_endDateTbl(new Date())
		set_blockingTbl(false)
		setIsEditMsn(false)
		setOpenModNewMsn(true);
		setOpenModAddMsn(false);
	}
	
	function closeEditar(){
		setOpenModEditMsn(false)
		cleanFieldsNewRelMsn();
	};

	// ABRIR/CERRAR EL MODAL CREAR NUEVO MSN
	function closeModCreateMsn() {
		setoOpenModCreateMsn(false);
		cleenFieldsNewMsn();
	};

	// HANDLERS
	function messageHandler(e) {
		set_messageTbl(e.target.value)
	}

	function cleanFieldsNewRelMsn() {
		set_idMessageTbl('')
		set_messageTbl('')
		set_idBranchAbrhil('')
		set_startDateTbl(new Date())
		set_endDateTbl(new Date())
		set_blockingTbl(false)
		setIsEditMsn(false)
		set_ddBranchesValueSelected('')
		set_msnForAllBranches(false)
	}

	function cleenFieldsNewMsn() {
		set_messageNew('');
		set_bloqueoNew(false)
	}

	const toggleBloking = (check) => {set_blockingTbl(check);}

	const toggleMsnAllBranches = (check) => {set_msnForAllBranches(check);}

	function tipoMensaje (numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function limpiarMensaje(){
		setTimeout(() => {
			setExistMessage(false);
			setRespMessage('');
			setColorMessage('');
		}, 6000);
	}

	const columnsMultiBranch = [
		{
			title: 'Sucursales',
			dataIndex: 'relData',
			render: (rowTags) => {
				return(<span>
					{rowTags.map((item,index)=>{
						let name = item.nombre;
						let variant = ['green', 'cyan', 'blue', 'geekblue', 'purple','green', 'cyan', 'blue', 'geekblue', 'purple'];
							{return(<Tag color={variant[index]} key={name}>
								{name}
							</Tag>)}
					})}
				</span>)
			  },
		},
		{
			title: 'Mensaje',
			dataIndex: 'message',
			...getColumnSearchProps('message'),
		},
		{
			title: 'Fecha Inicio',
			dataIndex: 'startDate',
			width:50,
			render: (props) => {
				return convertToDate(props);
			},
			editable: true,
		},
		{
			title: 'Fecha Fin',
			dataIndex: 'endDate',
			width:50,
			render: (props) => {
				return convertToDate(props);
			},
			editable: true,
		},
		{
			title: 'Bloquear',
			dataIndex: 'blockade',
			align:'center',
			width:50,
			render: (props) => {
				return props ? 'SI' : 'NO';
			},
			editable: true,
		},
		{
			title: 'Acciones',
			align:"center",
			width:100,
			render: (props) => {
				return (
					<div className='flex gap-2'>
						{findUserRights('8') ? (
							<Tooltip title='Editar'>
								<Button
									className="flex justify-center items-center p-2 bg-blue-200"
									onClick={() => {
										handlerMultiEdit(props);
									}}
								>
									<FormOutlined className=' text-lg' />
								</Button>
							</Tooltip>
						) : null}

						{findUserRights('9') ? (
							<Popconfirm 
								title='¿ Desea desligar el siguiente mensaje ?'
								onConfirm={() => { 
									handlerMultiDelete(props)
								}}
								okText='Desligar'
								cancelText='Cancelar'
								overlayClassName='text-ellipsis text-xs w-[350px] max-h-[150px]'
								okButtonProps={{'className':'bg-[#1677ff]'}}
							>
								<Tooltip title='Desligar'>
									<Button
										className="flex justify-center items-center p-2 bg-red-200"
									>
										<ApiOutlined className='text-lg'/>
									</Button>
								</Tooltip>
							</Popconfirm>
						) : null}
					</div>
				);
			},
		},
	];

	const columnsMessages = [
		{
			title: 'Mensaje',
			dataIndex: 'message',
			ellipsis: true,
			className:'break-all'
		},
		{
			title: 'Bloquear',
			dataIndex: 'blockade',
			align:"center",
			width:60,
			ellipsis: true,
			render: (text, record, index) => {
				return blockingToInt(record.blockade);
			},
		},
		{
			title: 'Acción',
			align:"center",
			width:50,
			ellipsis: true,
			render: (text, record, index ) => {
				return (
					<div className='flex justify-center items-center'>
						<Tooltip title="Ligar mensaje a sucursal">
						<Button
							className="flex justify-center items-center bg-blue-200 w-3/4"
							onClick={() => {
								agregarMsn(
									record.idMessage,
									record.message,
									record.blockade
								);
							}}
						>
							<AppstoreAddOutlined className='3xl:text-xl 2xl:text-xl xl:text-xl lg:text-xl md:text-lg sm:text-md'/>
						</Button>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const columnsMultiMessages = [
		{
			title: 'Mensaje',
			dataIndex: 'message',
		},
		{
			title: 'Bloquear',
			dataIndex: 'blockade',
			align:"center",
			width:50,
			render: (text, record, index) => {
				return blockingToInt(record.blockade);
			},
		},
		{
			title: 'Acción',
			align:"center",
			width:50,
			render: (text, record, index ) => {
				return (
					<div className='flex justify-center items-center'>
						<Tooltip title="Ligar mensaje a sucursal">
						<Button
							className="flex justify-center items-center bg-blue-200"
							onClick={() => {
								addMultiRelMsjAbr(
									record.idMessage,
									record.message,
									record.blockade 
								);
							}}
						>
							<AppstoreAddOutlined className='text-xl'/>
						</Button>
						</Tooltip>
					</div>
				);
			},
		},
	];

	useEffect(()=>{},[_itemsBranches])
	useEffect(()=>{},[_ddBranchesValueSelected])
	useEffect(()=>{},[_multiIdBranchAbrhil])

	const onRowClickBranches = (rowInfo, itemsBranches) => {
		let arrBranch=[];
		itemsBranches.forEach((element) => {
			arrBranch.push({
				label: element.description,
				value: element.idCompanyBranch,
			});
		});
		set_itemsBranches(arrBranch)
		set_idBranch(rowInfo.idBranch);
		set_idCompanyBranch(rowInfo.idCompanyBranch);
		loadBranchAbrhil(rowInfo)
		loadMessages(rowInfo.idCompanyBranch);
	};

	function rangeDays(data){
		set_startDateTbl(dayjs(data[0]).format('YYYYMMDD'));
		set_endDateTbl(dayjs(data[1]).format('YYYYMMDD'));
	}

	function blockingToInt (campo){
		switch (campo) {
			case false: {
				return 'NO';
			}
			case true: {
				return 'SI';
			}
			default: {
				return 'NO';
			}
		}
	};

	let convertToDate = (date) => {
		let fecha = moment(date).format('DD/MM/YYYY');
		return fecha;
	};

	let clearDropDown = () => {
		setItemsRelBranchMsn([]);
		set_idCompanyBranch('')
	};

	let findUserRights = (findRight) => {
		
		for (let i in rights) {
			let x = rights[i].idOperation;
			if (x == findRight) {
				return true;
			}
		}
	};

    const alertMessage = (type) =>{
		switch(type){
		  case 'success': return 'Correcto';
		  case 'warning': return 'Advertencia';
		  case 'error': return 'Error';
		}
	  }

	function openNotification () {
		api[colorMessage]({
		  message: alertMessage(colorMessage),
		  description: respMessage,
		  placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}
	
	const columsBranchAbrhil = [
		{
			title: 'Clave',
			dataIndex: 'clv_Sucursal',
			width:30,
			...getColumnSearchProps('clv_Sucursal'),
		},
		{
			title: 'Nombre',
			dataIndex: 'nombre',
			...getColumnSearchProps('nombre'),
		},
		{
			title: 'Acción',
			align:"center",
			width:50,
			render: (text, record, index ) => {
				return (
					<div className='flex justify-center items-center'>
						<Tooltip title="Ligar mensaje a sucursal">
						<Button
							className="flex justify-center items-center bg-blue-200"
							onClick={() => {
								set_idCompanyBranchMessage('')
								set_idBranchAbrhil(record.idBranchAbrhil)
								loadCatMsn();
								setOpenModNewMsn(true);
							}}
						>
							<AppstoreAddOutlined className='text-xl'/>
						</Button>
						</Tooltip>
					</div>
				);
			},
		},
	];

	function handlerAddNewMessage(){
		set_msjMulti('')
		set_blockMulti(false)
		set_startDateMulti(new Date());
		set_endDateMulti(new Date());
		set_openFormMulti(true);
	}
	const handlerCloseMulti = () => {setOpenMultiIUDrawer(false)}

	const expandedRowRender = (record) => {
		const columns = [
			{
				title: 'Mensaje',
				dataIndex: 'message',
				...getColumnSearchProps('message'),
			},
			{
				title: 'Fecha Inicio',
				dataIndex: 'startDate',
				width:50,
				render: (props) => {
					return convertToDate(props);
				},
				editable: true,
			},
			{
				title: 'Fecha Fin',
				dataIndex: 'endDate',
				width:50,
				render: (props) => {
					return convertToDate(props);
				},
				editable: true,
			},
			{
				title: 'Bloquear',
				dataIndex: 'blockade',
				align:'center',
				width:50,
				render: (props) => {
					return props ? 'SI' : 'NO';
				},
				editable: true,
			},
			{
				title: 'Acciones',
				align:"center",
				width:50,
				render: (props) => {
					return (
						<div className='flex gap-2'>
							{findUserRights('8') ? (
								<Tooltip title='Editar'>
									<Button
										className="flex justify-center items-center p-2 bg-blue-200"
										onClick={() => {
										editarMensajeModal(props);
										}}
									>
										<FormOutlined className=' text-lg' />
									</Button>
								</Tooltip>
							) : null}
	
							{findUserRights('9') ? (
								<Popconfirm 
									title='¿ Desea desligar el siguiente mensaje ?'
									onConfirm={() => { 
										eliminarMensaje(props.idCompanyBranchMessage)
									}}
									okText='Desligar'
									cancelText='Cancelar'
									overlayClassName='text-ellipsis text-xs w-[350px] max-h-[150px]'
									okButtonProps={{'className':'bg-[#1677ff]'}}
								>
									<Tooltip title='Desligar'>
										<Button
											className="flex justify-center items-center p-2 bg-red-200"
										>
											<ApiOutlined className='text-lg'/>
										</Button>
									</Tooltip>
								</Popconfirm>
							) : null}
						</div>
					);
				},
			},
		];
		const data = [];
		
		for (let i in itemsRelBranchMsn) {
			if(itemsRelBranchMsn[i].idBranchAbrhil == record.idBranchAbrhil){
				data.push(itemsRelBranchMsn[i])
			}
		}
		return (
			<Card title="Mensajes" size='small'>
				<Table
					bordered={true}
					columns={columns}
					dataSource={data}
					rowClassName='text-xs'
					className='w-[800px] branchMessage'
					size='small'
					rowKey={(r, i) => 'msjAbr_'+i}
					onRow={(record,i)=>{
						return{
							onClick:(event) => {
								$('.branchMessage tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
								$(`[data-row-key="msjAbr_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
							}
						}
					}}	
				/>
			</Card>
		);
	}

	const columMsjBranch = [
		{
			title: 'Mensaje',
			dataIndex: 'message',
			ellipsis: true,
			...getColumnSearchProps('message'),
		},
		{
			title: 'Fecha Inicio',
			dataIndex: 'startDate',
			width:100,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
			editable: true,
		},
		{
			title: 'Fecha Fin',
			dataIndex: 'endDate',
			width:100,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
			editable: true,
		},
		{
			title: 'Bloquear',
			dataIndex: 'blockade',
			align:'center',
			ellipsis: true,
			width:100,
			render: (props) => {
				return props ? 'SI' : 'NO';
			},
			editable: true,
		},
		{
			title: 'Acciones',
			align:"center",
			width:100,
			render: (props) => {
				return (
					<div className='flex gap-2'>
						{findUserRights('8') ? (
							<Tooltip title='Editar'>
								<Button
									className="flex justify-center items-center p-2 bg-blue-200"
									onClick={() => {
										editarMensajeModal(props);
									}}
								>
									<FormOutlined className=' text-lg' />
								</Button>
							</Tooltip>
						) : null}

						{findUserRights('9') ? (
							<Popconfirm 
								title='¿ Desea desligar el mensaje ?'
								onConfirm={() => { 
									eliminarMensaje(props.idCompanyBranchMessage)
								}}
								okText='Desligar'
								cancelText='Cancelar'
								overlayClassName='text-ellipsis text-xs w-[350px] max-h-[150px]'
								okButtonProps={{'className':'bg-[#1677ff]'}}
							>
								<Tooltip title='Desligar'>
									<Button
										className="flex justify-center items-center p-2 bg-red-200"
									>
										<ApiOutlined className='text-lg'/>
									</Button>
								</Tooltip>
							</Popconfirm>
						) : null}
					</div>
				);
			},
		},
	];

	const options = _itemsBranches
	return (
		<div>
			{contextHolder}
			<Spin spinning={loading} size='large'>	
			<div className=''>
				{findUserRights('6') ? (
					<div className='flex gap-4 flex-wrap justify-center
					3xl:min-w-[900px] 2xl:min-w-[900px] xl:min-w-[900px] lg:min-w-[300px] 
				'>
						<ClientTable onRowClickBranches={onRowClickBranches} clearDropDown={clearDropDown}  />
						<div className='flex gap-2 flex-col
						3xl:min-w-[900px] 2xl:min-w-[900px] xl:min-w-[900px] lg:min-w-[300px] 
						w-8/9
						'>
								<Card className='flex w-8/9 p-0'>
									<Table
										bordered={true}
										columns={columMsjBranch}
										dataSource={itemsRelBranchMsn}
										rowClassName='text-xs '
										className='headerTable brachAbrhil max-w-[1000px] 3xl:min-w-[900px] 2xl:min-w-[900px] xl:min-w-[900px] lg:min-w-[300px] md:max-w-[400px] sm:max-w-[350px]'
										size='small'
										rowKey={(r, i) => 'brachAbrhil_'+i}
									/>
								</Card>
								<div className=" flex gap-4">
									{findUserRights('7') ? (
										<Button 
											icon={<PlusSquareOutlined className='text-xl'/>}
											onClick={()=>{
												set_idCompanyBranchMessage('')
												set_idBranchAbrhil('')
												loadCatMsn();
												setOpenModNewMsn(true);}}
											type='primary'
											className='bg-[#1677ff] flex justify-center items-center'
											disabled={!_idCompanyBranch}
										>
											Agregar mensaje
										</Button>
											
									) : null}
								</div>
							</div>
						{/*_itemsBranchArbhil.length != 0 ?(
							<div>
								<Card title="Sucursales Abrhil" size='small' >
									<Table
										bordered={true}
										columns={columsBranchAbrhil}
										dataSource={_itemsBranchArbhil}
										rowClassName='text-xs'
										className='w-[900px] headerTable brachAbrhil'
										size='small'
										rowKey={(r, i) => 'abr_'+i}
										onRow={(record,i)=>{
											return{
												onClick:(event) => {
													$('.brachAbrhil tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
													$(`[data-row-key="abr_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');	
												}
											}
										}}	
										rowSelection={{
											onChange:(key, rowData, type)=>{set_multiIdBranchAbrhil(rowData); set_countId(key.length);}
										}}
										expandable={{
											expandedRowRender:(record)=>(expandedRowRender(record)),
											columnTitle:()=>"Ver",
											columnWidth:10,
										}}
									/>
									<div className=" flex gap-4">
										{findUserRights('7') ? (
											<Button 
												icon={<PlusSquareOutlined className='text-xl'/>}
												onClick={()=>{handlerMultiIU()}}
												type='primary'
												className='bg-[#1677ff] flex justify-center items-center'
												disabled={_countId === 0 || _countId ===1}
											>
												Agregar mensaje en multiples sucursales
											</Button>
												
										) : null}
										{findUserRights('7') ? (
											<Button 
												icon={<EditOutlined className='text-xl'/>}
												onClick={handlerMultiBranch}
												type='primary'
												className='bg-[#1677ff] flex justify-center items-center'
												disabled={!_idCompanyBranch}
											>
												Editar / Desligar mensajes en multiples sucursales
											</Button>
												
										) : null}
									</div>
								</Card>
							</div>)	:
							(<div className='flex gap-2 flex-col'>
								<Card>
									<Table
										bordered={true}
										columns={columMsjBranch}
										dataSource={itemsRelBranchMsn}
										rowClassName='text-xs'
										className='w-[900px] headerTable brachAbrhil'
										size='small'
										rowKey={(r, i) => 'brachAbrhil_'+i}
									/>
								</Card>
								<div className=" flex gap-4">
									{findUserRights('7') ? (
										<Button 
											icon={<PlusSquareOutlined className='text-xl'/>}
											onClick={()=>{
												set_idCompanyBranchMessage('')
												set_idBranchAbrhil('')
												loadCatMsn();
												setOpenModNewMsn(true);}}
											type='primary'
											className='bg-[#1677ff] flex justify-center items-center'
											disabled={!_idCompanyBranch}
										>
											Agregar mensaje
										</Button>
											
									) : null}
								</div>
							</div>)	
						*/}		
					</div>
				):(
					<NotAllow/>
				)}
			</div>
			{/*
			 * Modal para asignar y agregar mensajes 
			 */}
			
			<MultiDrawer 
				open={openModNewMsn}
				onClose={()=>{setOpenModNewMsn(false)}}
				title="Asignar un mensaje"
				card={{
					onClick:()=>{
						set_messageTbl('')
						set_blockingTbl(false)
						set_startDateTbl(new Date());
						set_endDateTbl(new Date());
						set_msnForAllBranches(false);
						set_ddBranchesValueSelected('')
						setArrOpt([]);
						setOpenModAddMsn(true);
					},
					disabled:!_idCompanyBranch,
					table:{
						columns:columnsMessages,
						dataSource:itemsCatMessages
					}
				}}
				childDrawer={{
					open:openModAddMsn,
					onClose:closeModalMsn,
					cancel:closeModalMsn,
					save:()=>{isEditMsn ? relCompBranchMsn(): addNewMsn()},
					title:isEditMsn === true ? 'Editar mensaje':'Nuevo mensaje',
					isEdit:isEditMsn,
					message:_messageTbl,
					messageHandler:messageHandler,
					startDate:_startDateTbl,
					endDate:_endDateTbl,
					rangeDays:rangeDays,
					block:_blockingTbl,
					toggleBlock:toggleBloking
				}}
			/>

			{/** DRAWER MULTI EDIT */}
			<MultiDrawer 
				open={openMultiBranchDrawer}
				onClose={handlerCloseBMultiBranch}
				title="Mensaje por sucursales"
				card={{
					onClick:'',
					disabled:!_idCompanyBranch,
					table:{
						columns:columnsMultiBranch,
						dataSource:_multiItems
					}
				}}
				childDrawer={{
					open:formMultiEdit,
					onClose:handlerCloseMultiBranch,
					cancel:handlerCloseMultiBranch,
					save:handlerEditMultiBranch,
					title:_isMultiEdit === true ? 'Editar mensaje':'Nuevo mensaje',
					isEdit:_isMultiEdit,
					message:_msjMulti,
					messageHandler:messageMultiHandler,
					startDate:_startDateMulti,
					endDate:_endDateMulti,
					rangeDays:rangeDaysMulti,
					block:_blockMulti,
					toggleBlock:toggleBlockMuli
				}}
			/>

			<MultiDrawer 
				open={openMultiIUDrawer}
				onClose={handlerCloseMulti}
				title="Asignar un mensaje"
				card={{
					onClick:handlerAddNewMessage,
					disabled:!_idCompanyBranch,
					table:{
						columns:columnsMultiMessages,
						dataSource:itemsCatMessages
					}
				}}
				childDrawer={{
					open:_openFormMulti,
					onClose:closeFormMulti,
					cancel:closeFormMulti,
					save:saveMultiRelMsjAbr,
					title:_isMultiEdit === true ? 'Editar mensaje':'Nuevo mensaje',
					isEdit:_isMultiEdit,
					message:_msjMulti,
					messageHandler:messageMultiHandler,
					startDate:_startDateMulti,
					endDate:_endDateMulti,
					rangeDays:rangeDaysMulti,
					block:_blockMulti,
					toggleBlock:toggleBlockMuli
				}}
			/>
			</Spin>
		</div>
	);
}

export default React.memo(RelBranchMessage)
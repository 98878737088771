import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { CSVLink, CSVDownload } from 'react-csv';
import { Card, Select, Spin, Table, notification, Space, Badge, Input, Button  } from 'antd';
import { DownloadOutlined, BarsOutlined, QuestionCircleOutlined, SearchOutlined  } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ButtonUI from '../../@ui/Button';
import Highlighter from 'react-highlight-words';
import NotAllow from '../../@ui/NotAllow';

function NoAcumulated(){
	const [ _itemsReport, set_itemsReport ] = useState([])

	const [ rights, setRights] = useState([])
	// VALORES PARA LOS FILTROS  
	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [ _filterCorp, set_filterCorp ] = useState([]);
	const [ _filterClient, set_filterClient ] = useState([]);
	const [ _filterCountry, set_filterCountry ] = useState([]);

	const [ _filteredData, set_filteredData ] = useState([]);

	const [loading, setLoading] = useState(false);

	useEffect(()=>{ getNoAcumulated();
		let stringUserRights = localStorage.getItem('userRights')
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights); },[])

	function convertToDate(date){return dayjs(date).format('DD/MM/YYYY');};

	function getNoAcumulated(){
		let token = localStorage.getItem('x-access-token');

		setLoading(true);
		fetch(urlAPI.url + `reportSinAcumulados/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut === undefined) {
					if (json.aut === false) logout();
					if (json.status) {
						set_itemsReport(json.data[0]);
						let filteredData = JSON.parse(JSON.stringify(json.data[0]));
						const corpFilter = filteredData.map((element)=>element.codeClient)
						const clientFilter = filteredData.map((element)=>element.description)
						const countryFilter = filteredData.map((element)=>element.country)
						filteredData.map((element)=>{
							element.dateCapture=convertToDate(element.dateCapture);
							element.startDate=convertToDate(element.startDate);
							element.endDate=convertToDate(element.endDate);
						})
						
						set_filteredData(filteredData);
						const cleanCorp = corpFilter.filter((x, i) => corpFilter.indexOf(x) === i);
						const cleanClient = clientFilter.filter((x, i) => clientFilter.indexOf(x) === i);
						const cleanCountry = countryFilter.filter((x, i) => countryFilter.indexOf(x) === i);
						set_filterCorp(cleanCorp.map((val) => {return {label:val, value:val}}))
						set_filterClient(cleanClient.map((val) => {return {label:val, value:val}}))
						set_filterCountry(cleanCountry.map((val) => {return {label:val, value:val}}))
					} 
				} else {
					logout();
				}
				setLoading(false);
			});

	}
    
	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

    //Filtrar
	  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
      if(selectedKeys === '' ){clearFilters && handleReset(clearFilters);}
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
	  };

	  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText(''); 
	  };
  
    const getColumnCountryProps = (dataIndex) => ({
		  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			  <Select
          style={{ marginBottom: 8, display: 'block', }}
          options={([{label:'Todos', value:''}]).concat(_filterCountry)}
          onChange={(e) => setSelectedKeys(e ? [e] : []) }
        />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  icon={<SearchOutlined />}
				  size="small"
				  style={{
					width: 90,
				  }}
				  className="bg-[#1677ff]"
				>
				  Buscar
				</Button>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered) => (
        filtered ?
        (<Badge className="animate-pulse" dot>
          <SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />
        </Badge>):(<SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />)
		  ),
		  onFilter: (value, record) =>{
			  if(record[dataIndex]) {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }else{
          return ''.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
		  onFilterDropdownOpenChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current?.select(), 100);
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{
				  backgroundColor: '#ffc069',
				  padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			  />
			) : (
			  text
			),
	});

    const getColumnCorpProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		  <div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Select
		style={{ marginBottom: 8, display: 'block', width: 200 }}
		options={([{label:'Todos', value:''}]).concat(_filterCorp)}
		onChange={(e) => setSelectedKeys(e ? [e] : []) }
		showSearch={true}
	  />
			<Space>
			  <Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				  width: 90,
				}}
				className="bg-[#1677ff]"
			  >
				Buscar
			  </Button>
			</Space>
		  </div>
		),
		filterIcon: (filtered) => (
	  filtered ?
	  (<Badge className="animate-pulse" dot>
		<SearchOutlined
		  className='w-[20px] h-[20px] text-[18px]'
		  style={{
		  color: filtered ? '#1890ff' : undefined,
		  }}
		/>
	  </Badge>):(<SearchOutlined
		  className='w-[20px] h-[20px] text-[18px]'
		  style={{
		  color: filtered ? '#1890ff' : undefined,
		  }}
		/>)
		),
		onFilter: (value, record) =>{
			if(record[dataIndex]) {
		return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
	  }else{
		return ''.toString().toLowerCase().includes(value.toLowerCase())
	  }
	},
		onFilterDropdownOpenChange: (visible) => {
		  if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		  }
		},
		render: (text) =>
		  searchedColumn === dataIndex ? (
			<Highlighter
			  highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			  }}
			  searchWords={[searchText]}
			  autoEscape
			  textToHighlight={text ? text.toString() : ''}
			/>
		  ) : (
			text
		  ),
  	});

	const getColumnClientProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Select
		style={{ marginBottom: 8, display: 'block', width: 300}}
		options={([{label:'Todos', value:''}]).concat(_filterClient)}
		onChange={(e) => setSelectedKeys(e ? [e] : []) }
		showSearch={true}
	/>
			<Space>
			<Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
			>
				Buscar
			</Button>
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
	filtered ?
	(<Badge className="animate-pulse" dot>
		<SearchOutlined
		className='w-[20px] h-[20px] text-[18px]'
		style={{
		color: filtered ? '#1890ff' : undefined,
		}}
		/>
	</Badge>):(<SearchOutlined
		className='w-[20px] h-[20px] text-[18px]'
		style={{
		color: filtered ? '#1890ff' : undefined,
		}}
		/>)
		),
		onFilter: (value, record) =>{
			if(record[dataIndex]) {
		return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
	}else{
		return ''.toString().toLowerCase().includes(value.toLowerCase())
	}
	},
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});
		
	const headerCSV = [
		{ label: 'Clv Corporativo', key: 'idCompany' },
		{ label: 'Corporativo', key: 'codeClient' },
		{ label: 'Clv Cliente', key: 'idBranch' },
		{ label: 'Cliente', key: 'description' },
		{ label: 'País', key: 'country' },
		{ label: 'Fecha de captura', key: 'dateCapture' },
		{ label: 'Inicio Contrato', key: 'startDate' },
		{ label: 'Fin Contrato', key: 'endDate' },
	]

	const columnas = [
		{
			title: 'Corporativo',
			dataIndex: 'codeClient',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			onFilter:(x,y,z)=>{},
			...getColumnCorpProps('codeClient'),
		},
		{
			title: 'Cliente',
			dataIndex: 'description',
			onFilter:(x,y,z)=>{},
			...getColumnClientProps('description'),
		},
		{
			title: 'País',
			dataIndex: 'country',
			onFilter:(x,y,z)=>{},
			...getColumnCountryProps('country'),
		},
		{
			title: 'Fecha de captura',
			dataIndex: 'dateCapture',
			align: 'right',
			width: 150,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
		{
			title: 'Inicio Contrato',
			dataIndex: 'startDate',
			align: 'right',
			width: 150,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
		{
			title: 'Fin Contrato',
			dataIndex: 'endDate',
			align: 'right',
			width: 150,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		}
	];

	let findUserRights = (findRight) => {		
		let userRights = rights;

		for (let i in userRights) {			
			let x = userRights[i].idOperation;

			if(x === findRight){					
				return true
			}
		}
	}

	return (
		<div>
			<Spin spinning={loading} size='large'>
			<Card>
			{findUserRights(68) ? 
				<div className='flex flex-col gap-4'>					
					<Table 
						columns={columnas}
						dataSource={_itemsReport}
						className="headerTable w-[1300px] branchNotiReport flex"
						size="small"
						rowKey={(r,i) => 'noAcum_'+i}
						onRow={(record,i)=>{
							return{
								onClick:(event) => {
									$('.branchNotiReport tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
									$(`[data-row-key="noAcum_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
								}
							}
						}}	
					/>
					<CSVLink 
						data={_filteredData} 
						headers={headerCSV} 
						className='flex w-fit'
						filename={"Sin Acumulados.csv"}
					>
						<ButtonUI 
							text='Exportar'
							icon={<DownloadOutlined className='text-xl'/>}
							className='text-white bg-[#1890ff] flex justify-center items-center'
						/>
					</CSVLink>
				</div> 
			: <NotAllow />}
			</Card>
			</Spin>
		</div>
	);
}
export default React.memo(NoAcumulated)
import React, { useEffect, useState } from 'react';
import './RolesAdmin.css';
import '../MainStyle.css';
import urlAPI from '../urlAPI/urlAPI.json';
import { Input, notification, Tabs,  Tooltip, Popconfirm, Card, Switch, Radio, Spin, Tag, Select } from "antd";
import { DeleteOutlined, EditOutlined, IdcardOutlined, PoweroffOutlined } from '@ant-design/icons';
import $ from 'jquery'
import { userService } from '../services/services';
import Table from '../../@ui/Table';
import Button from '../../@ui/Button';
import Checkbox from '../../@ui/Checkbox/Checkbox';
import { useNavigate } from 'react-router-dom';
import NotAllow from '../../@ui/NotAllow';

function RolesAdmin() {
	const [itemsRole, setItemsRole] = useState([]);
	// Operaciones del usuario que existen en la base de datos actualmente
	const [roleOperation, setRoleOperation] = useState([]);
	const [checkedRadio, setCheckedRadio] = useState(true);
	const [specialPerm, setSpecialPerm] = useState(2);

	// Identificador del Rol para poder actualizar
	const [_idRole, set_idRole] = useState('');
	const [_roleName, set_roleName] = useState('');
	const [_roleNameAux, set_roleNameAux] = useState('');
	const [_active, set_active] = useState(false);

	// Variable para desabilitar o no los controles check etc
	const [ctrlsDisabled, setCtrlsDisabled] = useState(true);

	// VARIABLES PARA MOSTRAR MENSAJES
	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');
	const [rights, setRights] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [deactivate, setDeactivate] = useState(false);
	const [_typeRole, set_typeRole] = useState ('');
	const [_userLogType, set_userLogType] = useState('0');

	// permisos
	const [_perm1, set_perm1] = useState(false);		const [_perm30, set_perm30] = useState(false);
	const [_perm2, set_perm2] = useState(false);		const [_perm31, set_perm31] = useState(false);
	const [_perm3, set_perm3] = useState(false);		const [_perm32, set_perm32] = useState(false);
	const [_perm4, set_perm4] = useState(false);		const [_perm33, set_perm33] = useState(false);
	const [_perm5, set_perm5] = useState(false);		const [_perm34, set_perm34] = useState(false);
	const [_perm6, set_perm6] = useState(false);		const [_perm35, set_perm35] = useState(false);
	const [_perm7, set_perm7] = useState(false);		const [_perm36, set_perm36] = useState(false);
	const [_perm8, set_perm8] = useState(false);		const [_perm37, set_perm37] = useState(false);
	const [_perm9, set_perm9] = useState(false);		const [_perm38, set_perm38] = useState(false);
	const [_perm10, set_perm10] = useState(false);		const [_perm39, set_perm39] = useState(false);
	const [_perm11, set_perm11] = useState(false);		const [_perm40, set_perm40] = useState(false);
	const [_perm12, set_perm12] = useState(false);		const [_perm41, set_perm41] = useState(false);
	const [_perm13, set_perm13] = useState(false);		const [_perm42, set_perm42] = useState(false);
	const [_perm14, set_perm14] = useState(false);		const [_perm43, set_perm43] = useState(false);
	const [_perm15, set_perm15] = useState(false);		const [_perm44, set_perm44] = useState(false);
	const [_perm16, set_perm16] = useState(false);		const [_perm45, set_perm45] = useState(false);
	const [_perm17, set_perm17] = useState(false);		const [_perm46, set_perm46] = useState(false);
	const [_perm18, set_perm18] = useState(false);		const [_perm47, set_perm47] = useState(false);
	const [_perm19, set_perm19] = useState(false);		const [_perm48, set_perm48] = useState(false);
	const [_perm20, set_perm20] = useState(false);		const [_perm49, set_perm49] = useState(false);
	const [_perm21, set_perm21] = useState(false);		const [_perm50, set_perm50] = useState(false);
	const [_perm22, set_perm22] = useState(false);		const [_perm51, set_perm51] = useState(false);
	const [_perm23, set_perm23] = useState(false);		const [_perm52, set_perm52] = useState(false);
	const [_perm24, set_perm24] = useState(false);		const [_perm53, set_perm53] = useState(false);
	const [_perm25, set_perm25] = useState(false);		const [_perm54, set_perm54] = useState(false);
	const [_perm26, set_perm26] = useState(false);		const [_perm55, set_perm55] = useState(false);
	const [_perm27, set_perm27] = useState(false);		const [_perm56, set_perm56] = useState(false);
	const [_perm28, set_perm28] = useState(false);		const [_perm57, set_perm57] = useState(false);
	const [_perm29, set_perm29] = useState(false);		const [_perm58, set_perm58] = useState(false);
	const [_perm59, set_perm59] = useState(false);		const [_perm60, set_perm60] = useState(false);
	const [_perm61, set_perm61] = useState(false);		const [_perm62, set_perm62] = useState(false);
	const [_perm63, set_perm63] = useState(false);		const [_perm64, set_perm64] = useState(false);
	const [_perm65, set_perm65] = useState(false);		const [_perm66, set_perm66] = useState(false);
	const [_perm67, set_perm67] = useState(false);		const [_perm68, set_perm68] = useState(false);
	const [_perm69, set_perm69] = useState(false);		const [_perm70, set_perm70] = useState(false);
	const [_perm71, set_perm71] = useState(false);		const [_perm72, set_perm72] = useState(false);
	const [_perm73, set_perm73] = useState(false);		const [loading, setLoading] = useState(false);		


	//PERMISOS para actualizar
	//Arreglo de permisos
	let allPermitions = [
		{ id: 1, value: 1, operationName: '(Modulos) Acceso a "Módulos"', isChecked: false },
		{ id: 2, value: 2, operationName: '(Modulos) Lectura de las computadoras asignadas a las sucursales', isChecked: false,	},
		{ id: 3, value: 3, operationName: '(Modulos) Asignar computadoras a las sucursales', isChecked: false, },
		{ id: 4, value: 4, operationName: '(Modulos) Editar de las computadoras asignadas a las sucursales', isChecked: false, },
		{ id: 5, value: 5, operationName: '(Modulos) Desligar computadoras de las sucursales', isChecked: false, },
		{ id: 6, value: 6, operationName: '(Modulos) Lectura de las mensajes asignados a las sucursales', isChecked: false, },
		{ id: 7, value: 7, operationName: '(Modulos) Asignar mensajes a las sucursales', isChecked: false, },
		{ id: 8, value: 8, operationName: '(Modulos) Editar de los mensajes asignados a las sucursales', isChecked: false, },
		{ id: 9, value: 9, operationName: '(Modulos) Desligar mensajes de las sucursales', isChecked: false, },
		{ id: 10, value: 10, operationName: '(Catalogos) Acceso a "Catalogos"', isChecked: false },
		{ id: 11, value: 11, operationName: '(Catalogos) Acceso a "Estructura"', isChecked: false },
		{ id: 12, value: 12, operationName: '(Catalogos) Lectura de la esctructura', isChecked: false, },
		{ id: 13, value: 13, operationName: '(Catalogos) Asignar sucursales a las empresas', isChecked: false, },
		{ id: 14, value: 14, operationName: '(Catalogos) Desligar sucursales de las empresas', isChecked: false, },
		{ id: 15, value: 15, operationName: '(Catalogos) Editar el usuario empresarial', isChecked: false, },
		{ id: 16, value: 16, operationName: '(Catalogos) Acceso a "Empresa"', isChecked: false },
		{ id: 17, value: 17, operationName: '(Catalogos) Lectura de las "Empresa"', isChecked: false, },
		{ id: 18, value: 18, operationName: '(Catalogos) Agregar empresas', isChecked: false },
		{ id: 19, value: 19, operationName: '(Catalogos) Editar empresas', isChecked: false },
		{ id: 20, value: 20, operationName: '(Catalogos) Eliminar empresas', isChecked: false },
		{ id: 21, value: 21, operationName: '(Catalogos) Acceso a "Sucursales"', isChecked: false },
		{ id: 22, value: 22, operationName: '(Catalogos) Lectura de las "Sucursales"', isChecked: false, },
		{ id: 23, value: 23, operationName: '(Catalogos) Agregar sucursales', isChecked: false },
		{ id: 24, value: 24, operationName: '(Catalogos) Editar sucursales', isChecked: false },
		{ id: 25, value: 25, operationName: '(Catalogos) Eliminar sucursales', isChecked: false },
		{ id: 26, value: 26, operationName: '(Catalogos) Ligar conceptos', isChecked: false },
		{ id: 27, value: 27, operationName: '(Catalogos) Editar conceptos', isChecked: false },
		{ id: 28, value: 28, operationName: '(Catalogos) Desligar conceptos', isChecked: false },
		{ id: 29, value: 29, operationName: '(Catalogos) Acceso a "Computadoras"', isChecked: false, },
		{ id: 30, value: 30, operationName: '(Catalogos) Lectura de las computadoras', isChecked: false, },
		{ id: 31, value: 31, operationName: '(Catalogos) Agregar computadoras', isChecked: false },
		{ id: 32, value: 32, operationName: '(Catalogos) Editar computadoras', isChecked: false },
		{ id: 33, value: 33, operationName: '(Catalogos) eliminar computadoras', isChecked: false },
		{ id: 34, value: 34, operationName: '(Catalogos) Acceso a "Conceptos"', isChecked: false },
		{ id: 35, value: 35, operationName: '(Catalogos) Lectura de las conceptos', isChecked: false, },
		{ id: 36, value: 36, operationName: '(Catalogos) Agregar conceptos', isChecked: false },
		{ id: 37, value: 37, operationName: '(Catalogos) Editar conceptos', isChecked: false },
		{ id: 38, value: 38, operationName: '(Catalogos) eliminar conceptos', isChecked: false },
		{ id: 39, value: 39, operationName: '(Catalogos) Acceso a "Mensajes"', isChecked: false },
		{ id: 40, value: 40, operationName: '(Catalogos) Lectura de los mensajes', isChecked: false, },
		{ id: 41, value: 41, operationName: '(Catalogos) Agregar mensajes', isChecked: false },
		{ id: 42, value: 42, operationName: '(Catalogos) Editar mensajes', isChecked: false },
		{ id: 43, value: 43, operationName: '(Catalogos) eliminar mensajes', isChecked: false },
		{ id: 44, value: 44, operationName: '(Administración) Acceso a "Administración"', isChecked: false, },
		{ id: 45, value: 45, operationName: '(Administración) Lectura de usuarios', isChecked: false, },
		{ id: 46, value: 46, operationName: '(Administración) Agregar usuarios', isChecked: false },
		{ id: 47, value: 47, operationName: '(Administración) Editar usuarios', isChecked: false },
		{ id: 48, value: 48, operationName: '(Administración) Desactivar usuarios', isChecked: false, },
		{ id: 49, value: 49, operationName: '(Reportes) Acceso a "Reportes"', isChecked: false },
		{ id: 50, value: 50, operationName: '(Roles) Acceso a "Admin roles"', isChecked: false },
		{ id: 51, value: 51, operationName: '(Roles) Lectura de roles', isChecked: false },
		{ id: 52, value: 52, operationName: '(Roles) Agregar roles', isChecked: false },
		{ id: 53, value: 53, operationName: '(Roles) Editar permisos del rol', isChecked: false },
		{ id: 54, value: 54, operationName: '(Roles) Editar nombre del rol', isChecked: false },
		{ id: 55, value: 55, operationName: '(Roles) Desactivar rol', isChecked: false },
		{ id: 56, value: 56, operationName: '(Catalogo) Ver historial', isChecked: false },
		{ id: 57, value: 57, operationName: '(Catalogo) Ver permisos de confronta', isChecked: false },
		{ id: 58, value: 58, operationName: '(Catalogo) Editar permisos de confronta', isChecked: false },
		{ id: 59, value: 59, operationName: '(Catalogos) Acceso a "Sucursales Abrhil"', isChecked: false },
		{ id: 60, value: 60, operationName: '(Catalogos) Editar sucursales Abrhil', isChecked: false },
		{ id: 61, value: 61, operationName: '(Modulos) Lista Especial', isChecked: false },
		{ id: 62, value: 62, operationName: '(Administración) Agregar usuario lista especial', isChecked: false },
		{ id: 63, value: 63, operationName: '(Catalogos) Acceso a RFC', isChecked: false },
		{ id: 64, value: 64, operationName: '(Catalogos) Agregar RFC', isChecked: false },
		{ id: 65, value: 65, operationName: '(Catalogos) Editar RFC', isChecked: false },
		{ id: 66, value: 66, operationName: '(Catalogos) Eliminar RFC', isChecked: false },
		{ id: 67, value: 67, operationName: '(Reportes) Acceso a Acumulados', isChecked: false },
		{ id: 68, value: 68, operationName: '(Reportes) Acceso a Sin acumulados', isChecked: false },
		{ id: 69, value: 69, operationName: '(Reportes) Acceso a Acumulados por mes', isChecked: false },
		{ id: 70, value: 70, operationName: '(Reportes) Acceso a Sucursales', isChecked: false },
		{ id: 71, value: 71, operationName: '(Reportes) Acceso a Descargar Raportes', isChecked: false },
		{ id: 72, value: 72, operationName: '(Administración) Acceso a Configuraciones', isChecked: false },
		{ id: 73, value: 73, operationName: '(Administración) Editar Configuraciones', isChecked: false },
	]

	const [itemsOperations, setItemsOperations] = useState (allPermitions);
	
	useEffect(()=>{
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights); 
		let stringType = localStorage.getItem('type');
		let userType = JSON.parse(stringType);
		set_userLogType(userType);
		setRights(userRights);
		getRolesAdmin();
	},[])

	function getRolesAdmin() {

		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + 'rolesAdmin/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					setItemsRole(json.data[0])
				}
			} else {
				logout();
			}
			setLoading(false)
		});
	}

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	useEffect(()=>{},[roleOperation])
	const sets = [
		'',set_perm1,set_perm2,set_perm3,set_perm4,set_perm5,set_perm6,set_perm7,set_perm8,set_perm9,set_perm10,set_perm11,set_perm12,set_perm13,
		set_perm14,set_perm15,set_perm16,set_perm17,set_perm18,set_perm19,set_perm20,set_perm21,set_perm22,set_perm23,set_perm24,set_perm25,set_perm26,
		set_perm27,set_perm28,set_perm29,set_perm30,set_perm31,set_perm32,set_perm33,set_perm34,set_perm35,set_perm36,set_perm37,set_perm38,set_perm39,
		set_perm40,set_perm41,set_perm42,set_perm43,set_perm44,set_perm45,set_perm46,set_perm47,set_perm48,set_perm49,set_perm50,set_perm51,set_perm52,
		set_perm53,set_perm54,set_perm55,set_perm56,set_perm57,set_perm58, set_perm59, set_perm60, set_perm61, set_perm62, set_perm63, set_perm64, 
		set_perm65, set_perm66, set_perm67, set_perm68, set_perm69, set_perm70, set_perm71, set_perm72, set_perm73
	]

	function loadPermissions(idRole) {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `roleOperations/${idRole}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRoleOperation(json.data[0])
				
				// Traemos los arreglos de las operaciones
				let operationsBase = json.data[0];
				
				let operationsRender = itemsOperations.map((operRender, index) => {
					operRender.isChecked = false;
					sets[operRender.value](false);
					return operRender
				});
				
				//Recorremos el arreglo de las operaciones local
				operationsRender.map((operRender) => {
					// Recorremos el arreglo que traemos de la base de datos
					operationsBase.map((operBase) => {
						// Comparamos los arreglos
						if (operRender.value === operBase.idOperation) {
							operRender.isChecked = true;
							sets[operRender.value](true);
							return operRender;
						}
					});
				});

				setItemsOperations(operationsRender);
				
			} 
			setLoading(false)
		});
	}

	function findPermChecked(idOper) {
		let operations = roleOperation;
		
		for (let i in operations) {
			let x = operations[i].idOperation;

			if (x === idOper) {
				return true;
			}
		}
		return false;
	}

	function saveRolOperations() {
		
		let token = localStorage.getItem('x-access-token');
		let idRole = isEdit ? _idRole : (itemsRole[(itemsRole.length - 1)]).idRole;
		let permissions = itemsOperations;
		
		setLoading(true)
		fetch(urlAPI.url + `roleOperations/${idRole}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(permissions),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
				setCtrlsDisabled(true)
				setIsEdit(false)
			} else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
					setCtrlsDisabled(true)
			}
			setLoading(false)
		});
		transitionMsn();
		limpiarMensaje();
	}

	function deleteRole(idRole){
		
		let token = localStorage.getItem("x-access-token");
		setLoading(true)
		fetch(urlAPI.url + `roleAdmin/${idRole}`, {
		  method: "DELETE",
		  headers: { "x-access-token": token, },
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
		if (json.status) {
			setRespMessage(json.data[0].mensaje)
			setColorMessage(tipoMensaje(json.data[0].numMensaje));
			getRolesAdmin();
		} else {
			setRespMessage(json.mensaje)
			setColorMessage('error')
		}
		setLoading(false)
		});
	}

	function cancelSaveRolOper() {
		set_idRole('');
		set_roleName('');
		set_active(true)
		setDeactivate(false);
		setIsEdit(false);
		setCtrlsDisabled(true)
		itemsOperations.map((operRender) => {
			operRender.isChecked = false;
			sets[operRender.value](false);
			return operRender
		});
	}

	function addRole() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevoRol = {
			idRole: '',
			roleName: _roleName,
			active: 1,
			userCap: userLogged,
		};

		setLoading(true)
		fetch(urlAPI.url + `roleAdmin/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevoRol),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				getRolesAdmin();
				this.setState({
					existMessage: true,
					respMessage: json.data[0].mensaje,
					colorMessage: tipoMensaje(json.data[0].numMensaje),
					ctrlsDisabled: true,
				});
			} else {
				this.setState({
					existMessage: true,
					respMessage: json.mensaje,
					colorMessage: 'red',
					ctrlsDisabled: true,
				});
			}
			setLoading(false)
		});
		limpiarMensaje();
		limpiaCampos();
		transitionMsn();
		closeNewRole();
	}

	function editRoleName() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');
		
		let newRoleName = {
			idRole: isEdit ? _idRole: '',
			roleName: _roleName,
			active: deactivate ? 0 : activoToInt(_active),
			userCap: userLogged,
			roleType:_typeRole,
		};
		
		setLoading(true)
		fetch(urlAPI.url + `roleAdmin/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(newRoleName),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				getRolesAdmin();
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
				if(!deactivate) saveRolOperations();
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
				cancelEditRoleName();
			}
			setLoading(false)
		});
		transitionMsn();
		limpiarMensaje();
		limpiaCampos();
	}

	let activoToInt = (campo) => {
		switch (campo) {
			case false: {
				return 0;
			}
			case true: {
				return 1;
			}
			default: {
				return 2;
			}
		}
	};

	function desactivateRole() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let roleDesact = {
			idRole: _idRole,
			roleName: _roleName,
			active: 0,
			userCap: userLogged,
		};

		setLoading(true)
		fetch(urlAPI.url + `roleAdmin/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(roleDesact),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				getRolesAdmin();
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			limpiaCampos();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	let tipoMensaje = (numMensaje) => {
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	let transitionMsn = () => {
		setTimeout(() => {
		}, 4000);
	};

	let limpiarMensaje = () => {
		setTimeout(() => {
			setRespMessage('');
			setColorMessage('');
		}, 6000);
	};

	let handleAllChecked = (trueOrFalse) => {
		let operations = itemsOperations;
		operations.forEach((operation) => (operation.isChecked = trueOrFalse));
			setItemsOperations(operations);
			set_perm1(trueOrFalse);			set_perm30(trueOrFalse);
			set_perm2(trueOrFalse);			set_perm31(trueOrFalse);		
			set_perm3(trueOrFalse);			set_perm32(trueOrFalse);
			set_perm4(trueOrFalse);			set_perm33(trueOrFalse);		
			set_perm5(trueOrFalse);			set_perm34(trueOrFalse);
			set_perm6(trueOrFalse);			set_perm35(trueOrFalse);		
			set_perm7(trueOrFalse);			set_perm36(trueOrFalse);
			set_perm8(trueOrFalse);			set_perm37(trueOrFalse);		
			set_perm9(trueOrFalse);			set_perm38(trueOrFalse);
			set_perm10(trueOrFalse);		set_perm39(trueOrFalse);		
			set_perm11(trueOrFalse);		set_perm40(trueOrFalse);
			set_perm12(trueOrFalse);		set_perm41(trueOrFalse);		
			set_perm13(trueOrFalse);		set_perm42(trueOrFalse);
			set_perm14(trueOrFalse);		set_perm43(trueOrFalse);		
			set_perm15(trueOrFalse);		set_perm44(trueOrFalse);
			set_perm16(trueOrFalse);		set_perm45(trueOrFalse);		
			set_perm17(trueOrFalse);		set_perm46(trueOrFalse);
			set_perm18(trueOrFalse);		set_perm47(trueOrFalse);		
			set_perm19(trueOrFalse);		set_perm48(trueOrFalse);
			set_perm20(trueOrFalse);		set_perm49(trueOrFalse);		
			set_perm21(trueOrFalse);		set_perm50(trueOrFalse);
			set_perm22(trueOrFalse);		set_perm51(trueOrFalse);		
			set_perm23(trueOrFalse);		set_perm52(trueOrFalse);
			set_perm24(trueOrFalse);		set_perm53(trueOrFalse);		
			set_perm25(trueOrFalse);		set_perm54(trueOrFalse);
			set_perm26(trueOrFalse);		set_perm55(trueOrFalse);		
			set_perm27(trueOrFalse);		set_perm56(trueOrFalse);
			set_perm28(trueOrFalse);		set_perm57(trueOrFalse);		
			set_perm29(trueOrFalse);		set_perm58(trueOrFalse);			
			set_perm59(trueOrFalse);		set_perm60(trueOrFalse);	
			set_perm61(trueOrFalse);		set_perm62(trueOrFalse);
			set_perm63(trueOrFalse);		set_perm64(trueOrFalse);
			set_perm65(trueOrFalse);		set_perm66(trueOrFalse);
			set_perm67(trueOrFalse);		set_perm68(trueOrFalse);
			set_perm69(trueOrFalse);		set_perm70(trueOrFalse);
			set_perm71(trueOrFalse);		set_perm72(trueOrFalse);
			set_perm73(trueOrFalse);		
	};

	let handleCheckPerm = (event) => {
		let operations = itemsOperations;
		operations.forEach((operation) => {
			if (operation.value === event.target.value) {
				operation.isChecked = event.target.checked;
			}
		});

		setItemsOperations(operations);

		if (event.target.value === 1) { set_perm1(event.target.checked); }
		if (event.target.value === 2) { set_perm2(event.target.checked); }
		if (event.target.value === 3) { set_perm3(event.target.checked); }
		if (event.target.value === 4) { set_perm4(event.target.checked); }
		if (event.target.value === 5) { set_perm5(event.target.checked); }
		if (event.target.value === 6) { set_perm6(event.target.checked); }
		if (event.target.value === 7) { set_perm7(event.target.checked); }
		if (event.target.value === 8) { set_perm8(event.target.checked); }
		if (event.target.value === 9) { set_perm9(event.target.checked); }
		if (event.target.value === 10) { set_perm10(event.target.checked); }
		if (event.target.value === 11) { set_perm11(event.target.checked); }
		if (event.target.value === 12) { set_perm12(event.target.checked); }
		if (event.target.value === 13) { set_perm13(event.target.checked); }
		if (event.target.value === 14) { set_perm14(event.target.checked); }
		if (event.target.value === 15) { set_perm15(event.target.checked); }
		if (event.target.value === 16) { set_perm16(event.target.checked); }
		if (event.target.value === 17) { set_perm17(event.target.checked); }
		if (event.target.value === 18) { set_perm18(event.target.checked); }
		if (event.target.value === 19) { set_perm19(event.target.checked); }
		if (event.target.value === 20) { set_perm20(event.target.checked); }
		if (event.target.value === 21) { set_perm21(event.target.checked); }
		if (event.target.value === 22) { set_perm22(event.target.checked); }
		if (event.target.value === 23) { set_perm23(event.target.checked); }
		if (event.target.value === 24) { set_perm24(event.target.checked); }
		if (event.target.value === 25) { set_perm25(event.target.checked); }
		if (event.target.value === 26) { set_perm26(event.target.checked); }
		if (event.target.value === 27) { set_perm27(event.target.checked); }
		if (event.target.value === 28) { set_perm28(event.target.checked); }
		if (event.target.value === 29) { set_perm29(event.target.checked); }
		if (event.target.value === 30) { set_perm30(event.target.checked); }
		if (event.target.value === 31) { set_perm31(event.target.checked); }
		if (event.target.value === 32) { set_perm32(event.target.checked); }
		if (event.target.value === 33) { set_perm33(event.target.checked); }
		if (event.target.value === 34) { set_perm34(event.target.checked); }
		if (event.target.value === 35) { set_perm35(event.target.checked); }
		if (event.target.value === 36) { set_perm36(event.target.checked); }
		if (event.target.value === 37) { set_perm37(event.target.checked); }
		if (event.target.value === 38) { set_perm38(event.target.checked); }
		if (event.target.value === 39) { set_perm39(event.target.checked); }
		if (event.target.value === 40) { set_perm40(event.target.checked); }
		if (event.target.value === 41) { set_perm41(event.target.checked); }
		if (event.target.value === 42) { set_perm42(event.target.checked); }
		if (event.target.value === 43) { set_perm43(event.target.checked); }
		if (event.target.value === 44) { set_perm44(event.target.checked); }
		if (event.target.value === 45) { set_perm45(event.target.checked); }
		if (event.target.value === 46) { set_perm46(event.target.checked); }
		if (event.target.value === 47) { set_perm47(event.target.checked); }
		if (event.target.value === 48) { set_perm48(event.target.checked); }
		if (event.target.value === 49) { set_perm49(event.target.checked); }
		if (event.target.value === 50) { set_perm50(event.target.checked); }
		if (event.target.value === 51) { set_perm51(event.target.checked); }
		if (event.target.value === 52) { set_perm52(event.target.checked); }
		if (event.target.value === 53) { set_perm53(event.target.checked); }
		if (event.target.value === 54) { set_perm54(event.target.checked); }
		if (event.target.value === 55) { set_perm55(event.target.checked); }
		if (event.target.value === 56) { set_perm56(event.target.checked); }
		if (event.target.value === 57) { set_perm57(event.target.checked); }
		if (event.target.value === 58) { set_perm58(event.target.checked); }
		if (event.target.value === 59) { set_perm59(event.target.checked); }
		if (event.target.value === 60) { set_perm60(event.target.checked); }
		if (event.target.value === 61) { set_perm61(event.target.checked); }
		if (event.target.value === 62) { set_perm62(event.target.checked); }
		if (event.target.value === 63) { set_perm63(event.target.checked); }
		if (event.target.value === 64) { set_perm64(event.target.checked); }
		if (event.target.value === 65) { set_perm65(event.target.checked); }
		if (event.target.value === 66) { set_perm66(event.target.checked); }
		if (event.target.value === 67) { set_perm67(event.target.checked); }
		if (event.target.value === 68) { set_perm68(event.target.checked); }
		if (event.target.value === 69) { set_perm69(event.target.checked); }
		if (event.target.value === 70) { set_perm70(event.target.checked); }
		if (event.target.value === 71) { set_perm71(event.target.checked); }
		if (event.target.value === 72) { set_perm72(event.target.checked); }
		if (event.target.value === 73) { set_perm73(event.target.checked); }
	};

	let handleChangeAll = (e) => {
		handlePersonalPerm(e.target.value);
	}

	function handlePersonalPerm(value){
		setSpecialPerm(value);
		switch(value){
			case 0: handleAllChecked(false); setCheckedRadio(true); break;
			case 1: handleAllChecked(true); setCheckedRadio(true); break;
			case 2: handleAllChecked(false); setCheckedRadio(false); break;
		} 
	}

	function roleNameHndlr(e) { set_roleName(e.target.value) }

	let toggleRoleActive = (check) => { set_active(check);}

	/**
	 * Editar 
	 */
	useEffect(()=>{},[_idRole])

	function editRole(rowInfo) {
		set_typeRole(rowInfo.typeRole);
		set_idRole(rowInfo.idRole);
		set_roleName(rowInfo.roleName);
		set_active(activoNumToBool(rowInfo.active));

		loadPermissions(rowInfo.idRole);
		
		setCtrlsDisabled(false);
		setCheckedRadio(false);
		setIsEdit(true);
	}

	useEffect(()=>{ if(deactivate)editRoleName(); },[deactivate])
	function desactiveRoleModal(props) {
		set_idRole(props.idRole);
		set_roleName(props.roleName);
		setIsEdit(true);
		setDeactivate(true);
	}

	function showNewRole(){
		set_idRole('');
		set_roleName('');
		set_active(true);
		setDeactivate(false);
		set_typeRole('')
		setCtrlsDisabled(false);
		setCheckedRadio(false);
		setIsEdit(false);
	}

	let closeNewRole = () => {
		let operationsRender = itemsOperations;

		operationsRender.forEach((operRender) => {
			operRender.isChecked = false;
		});
		
		setItemsOperations(operationsRender);
		limpiaCampos()
	};

	let cancelEditRoleName = () => {
		let oldRoleName = _roleNameAux;
		set_roleNameAux(oldRoleName);
	};

	function limpiaCampos (){
		set_idRole('');
		set_roleName('');
		setCtrlsDisabled(true);
		setIsEdit(false);
		setDeactivate(false);
		set_typeRole('')
	};

	const typeUserHandler = (e) => set_typeRole(e);

	// Segundo Accordion
	let handlerModules = (item) => {
		if(item)return
		set_perm2(false);
		set_perm3(false);
		set_perm4(false);
		set_perm5(false);
		set_perm6(false);
		set_perm7(false);
		set_perm8(false);
		set_perm9(false);
	}
	let handlerCatalogs = (item) => {
		if(item)return
		// Estructura
		set_perm11(false);
		set_perm12(false);
		set_perm13(false);
		set_perm14(false);
		set_perm15(false);

		// Corporativos
		set_perm16(false);
		set_perm17(false);
		set_perm18(false);
		set_perm19(false);
		set_perm20(false);

		//Clientes
		set_perm21(false);
		set_perm22(false);
		set_perm23(false);
		set_perm24(false);
		set_perm25(false);
		set_perm26(false);
		set_perm27(false);
		set_perm28(false);
		set_perm56(false);
		set_perm57(false);
		set_perm58(false);
		set_perm59(false);
		set_perm60(false);

		//RFC
		set_perm63(false);
		set_perm64(false);
		set_perm65(false);
		set_perm66(false);

		// Computadoras
		set_perm29(false);
		set_perm30(false);
		set_perm31(false);
		set_perm32(false);
		set_perm33(false);

		// Conceptos
		set_perm34(false);
		set_perm35(false);
		set_perm36(false);
		set_perm37(false);
		set_perm38(false);

		// Mensajes
		set_perm39(false);
		set_perm40(false);
		set_perm41(false);
		set_perm42(false);
		set_perm43(false);
	}
	let handlerAdmininstration = (item) => {
		if(item)return
		set_perm45(false);
		set_perm46(false);
		set_perm47(false);
		set_perm48(false);
		set_perm50(false);
		set_perm51(false);
		set_perm52(false);
		set_perm53(false);
		set_perm54(false);
		set_perm55(false);
		set_perm62(false);
	}
	let CheckPerm = (item) => {
		return (
			<div className="flex gap-4">
					<Checkbox
					disabled={ctrlsDisabled || checkedRadio || item.disabled}
					value={item.value}
					id={item.id}
					checked={item.checked}
					onChange={(e) => {
						handleCheckPerm(e)
						switch(e.target.value){
							case 1: handlerModules(e.target.checked); break;
							case 10: handlerCatalogs(e.target.checked); break;
							case 44: handlerAdmininstration(e.target.checked); break;
						}
					}}
					/>
				<label className="">
					{item.label}
				</label>
			</div>
		)
	}

	const cataloguePanels = [
		{
			label: 'Estructura',
			key: 'Estructura',
			children: 
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
							<label className='w-full flex'>Estructura</label>
								<CheckPerm value={11} id='perm-11' checked={_perm11} label='Acceso a "Estructura"' disabled={!_perm10} />
								<CheckPerm value= {12} id= 'perm-12' checked= {_perm12} label= 'Ver las estructuras' disabled={!_perm10} />
						</div>
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
							<label className='w-full flex'>Sucursales</label>
								<CheckPerm value={13} id='perm-13' checked={_perm13} label='Asignar sucursales' disabled={!_perm10} />
								<CheckPerm value={14} id='perm-14' checked={_perm14} label='Desligar sucursales' disabled={!_perm10} />
								<CheckPerm value={15} id='perm-15' checked={_perm15} label='Editar usuario sucursal' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'Corporativos',
			key: 'Corporativos',
			children: 
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={16} id='perm-16' checked={_perm16} label='Acceso a "Corporativos"' disabled={!_perm10} />
								<CheckPerm value={17} id='perm-17' checked={_perm17} label='Ver los corporativos' disabled={!_perm10} />
						</div>
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={18} id='perm-18' checked={_perm18} label='Agregar corporativos' disabled={!_perm10} />
								<CheckPerm value={19} id='perm-19' checked={_perm19} label='Editar corporativos' disabled={!_perm10} />
								<CheckPerm value={20} id='perm-20' checked={_perm20} label='Eliminar corporativos' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'Clientes',
			key: 'Clientes',
			children:
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<label className='w-full flex'>Clientes</label>
								<CheckPerm value={21} id='perm-21' checked={_perm21} label='Acceso a clientes' disabled={!_perm10} />
								<CheckPerm value={22} id='perm-22' checked={_perm22} label='Ver los clientes' disabled={!_perm10} />
								<CheckPerm value={23} id='perm-23' checked={_perm23} label='Agregar clientes' disabled={!_perm10} />
								<CheckPerm value={24} id='perm-24' checked={_perm24} label='Editar clientes' disabled={!_perm10} />
								<CheckPerm value={25} id='perm-25' checked={_perm25} label='Eliminar clientes' disabled={!_perm10} />
						</div>
						{/*<div className='flex flex-col gap-2 w-1/3'>
								<label className='w-full flex'>Conceptos e historial</label>
								<CheckPerm value={26} id='perm-26' checked={_perm26} label='Ligar conceptos' disabled={!_perm10} />
								<CheckPerm value={27} id='perm-27' checked={_perm27} label='Editar conceptos' disabled={!_perm10} />
								<CheckPerm value={28} id='perm-28' checked={_perm28} label='Desligar conceptos' disabled={!_perm10} />
								
						</div>*/}
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<label className='w-full flex'>Sucursales Abrhil y confronta</label>
								<CheckPerm value={57} id='perm-57' checked={_perm57} label='Ver permiso confronta' disabled={!_perm10} />
								<CheckPerm value={58} id='perm-58' checked={_perm58} label='Editar permisos confronta' disabled={!_perm10} />
								<CheckPerm value={59} id='perm-59' checked={_perm59} label='Acceso a sucursales Abrhil' disabled={!_perm10} />
								<CheckPerm value={60} id='perm-60' checked={_perm60} label='Editar sucursales Abrhil' disabled={!_perm10} />
								<CheckPerm value={56} id='perm-56' checked={_perm56} label='Ver historial' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'RFC',
			key: 'rfc',
			children: 
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={63} id='perm-63' checked={_perm63} label='Acceso a "RFC"' disabled={!_perm10} />
								<CheckPerm value={64} id='perm-64' checked={_perm64} label='Agregar RFC' disabled={!_perm10} />
						</div>
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={65} id='perm-65' checked={_perm65} label='Editar RFC' disabled={!_perm10} />
								<CheckPerm value={66} id='perm-66' checked={_perm66} label='Eliminar RFC' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'Computadoras',
			key: 'Computadoras',
			children: 
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={29} id='perm-29' checked={_perm29} label='Acceso a "Computadoras"' disabled={!_perm10} />
								<CheckPerm value={30} id='perm-30' checked={_perm30} label='Ver las computadoras' disabled={!_perm10} />
						</div>
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={31} id='perm-31' checked={_perm31} label='Agregar computadoras' disabled={!_perm10} />
								<CheckPerm value={32} id='perm-32' checked={_perm32} label='Editar computadoras' disabled={!_perm10} />
								<CheckPerm value={33} id='perm-33' checked={_perm33} label='Eliminar computadoras' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'Conceptos',
			key: 'Conceptos',
			children:
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={34} id='perm-34' checked={_perm34} label='Acceso a "Conceptos"' disabled={!_perm10} />
								<CheckPerm value={35} id='perm-35' checked={_perm35} label='Ver los conceptos' disabled={!_perm10} />
						</div>
						<div className='flex flex-col gap-2 
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<CheckPerm value={36} id='perm-36' checked={_perm36} label='Agregar conceptos' disabled={!_perm10} />
								<CheckPerm value={37} id='perm-37' checked={_perm37} label='Editar conceptos' disabled={!_perm10} />
								<CheckPerm value={38} id='perm-38' checked={_perm38} label='Eliminar conceptos' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'Mensajes',
			key: 'Mensajes',
			children: 
				<Card title="" size='small'>
					<div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
						<div className='flex flex-col gap-2 w-1/2'>
								<CheckPerm value={39} id='perm-39' checked={_perm39} label='Acceso a "Mensajes"' disabled={!_perm10} />
								<CheckPerm value={40} id='perm-40' checked={_perm40} label='Ver los mensajes' disabled={!_perm10} />
						</div>
						<div className='flex flex-col gap-2 w-1/2'>
								<CheckPerm value={41} id='perm-41' checked={_perm41} label='Agregar mensajes' disabled={!_perm10} />
								<CheckPerm value={42} id='perm-42' checked={_perm42} label='Editar mensajes' disabled={!_perm10} />
								<CheckPerm value={43} id='perm-43' checked={_perm43} label='Eliminar mensajes' disabled={!_perm10} />
						</div>
					</div>
				</Card>
		},
	];

	const roleTableColumns = [
		{
			title: 'Rol',
			key: 'rol',
			align:'center',
			className:'select-none',
			width: 120,
			ellipsis: true,
			render: (props) => {
				let color, type;
				switch(props.typeRole){ 
					case 0: color='blue'; type='General'; break; 
					case 1: color='cyan'; type='Abrhilsoft'; break; 
					case 2: color='magenta'; type='Timbrasoft'; break; 
					case 3: color='purple'; type='CyL Consultores'; break; 
					default: color='volcano'; type='-sin tipo-'; break; 
				}
				return(
					<div className='flex flex-col gap-2 select-none'>
						<label>{props.roleName}</label>
						<Tag color={color} key={props} className='flex justify-center text-xs select-none'>
							{type.toUpperCase()}
						</Tag>
					</div>
				)},
		},
		{
			title: 'Activo',
			dataIndex: 'active',
			key: 'active',
			align:'center',
			className:'select-none',
			width: 50,
			ellipsis: true,
			responsive: ['xxl', 'xl'],
			render: (props) => {
				return validaActivo(props);
			},
		},
		{
			title: 'Acciones',
			key: 'actions',
			align:'center',
			width: 100,
			className:'select-none',
			ellipsis: true,
			render: (props) => {
				return (
					<div className='flex justify-center gap-2'>
						{findUserRights(53) ? (
						<Tooltip placement="top" title='Editar' >
							<Button
								className="flex justify-center items-center bg-blue-200 p-2 select-none"
								onClick={() => {editRole(props)}}
								text={<EditOutlined className='select-none' />}
							/>
						</Tooltip>
						) : null}

						{findUserRights(55) ? (
						<Tooltip placement="top" title='Desactivar' >
						<Popconfirm
							title="Desactivar rol"
							description={'¿Desea desactivar el rol "'+ props.roleName +'" ?'}
							onConfirm={() => {desactiveRoleModal(props);}}
							onCancel={()=>{}}
							okText="Desactivar"
							cancelText="Cancelar"
							okButtonProps={{className:'bg-[#1677ff]'}}
						>
							<Button className="flex justify-center items-center bg-slate-200 p-2 select-none" text={<PoweroffOutlined className='select-none' />} />
						</Popconfirm>
						</Tooltip>
						) : null}

						{findUserRights(55) ? (
						<Tooltip placement="top" title='Eliminar' >
						<Popconfirm
							title="Eliminar rol"
							description={'¿Desea Eliminar el rol "'+ props.roleName +'" ?'}
							onConfirm={() => {deleteRole(props.idRole);}}
							onCancel={()=>{}}
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{className:'bg-red-600'}}
						>
							<Button className="flex justify-center items-center bg-red-200 p-2 select-none" text={<DeleteOutlined className='select-none' />} />
						</Popconfirm>
						</Tooltip>
						) : null}	
					</div>
				);
			},
		},
		
	];

	let validaActivo = (campo) => {
		switch (campo) {
			case 1: {
				return 'SI';
			}
			case 0: {
				return 'NO';
			}
			default: {
				return 'N/A';
			}
		}
	};

	let activoNumToBool = (campo) => {
		switch (campo) {
			case 0: {
				return false;
			}
			case 1: {
				return true;
			}
			default: {
				return 2;
			}
		}
	};

	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x === findRight) {
				return true;
			}
		}
	};

	const rolePanels = [
		{
			label: 'Módulos',
			key: 'Módulos',
			children: 
				<Card title="" size='small'>					
					<div className='flex flex-col gap-2'>
						<CheckPerm value={1} id='perm-1' checked={_perm1} label='Acceso a "Módulos"' disabled={''} />
						<div className="flex gap-2 
						3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
							<div className='flex flex-col gap-2 w-1/2
							3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<label className='w-full flex'>Asignación de computadoras</label>
									<CheckPerm value={2} id='perm-2' checked={_perm2} label='Ver computadoras asignadas' disabled={!_perm1} />
									<CheckPerm value={3} id='perm-3' checked={_perm3} label='Asignar computadoras' disabled={!_perm1} />
									<CheckPerm value={4} id='perm-4' checked={_perm4} label='Editar computadoras asignadas' disabled={!_perm1} />
									<CheckPerm value={5} id='perm-5' checked={_perm5} label='Desligar computadoras' disabled={!_perm1} />
							</div>
							<div className='flex flex-col gap-2 w-1/2'>
								<label className='w-full flex'>Asignación de mensajes</label>
									<CheckPerm value={6} id='perm-6' checked={_perm6} label='Ver mensajes asignados' disabled={!_perm1} />
									<CheckPerm value={7} id='perm-7' checked={_perm7} label='Asignar mensajes' disabled={!_perm1} />
									<CheckPerm value={8} id='perm-8' checked={_perm8} label='Editar los mensajes asignados' disabled={!_perm1} />
									<CheckPerm value={9} id='perm-9' checked={_perm9} label='Desligar mensajes' disabled={!_perm1} />
							</div>
						</div>
						{/*<CheckPerm value={61} id='perm-61' checked={_perm61} label='Listado Especial' disabled={''} />*/}
					</div>
				</Card>
		},
		{
			label: 'Catálogos',
			key: 'Catálogos',
			children: 
				<Card title="" size='small'>
					<div>
						<CheckPerm value={10} id='perm-10' checked={_perm10} label='Acceso a "Catalogos"' disabled={''} />
						<div>
							<Tabs items={cataloguePanels} />
						</div>
					</div>
				</Card>
		},
		{
			label: 'Administración',
			key: 'Administración',
			children:
				<Card title="" size='small'>
					<div className='flex flex-col gap-2'>
						<CheckPerm value={44} id='perm-44' checked={_perm44} label='Acceso a "Administración"' disabled={''} />
						<div className="flex gap-2
						3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
							<div className='flex flex-col gap-2 
							3xl:w-1/3 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<label className='w-full flex'>Usuarios</label>
									<CheckPerm value={45} id='perm-45' checked={_perm45} label='Ver los usuarios' disabled={!_perm44} />
									<CheckPerm value={46} id='perm-46' checked={_perm46} label='Agregar usuario general' disabled={!_perm44} />
									<CheckPerm value={62} id='perm-62' checked={_perm62} label='Agregar usuario lista especial' disabled={!_perm44} />
									<CheckPerm value={47} id='perm-47' checked={_perm47} label='Editar usuarios' disabled={!_perm44} />
									<CheckPerm value={48} id='perm-48' checked={_perm48} label='Desactivar usuarios' disabled={!_perm44} />
							</div>
							<div className='flex flex-col gap-2 
							3xl:w-1/3 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<label className='w-full flex'>Roles</label>
									<CheckPerm value={51} id='perm-51' checked={_perm51} label='Ver los roles' disabled={!_perm44} />
									<CheckPerm value={52} id='perm-52' checked={_perm52} label='Agregar roles' disabled={!_perm44} />
									<CheckPerm value={54} id='perm-54' checked={_perm54} label='Editar nombre del rol' disabled={!_perm44} />
									<CheckPerm value={53} id='perm-53' checked={_perm53} label='Editar permisos del rol' disabled={!_perm44} />
									<CheckPerm value={55} id='perm-55' checked={_perm55} label='Desactivar rol' disabled={!_perm44} />
									<CheckPerm value={50} id='perm-50' checked={_perm50} label='Acceso a "Admin roles"' disabled={!_perm44} />
							</div>
							<div className='flex flex-col gap-2 
								3xl:w-1/3 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<label className='w-full flex'>Login</label>
								<CheckPerm value={72} id='perm-72' checked={_perm72} label='Acceso a Configuraciones' disabled={!_perm44} />
								<CheckPerm value={73} id='perm-73' checked={_perm73} label='Editar Configuraciones' disabled={!_perm44} />
							</div>
						</div>
					</div>
				</Card>
		},
		{
			label: 'Reportes',
			key: 'Reportes',
			children: 
				<Card title="" size='small'>
					<div className='flex flex-col gap-4'>
						<CheckPerm value={49} id='perm-49' checked={_perm49} label='Acceso a "Reportes"' disabled={''} />
						<div className="flex gap-2
						3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
							<div className='flex flex-col gap-2 
							3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
									<CheckPerm value={67} id='perm-67' checked={_perm67} label='Acceso a Acumulados' disabled={!_perm49} />
									<CheckPerm value={68} id='perm-68' checked={_perm68} label='Acceso a Sin Acumulados' disabled={!_perm49} />
									<CheckPerm value={69} id='perm-69' checked={_perm69} label='Acceso a Acumulados por mes' disabled={!_perm49} />
									<CheckPerm value={70} id='perm-70' checked={_perm70} label='Acceso a Sucursales' disabled={!_perm49} />
									<CheckPerm value={71} id='perm-71' checked={_perm71} label='Acceso a Descargar Reportes' disabled={!_perm49} />
							</div>
						</div>
					</div>
				</Card>
		},
		{
			label: 'Listado Especial',
			key: 'especial_list',
			children: 
				<Card title="" size='small'>
					<div className='flex gap-4'>
						<CheckPerm value={61} id='perm-61' checked={_perm61} label='Acceso a "Listado especial"' disabled={''} />
					</div>
				</Card>
		},
	];

	const alertMessage = (type) =>{
		switch(type){
			case 'success': return 'Correcto';
			case 'warning': return 'Advertencia';
			case 'error': return 'Error';
		}
		}
	
		const typesUser = [
			{
				value:0,
				label:'General',
			},
			{
				value:1,
				label:'Abrhilsoft',
			},
			{
				value:2,
				label:'Timbrasoft',
			},
			{
				value:3,
				label:'CyL Consultores',
			},];

		//Alert
		const [api, contextHolder] = notification.useNotification();
		useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])
		function openNotification () {
			api[colorMessage]({
			message: alertMessage(colorMessage),
			description: respMessage,
			placement:'bottomRight',
			});
			setColorMessage('')
			setRespMessage('')
		}

		return (
			<div>
			{contextHolder}
			<Spin spinning={loading} size='large'>	
				<div className=''>
					<Card title="Administración de Roles" className='flex flex-col' >
						{ findUserRights(50) ?
						<div className='flex flex-wrap w-full'>
							<div className='flex flex-col gap-2 3xl:w-1/3 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<Table 
									columns={roleTableColumns}
									dataSource={itemsRole}
									className="headerTable rolesAdmin select-none"
									size="small"
									rowKey={(r,i) => 'rolesAdmin_'+i}
									onRow={(record,i)=>{
										return{
											onClick:(event) => {
												$('.rolesAdmin tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
												$(`[data-row-key="rolesAdmin_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
											}
										}
									}}	
								/>
								<div className='flex justify-start items-center'>
									{
									findUserRights(52) ? (
										<Button
											className="bg-[#1677ff] text-white flex justify-center items-center"
											disabled={!ctrlsDisabled}
											onClick={showNewRole}
											text="Nuevo rol"
											icon={<IdcardOutlined className='text-xl'/>}
										/>
									) : <Button 
											text="Nuevo rol"
											icon={<IdcardOutlined className='text-xl'/>}
											disabled={true}
										/>
									}
								</div>
							</div>
							<div className='flex flex-col gap-2 p-2
							3xl:w-2/3 2xl:w-2/3 xl:w-2/3 lg:w-2/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
								<Card title="Propiedades del rol" size='small'>
									<div className='flex gap-4 py-2 justify-between items-center
									3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col' >
										<Input
											className="flex justify-center"
											type="text"
											placeholder="Ej. Consultor"
											disabled={ctrlsDisabled}
											onChange={(e)=> roleNameHndlr(e)}
											value={_roleName}
										/>
										<div className='flex gap-2 justify-center'>
											<Switch 
												className='bg-slate-200'
												disabled={ctrlsDisabled}
												checked={_active}
												onChange={(check)=>toggleRoleActive(check)}
											/>
											<label>Activo</label>
										</div>
										<div className='flex gap-4 justify-center'>
											<Button
												className=""
												disabled={ctrlsDisabled}
												onClick={() => {cancelSaveRolOper()}}
												text="Cancelar"
											/>

											<Button
												className=""
												disabled={ctrlsDisabled}
												onClick={() => {editRoleName()}}
												text="Guardar"
											/>
										</div>
									</div>
								</Card>
								<Card title="Permisos especiales" size='small' >
									<div className='flex gap-2
									3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col'>
									<Radio.Group onChange={handleChangeAll} disabled={ctrlsDisabled} defaultValue={2}
									className='flex gap-4 justify-between
									3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col'  >
										<Radio
											value={1}
											checked={specialPerm === 1}
										>Todos los permisos</Radio>
										<Radio
											value={0}
											checked={specialPerm === 0}
										>Ningún permisos</Radio>
										<Radio
											value={2}
											checked={specialPerm === 2}
										>Personalizado</Radio>
									</Radio.Group>
									{_userLogType === 0 ? 
										<div className='flex gap-4 3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
											<label className='text-xs'>Tipo</label>
											<Select
												className='w-3/4'
												disabled={ctrlsDisabled}
												options={typesUser}
												onChange={typeUserHandler}
												value={_typeRole}
												placeholder="Tipo de rol"
												/>
										</div>: ''}
									</div>
								</Card>
								<Tabs items={rolePanels} />
							</div>
						</div> 
						:<div className='flex justify-center items-center'> <NotAllow/> </div>
						}
					</Card>
				</div>
			</Spin>	
			</div>
		);
}

export default React.memo(RolesAdmin)
import React, { useEffect, useState } from 'react';
import RelBranchComputer from './relBranchComputer';
import RelBranchMessage from './relBranchMessage';
import MainMenu from '../menu/Menu';
import { Card, Tabs, Row, Col } from 'antd';
import NotAllow from '../../@ui/NotAllow';
import NotPermission from '../notPermission';

function Modules() {
	const[rights, setRights] = useState([])
	const [canAccess, setCanAccess] = useState(false);

	useEffect(() => {
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		let typeUser = localStorage.getItem('type');
		setRights(userRights);
		setCanAccess(typeUser === '2' ? false : true)
	},[])
	
	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};
	const panelsToFill=[
		{
			label: 'Computadoras',
			key:'Computadoras',
			children: <RelBranchComputer />
		},
		{
			label: 'Mensajes',
			key:'Mensajes',
			children:<RelBranchMessage />
		}
	];
	
	return (
		<div className='w-full flex flex-col gap-4'>
			{canAccess ? 
			<div className='w-full flex flex-col gap-2'>
				<MainMenu activeItem={'modulos'} />
				<div className='w-full flex justify-center'>
				{findUserRights('1') ? (
					<Card className="w-8/9 flex flex-col justify-center" title="Asignación de Computadoras y Mensajes" size='small'>
						<Tabs items={panelsToFill} size='small' />
					</Card>
					) :
					(
					<Card className="" title="Asignación de Computadoras y Mensajes" size='small'>
						<NotAllow />
					</Card>
					)
				}
				</div>
			</div> : <NotPermission />}
		</div>
	)
}

export default React.memo(Modules)
import React, { useEffect, useState } from 'react';
import BranchReport from './BranchReport';
import BranchNotiReport from './BranchNotiReport';
import ExportReports from './ExportarReports';
import NoAcumulated from './noAccumulated';
import AcumulatedPerMonth from './accumulatedPerMonth';
import MainMenu from '../menu/Menu';
import './Reporting.css';
//import { Tab } from 'semantic-ui-react';
import { Tabs } from 'antd';
import NotPermission from '../notPermission';

function Reporting(){
	//PERMISOS DE USUARIO
	const [ rights, setRights] = useState([])
	const [canAccess, setCanAccess] = useState(false);

	useEffect(() => {
		let stringUserRights = localStorage.getItem('userRights')
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
		let userType = localStorage.getItem('type')
		setCanAccess(userType === '2' ? false : true)
	},[])

	let findUserRights = (findRight) => {		
		let userRights = rights;

		for (let i in userRights) {			
			let x = userRights[i].idOperation;

			if(x === findRight){					
				return true
			}
		}
	}
	
	const items = [
		{
			key:'acumulados',
			label:'Acumulados',
			children:<div>{findUserRights(67) ? <BranchNotiReport className='w-full flex justify-center' /> : <BranchNotiReport disabled={true} /> }</div>,
		},
		{
			key:'noAcumulados',
			label:'Sin Acumulados',
			children:<div>{findUserRights(68) ? <NoAcumulated className='w-full flex justify-center' /> : <NoAcumulated disabled={true} /> }</div>,
		},
		{
			key:'acumuladosPerMonth',
			label:'Acumulados por mes',
			children:<div>{findUserRights(69) ? <AcumulatedPerMonth className='w-full flex justify-center' /> : <AcumulatedPerMonth disabled={true} /> }</div>,
		},
		{
			key:'sucursales',
			label:'Sucursales',
			children:<div>{findUserRights(70) ? <BranchReport className='w-full flex justify-center' /> : <BranchReport disabled={true} /> }</div>,
		},
		{
			key:'descargar_reportes',
			label:'Descargar reportes',
			children:<div>{findUserRights(71) ? <ExportReports className='w-full flex justify-center' /> : <ExportReports disabled={true} /> }</div>,
		},
	]
	
	return (
		<div className='w-full flex flex-col gap-5'>
			{canAccess ? <div>
				<MainMenu activeItem={'reportes'} />
				<div className="w-full flex justify-center 
				3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[700px] md:min-w-[350px]  sm:min-w-[350px] min-w-[350px]
				">
					<Tabs items={items} className='flex p-2 rounded-lg w-11/12' /> 
				</div>
			</div>: <NotPermission />}
		</div>
	);
}
export default React.memo(Reporting)
import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Input, notification, Tooltip, Popconfirm, Select, Switch, Spin, Tag, TreeSelect, Space, Badge } from "antd";
import urlAPI from '../urlAPI/urlAPI.json';
import './UsersTable.css';
import '../MainStyle.css';
import { userService } from '../services/services';
import { EditOutlined, UserAddOutlined, PoweroffOutlined, DeleteOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Table from '../../@ui/Table';
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
import NotAllow from '../../@ui/NotAllow';


function  UsersTable(){
	
	const [itemsUser, setItemsUser] = useState([]);
	const [itemsRoles, setItemsRoles] = useState([]);
	const [_allCorps, set_allCorps] = useState([]);

	const [openModNuevo, setOpenModNuevo] = useState(false);

	const [_idUsuario, set_idUsuario] = useState(0);
	const [_usuario, set_usuario] = useState('');
	const [_password, set_password] = useState('');
	const [_activo, set_activo] = useState(true);
	const [_idRol, set_idRol] = useState('');
	const [_email, set_email] = useState('');
	const [_typeUser, set_typeUser] = useState ('');
	const [_country, set_country] = useState('');
	const [password2, setPassword2] = useState('');
	const [passIgualError, setPassIgualError] = useState(false);
	const [_phone, set_phone] = useState('');
	const [_corps, set_corps] = useState([]);
	const [_lada, set_lada] = useState([]);
  
	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
  const [_filterRol, set_filterRol] = useState([])

	// VARIABLES PARA MOSTRAR MENSAJES
	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');
	const [_notMail, set_notMail] = useState(false);

	//PERMISOS DE USUARIO
	const [rights, setRights] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [toDeactivate, setToDeactivate] = useState(false);
	const [loading, setLoading] = useState(false);
	const [_userLogType, set_userLogType] = useState('0');
	useEffect(() => {cleanAlert();},[passIgualError])
	useEffect(() => {agregarUsuario();},[toDeactivate])

	const getUserCompanys = (id) => {
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + `userCompani/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === false) logout();
			if (json.status) {
				let arrCompanies = [];
				let data = json.data[0];

				data.forEach((element) => {
					arrCompanies.push(element.idCompany);
				});
				set_corps(arrCompanies)

			}
			setLoading(false)
		});
	}

	function editarUsuarioModal(props) {
		setIsEdit(true);
		set_idUsuario(props.idUser);
		set_usuario(props.nameUser);
		set_password('----------');
		setPassword2('----------',);
		set_activo(props.active);
		set_idRol(props.idRole);
		set_email(props.email);
		set_notMail(false);
		set_typeUser(props.typeUser);
		countryHandler(props.country);
		set_phone(props.phone);
		/**add corps */
		getUserCompanys(props.idUser);
		setOpenModNuevo(true);
	}

	function desactivarUsuarioModal(props) {
		setToDeactivate(true);
		setIsEdit(true);
		set_idUsuario(props.idUser);
		set_usuario(props.nameUser);
		set_password('----------');
		set_activo(!props.active);
		set_idRol(props.idRole);
		set_email(props.email);
		set_notMail(false);
		set_typeUser(props.typeUser);
		set_country(props.country); 

		const lada = intLadas.map((obj)=> obj.key === props.country ? obj.value : '');
		set_lada(lada.join(''));

		set_phone(props.phone);
		set_corps([]);
	}

	const intLadas = [
		{ key: "MEX", value: "52" },
		{ key: "CHL", value: "56" },
		{ key: "CRI", value: "506" },
		{ key: "JAM", value: "1+876" },
		{ key: "DOM", value: "1+809" },
		{ key: "COL", value: "57" },
		{ key: "PER", value: "51" },
		{ key: "LCA", value: "1+758" },
		{ key: "GRD", value: "1+809" },
	];

		const regex = /^[0-9]*$/;
		const onlyNumbers = regex.test(`34563`); // true

	// HANDLERS
	const usuarioHandler = (e) => set_usuario(e.target.value);
	const passwordHandler = (e) => set_password(e.target.value);
	const password2Handler = (e) => setPassword2(e.target.value);
	const toggle = (check) => set_activo(check);
	const roleHandler = (e) => set_idRol(e);
	const emailHandler = (e) => set_email(e.target.value);
	const typeUserHandler = (e) => set_typeUser(e);
	const countryHandler = (e) => {
		set_country(e); 
		const lada = intLadas.map((obj)=> obj.key === e ? obj.value : '');
		set_lada(lada.join(''));
	}
	const phoneHandler = (e) => regex.test(e.target.value) ? set_phone(e.target.value) : ''
	const corpsHandler = (selected) => set_corps(selected);
	const clearCorps = () => set_corps([]);

	useEffect(() => {
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		let stringType = localStorage.getItem('type');
		let userType = JSON.parse(stringType);
		set_userLogType(userType);
		setRights(userRights)
		userItm();
		rolesDrop();
		getCompanys();
	},[])

	function getCompanys(){
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'companies/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === false) logout();
			if (json.status) {
				let arrCompanies = [];
				let data = json.data[0];

				data.forEach((element) => {
					arrCompanies.push({
						title: element.codeClient,
						value: element.idCompany,
					});
				});
				set_allCorps(arrCompanies)

			}
			setLoading(false)
		});
	}

	function userItm(){
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + 'users/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					setItemsUser(json.data[0])
				} 
			} else {
				logout();
			}
			setLoading(false)
		});
	}

	function rolesDrop(){
		// llenar el dropdown de roles
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'roles/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) this.logout();
				if (json.status) {
					let arrRoles = [];
					let data = json.data[0];
					data.forEach((element) => {
						arrRoles.push({
							value: element.idRole,
							label: element.roleName,
						});
					});
					setItemsRoles(arrRoles);
          let filterOption = [{ label: "TODOS", value: "", key:'all' }]
					data.forEach((element) => {
						filterOption.push({
							key: element.idRole,
							value: element.roleName,
							label: element.roleName,
						});
					});
          set_filterRol(filterOption)
				}
			}
			setLoading(false)
		});
	}

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	const blackList = ['gmail','hotmail','outlook','yahoo','aol','datadisc','yahwehangelcraft',
	'url','icloud','zoho','gmx']
	
	const validaMail = (mail) => {
		const  complete = mail.split('');
		const at = complete.filter( (e) => e === '@');

		if(at.length===0){
			setColorMessage('error');
			setRespMessage('Correo no valido');
			set_notMail(true);
			return false
		}

		const part = mail.split('@')
		const email = part[0];
		const dom = part[1];
		const dot = (dom.split('')).filter( (e) => e === '.');

		if(dot.length===0){
			setColorMessage('error');
			setRespMessage('Correo no valido');
			set_notMail(true);
			return false
		}

		const partDom = dom.split('.');
		const corp = partDom[0];

		const verifyCorp = blackList.filter((e) => e === corp)

		if(verifyCorp.length !== 0){
			setColorMessage('error');
			setRespMessage('Debe ser un correo empresarial');
			set_notMail(true);
			return false
		}

		/**
		 * Falta validacion para verificar si existe el correo
		 */
		
		set_notMail(false);
		return true;
	}

	function agregarUsuario() {
		
		if(!toDeactivate){
			if(_usuario === '' || _password === '' || _email === '' || _idRol === '' || password2 === ''){
				if(openModNuevo){
					setColorMessage('error');
					setRespMessage('Los campos son obligatorios');
				}
				return;
			}
			if (!validaPassword()) return;
			if(!validaMail(_email)) return;
		}
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

			let nuevoUsuario = {
				idUser: isEdit ? _idUsuario : '',
				nameUser: _usuario,
				password: _password,
				active: _activo ? 1 : 0,
				idRole: _idRol,
				email: _email,
				userCap: userLogged,
				typeUser: _typeUser,
				country: _country,
				phone: _phone,
				corps: _corps,
				lada:_lada
			};
			
			setLoading(true)
			fetch(urlAPI.url + 'users/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': token,
				},
				body: JSON.stringify(nuevoUsuario),
			})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.status) {
					setRespMessage(json.data[0].mensaje)
					setColorMessage(tipoMensaje(json.data[0].numMensaje))
					userItm();
					rolesDrop();
				} else {
					setRespMessage(json.mensaje)
					setColorMessage('error')
				}
				limpiaCampos();
				closeNuevo();
				setLoading(false)
			});
	}

	function validaPassword() {
		if (_password.length < 8) {
			setColorMessage('error');
			setRespMessage('La contraseña debe tener más de 8 caracteres');
			return false;
		} 

		if (_password !== password2) {
			setColorMessage('error');
			setRespMessage('Las contraseñas no coinciden');
			return false;
		} 

		return true;
	}

	function limpiaCampos() {
		set_idUsuario('');
		set_usuario('');
		set_password('');
		set_activo(true);
		set_idRol('');
		set_email('');
		setPassword2('');
		setPassIgualError('');
		set_typeUser('');
		set_country(''); 
		setIsEdit(false);
		setToDeactivate(false);
		set_phone('');
		set_corps([]);
	}

	function cleanAlert(){
		setPassIgualError('');
	}

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function showNuevo(){

		setColorMessage('');
		setRespMessage('');

		setPassIgualError('');

		setOpenModNuevo(true);	
	}

	function closeNuevo(){setOpenModNuevo(false); limpiaCampos(); cleanAlert(); }

	function deleteUser(idUser){
		let token = localStorage.getItem("x-access-token");
		setLoading(true)
		fetch(urlAPI.url + `users/${idUser}`, {
		  method: "DELETE",
		  headers: { "x-access-token": token, },
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
		if (json.status) {
			setRespMessage(json.data[0].mensaje)
			setColorMessage(tipoMensaje(json.data[0].numMensaje));
			userItm();
		} else {
			setRespMessage(json.mensaje)
			setColorMessage('error')
		}
		setLoading(false)
		});
	}

    //Filtrar
	  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
      if(selectedKeys === '' ){clearFilters && handleReset(clearFilters);}
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
	  };

	  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText(''); 
	  };
  
    const getColumnRolProps = (dataIndex) => ({
		  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			  <Select
          style={{ marginBottom: 8, display: 'block', }}
          options={_filterRol}
          onChange={(e) => setSelectedKeys(e ? [e] : []) }
        />
			  <Space>
				<Button
				  type="primary"
				  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				  icon={<SearchOutlined />}
				  size="small"
				  style={{
					width: 90,
				  }}
				  className="bg-[#1677ff]"
				>
				  Buscar
				</Button>
			  </Space>
			</div>
		  ),
		  filterIcon: (filtered) => (
        filtered ?
        (<Badge className="animate-pulse" dot>
          <SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />
        </Badge>):(<SearchOutlined
            className='w-[20px] h-[20px] text-[18px]'
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
          />)
		  ),
		  onFilter: (value, record) =>{
			  if(record[dataIndex]) {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }else{
          return ''.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
		  onFilterDropdownOpenChange: (visible) => {
			if (visible) {
			  setTimeout(() => searchInput.current?.select(), 100);
			}
		  },
		  render: (text) =>
			searchedColumn === dataIndex ? (
			  <Highlighter
				highlightStyle={{
				  backgroundColor: '#ffc069',
				  padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
			  />
			) : (
			  text
			),
		});

	const columnas = [
		{
			title: 'Usuario',
			dataIndex: 'nameUser',
			width: 100,
			className:'select-none',
			ellipsis: true,
		},
		{
			title: 'Activo',
			dataIndex: 'active',
			align:"center",
			width:40,
			className:'select-none',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return props ? 'SI' : 'NO'
			},
		},
		{
			title: 'Rol',
			dataIndex: 'roleName',
			width: 200,
			align:"center",
			className:'select-none',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
      onFilter:(x,y,z)=>{},
      ...getColumnRolProps('roleName'),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			align:"center",
			className:'select-none',
			width: 250,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'Tipo',
			dataIndex: 'typeUser',
			align:"center",
			className:'select-none',
			width: 100,
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				let color, type;
				switch(props){ 
					case '0': color='cyan'; type='Abrhilsoft'; break; 
					case '1': color='magenta'; type='Timbrasoft'; break; 
					case '2': color='geekblue'; type='CyL Consultores'; break; 
					case '3': color='purple'; type='Externo'; break; 
					default: color='volcano'; type='-sin tipo-'; break; 
				}
				return(
				<Tag color={color} key={props}>
					{type.toUpperCase()}
				</Tag>
			  )},
		},
		{
			title: 'Acción',
			width: 100,
			align:"center",
			className:'p-0 select-none',
			render: (props) => {
				let status = props.active
				return (
					<div className='flex gap-2 justify-center select-none '>
						{findUserRights(47) ? (
						<Tooltip placement="top" title={'Editar'} >
						<Button
							className="flex justify-center items-center p-2 bg-blue-200 select-none"
							onClick={() => {
								editarUsuarioModal(props)
							}}
						>
							<EditOutlined className='select-none' />
						</Button>
						</Tooltip>
						) : null}

						{findUserRights(48) ? (
						<Tooltip placement="top" title={status ? 'Desactivar' : 'Activar'} >
						<Popconfirm
							title={status ? 'Desactivar usuario' : 'Activar usuario'}
							description={'¿Desea actulizar el usuario "'+ props.nameUser +'" ?'}
							onConfirm={() => {desactivarUsuarioModal(props);}}
							onCancel={()=>{}}
							okText={status ? 'Desactivar' : 'Activar'}
							cancelText="Cancelar"
							okButtonProps={status ? { className:'bg-red-600' } : { className:'bg-blue-600' }}
						>
							<Button className={`flex justify-center items-center p-2 select-none ${status ? 'bg-slate-50' : 'bg-emerald-200'}`}>
								<PoweroffOutlined className='select-none text-slate-500'/>
							</Button>
						</Popconfirm>
						</Tooltip>
						) : null}

						{findUserRights(48) ? (
						<Tooltip placement="top" title={'Elminar'} >
						<Popconfirm
							title="Eliminar usuario"
							description={'¿Desea Eliminar el usuario "'+ props.nameUser +'" ?'}
							onConfirm={() => {deleteUser(props.idUser);}}
							onCancel={()=>{}}
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{className:'bg-red-600'}}
						>
							<Button className="flex justify-center items-center p-2 bg-red-200 select-none">
								<DeleteOutlined className='select-none'/>
							</Button>
						</Popconfirm>
						</Tooltip>
						) : null}
					</div>
				);
			},
		},
	];

	let userOptions = (userType) => {
		let newList = [];
		switch(userType){
			case 0: 
				newList = [
					{
						value:'0',
						label:'Abrhilsoft',
					},
					{
						value:'1',
						label:'Timbrasoft',
					},
					{
						value:'2',
						label:'CyL Consultores',
					},
					{
						value:'3',
						label:'Externo',
					},
				];
			break;
			case 1: newList = [ { value:'1', label:'Timbrasoft', },]; break;
			case 2: newList = [ { value:'2', label:'CyL Consultores', },]; break;
			case 2: newList = [ { value:'3', label:'Externo', },]; break;
			case 'default': 
				newList = [
					{
						value:'0',
						label:'Abrhilsoft',
					},
					{
						value:'1',
						label:'Timbrasoft',
					},
					{
						value:'2',
						label:'CyL Consultores',
					},
					{
						value:'3',
						label:'Externo',
					},
				]; 
			break;
		}
		return newList;
	}

	const typesUser = userOptions(_userLogType);

	const optnsCountry = [
		{ label: "México", value: "MEX" },
		{ label: "Chile", value: "CHL" },
		{ label: "Costa Rica", value: "CRI" },
		{ label: "Jamaica", value: "JAM" },
		{ label: "Dominicana", value: "DOM" },
		{ label: "Colombia", value: "COL" },
		{ label: "Perú", value: "PER" },
		{ label: "Santa Lucía", value: "LCA" },
		{ label: "Granada", value: "GRD" },
	];

	let findUserRights = (findRight) => {
		let userRights = rights;
		for (let i in userRights) {
			let x = userRights[i].idOperation;
			if (x === findRight) {
				return true;
			}
		}
	};

	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])

    const alertMessage = (type) =>{
		switch(type){
		  case 'success': return 'Correcto';
		  case 'warning': return 'Advertencia';
		  case 'error': return 'Error';
		}
	}
	
	function openNotification () {
		api[colorMessage]({
		message: alertMessage(colorMessage),
		description: respMessage,
		placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}
	
	return (
		<div className='flex flex-col'>
		{contextHolder}
		{findUserRights(45) ? <Spin spinning={loading} size='large'>	
			<Table
				bordered={true}
				columns={columnas}
				dataSource={itemsUser}
				rowKey={(r,i) => 'userTable_'+i}
				className='headerTable userTable select-none flex
				3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[900px] md:min-w-[600px] sm:min-w-[350px] xs:min-w-[350px] min-w[350px]'
				size='small'
				onRow={(record,i)=>{
					return{
						onClick:(event) => {
							$('.userTable tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
							$(`[data-row-key="userTable_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
						}
					}
				}}	
			/>

			<div className="">
				<div className="flex justify-between">
					{findUserRights(46) ? (
						<Button 
						icon={<UserAddOutlined className='text-xl'/>}
						onClick={()=>{ setIsEdit(false); showNuevo()}}
						type='primary'
						className='bg-[#1677ff] flex justify-center items-center'
						>
						Nuevo usuario
						</Button>
					) : null}
				</div>
			</div>

			<Modal 
				open={openModNuevo}
				title={ isEdit ? 'Editar usuario' : 'Nuevo usuario'}
				onOk={agregarUsuario}
				okText="Guardar"
				onCancel={closeNuevo}
				cancelText="Cancelar"
				okButtonProps={{className:'bg-[#1677ff]'}}
        className='w-1/2 flex'
        width={1000}
			>
				<div className='flex flex-col gap-4'>		
            <div className='flex gap-2 flex-wrap'>
              <div className='flex flex-col gap-4 
							3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
                <label className=''>Usuario</label>
                <Input
                  value={_usuario}
                  className=""
                  placeholder="Nombre de usuario"
                  onChange={(e) => {usuarioHandler(e)}}
                  maxLength={50}
                  status={_usuario === '' ? 'error' : 'success'}
                />
              </div>

              <div className='flex flex-col gap-4 
							3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
                <label className=''>Rol</label>
                <Select
                  options={itemsRoles}
                  onChange={roleHandler}
                  value={_idRol}
                  placeholder="Selecciona una rol"
                  status={_idRol === '' ? 'error' : 'success'}
                  />
              </div>

              <div className='flex flex-col gap-4 
							3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
                <label className=''>Tipo</label>
                <Select
                  options={typesUser}
                  onChange={typeUserHandler}
                  value={_typeUser}
                  placeholder="Selecciona un tipo"
                  status={_typeUser === '' ? 'error' : 'success'}
                  />
              </div>

              <div className='flex justify-end items-end gap-4 
							3xl:w-1/5 2xl:w-1/5 xl:w-1/5 lg:w-1/5 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
                <label className=''>Activo</label>
                <Switch onChange={toggle} checked={isEdit ? _activo : true} className='bg-slate-200'/>
              </div>

            </div>

            <div className='flex gap-2 flex-wrap'>
              <div className='flex flex-col gap-2 w-11/12'>
                <label className=''>Contraseña</label>
                <Input
                  value={_password}
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) => {passwordHandler(e)}}
                  maxLength={50}
                  status={_password === '' ? 'error' : 'success'}
                />
              </div>

              <div className='flex flex-col gap-2 w-11/12'>
                <label className=''>Repita la contraseña</label>
                <Input
                  value={password2}
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) => {password2Handler(e)}}
                  maxLength={50}
                />
              </div>

              <div className='flex flex-col gap-2 w-11/12'>
                <label className=''>Email</label>
                <Input
                  className=""
                  value={_email}
                  placeholder="ejemplo@dominio.com"
                  onChange={(e) => {emailHandler(e)}}
                  maxLength={100}
                  status={_email === '' ? 'error' : 'success' && _notMail ? 'error' : 'success'}
                />
              </div>
            </div>

					<div className='flex flex-wrap gap-4'>
						<div className="flex gap-2 
						3xl:w-1/3 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12">
								<label className='w-1/4'>País</label>
								<Select 
                  className='w-3/4'
									value={_country} 
									onChange={countryHandler} 
									options={optnsCountry}
									status={_country === '' ? 'error' : 'success'}
								/>
						</div>

						<div className='flex gap-2 items-end 
						3xl:w-1/5 2xl:w-1/5 xl:w-1/5 lg:w-1/5 md:w-2/3 sm:w-2/3 xs:w-2/3 w-2/3'>
							<label className='w-1/2'>Lada:</label>
							<Input
								className="disbled"
								value={_lada}
								placeholder="Selecciona un país"
								maxLength={10}
								disabled
							/>
						</div>

						<div className='flex gap-2 items-end 
						3xl:w-1/3 2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-11/12 sm:w-11/12 xs:w-11/12 w-11/12'>
							<label className=''>Telefono</label>
							<Input
								className=""
								value={_phone}
								placeholder="998 333 4444"
								onChange={(e) => {phoneHandler(e)}}
								maxLength={10}
							/>
						</div>
					</div>

					<div className='flex flex-wrap gap-4'>
						<div className="flex flex-col gap-2 w-full">
								<label className=''>Coorporativos</label>
								<TreeSelect 
									className='w-full'
									allowClear={true}
									treeCheckable={true}
									showCheckedStrategy={TreeSelect.SHOW_CHILD}
									onChange={corpsHandler} 
									value={_corps} 
									treeData={([
										{
											title:
												_corps.length > 0 ? (
													<span onClick={() => set_corps([])} className='text-rose-700'>
														Eliminar seleccionados
													</span>
												) : (
													<span onClick={() => set_corps(_allCorps.map(({value}) => value))}  className='text-sky-500'>
														Seleccionar todos
													</span>
												),
											value: "xxx",
											checkable:false,
											disableCheckbox: true
										}]).concat(_allCorps)}
									clearIcon={<CloseOutlined className='text-[14px] text-black' />}
									onClear={clearCorps}
									filterTreeNode
									treeNodeFilterProp={"title"}
								/>
						</div>
					</div>

				</div>	
			</Modal>
		</Spin> : <NotAllow /> }
		</div>
	);
}
export default React.memo(UsersTable);
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { CSVLink, CSVDownload } from 'react-csv';
import { Card, Select, Spin, Table, Button, Space, Badge, Input, Checkbox } from 'antd';
import { CloseOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ButtonUI from '../../@ui/Button';
import Highlighter from 'react-highlight-words';
import NotAllow from '../../@ui/NotAllow';

function AcumulatedPerMonth(){
	//Filtro por año
	const year = (new Date()).getFullYear();
	const actualMonth = (new Date()).getMonth();
	const pastMonth = (actualMonth - 1) < 0 ? 12 : (actualMonth - 1);

	const yearFilters= [];	
	const monthOptions = [
		{ value: 1, label: 'Enero'},
		{ value: 2, label: 'Febrero'},
		{ value: 3, label: 'Marzo'},
		{ value: 4, label: 'Abril'},
		{ value: 5, label: 'Mayo'},
		{ value: 6, label: 'Junio'},
		{ value: 7, label: 'Julio'},
		{ value: 8, label: 'Agosto'},
		{ value: 9, label: 'Septiembre'},
		{ value: 10, label: 'Octubre'},
		{ value: 11, label: 'Noviembre'},
		{ value: 12, label: 'Diciembre'},
	]

	for(var i=2019; i<year + 1; i++){
		yearFilters.push({ value:i, label:i});
	}

	const [ _itemsReport, set_itemsReport ] = useState([]);

	// VALORES PARA LOS FILTROS
	const [ _yearValue, set_yearValue ] = useState(year);
	const [ _filteredData, set_filteredData ] = useState([]);
	const [loading, setLoading] = useState(false);

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [ _filterCorp, set_filterCorp ] = useState([]);
	const [ _filterClient, set_filterClient ] = useState([]);
	const [ _filterCountry, set_filterCountry ] = useState([]);
	const [ _filterZero, set_filterZero ] = useState(actualMonth);
	const [ _activeZero, set_activeZero] = useState(true);
	const [ _dataZero, set_dataZero] = useState([]);

	const [ rights, setRights] = useState([])

	const handleYear = (value) => set_yearValue(value); 
	const clearDropYear = (e) => set_yearValue(year);
	const handlerActiveZero = (c) => {set_activeZero(c.target.checked); set_filterZero(actualMonth);} 
	const handlerFilZero = (value) => {
		set_filterZero(value);
		filteredZero(_filteredData, value);
	}

	useEffect(()=>{ 
		getPerMonth(); 

		let stringUserRights = localStorage.getItem('userRights')
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
	},[])

	const convertToDate = (date) => dayjs(date).format('DD/MM/YYYY');

	function filteredZero(data, month){
		const zeroData = data.filter( element => element[month] === 0);
		set_dataZero(zeroData);
	}
	function getPerMonth(){
		let token = localStorage.getItem('x-access-token');

		setLoading(true);
		fetch(urlAPI.url + `reportAcumuladoClienteMes/${_yearValue}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut === undefined) {
					if (json.aut === false) logout();
					if (json.status) {
						set_itemsReport(json.data[0])
						let filteredData = JSON.parse(JSON.stringify(json.data[0]));

						const corpFilter = filteredData.map((element)=>element.codeClient)
						const clientFilter = filteredData.map((element)=>element.description)
						const countryFilter = filteredData.map((element)=>element.country)

						filteredData.map((element)=>{
							element.dateCapture=convertToDate(element.dateCapture);
							element.startDate=convertToDate(element.startDate);
							element.endDate=convertToDate(element.endDate);
							element['1']= element['1'] ? element['1'] : 0;
							element['2']= element['2'] ? element['2'] : 0;
							element['3']= element['3'] ? element['3'] : 0;
							element['4']= element['4'] ? element['4'] : 0;
							element['5']= element['5'] ? element['5'] : 0;
							element['6']= element['6'] ? element['6'] : 0;
							element['7']= element['7'] ? element['7'] : 0;
							element['8']= element['8'] ? element['8'] : 0;
							element['9']= element['9'] ? element['9'] : 0;
							element['10']= element['10'] ? element['10'] : 0;
							element['11']= element['11'] ? element['11'] : 0;
							element['12']= element['12'] ? element['12'] : 0;
						})
						set_filteredData(filteredData);
						filteredZero(filteredData,_filterZero);
						const cleanCorp = corpFilter.filter((x, i) => corpFilter.indexOf(x) === i);
						const cleanClient = clientFilter.filter((x, i) => clientFilter.indexOf(x) === i);
						const cleanCountry = countryFilter.filter((x, i) => countryFilter.indexOf(x) === i);

						set_filterCorp(cleanCorp.map((val) => {return {label:val, value:val}}));
						set_filterClient(cleanClient.map((val) => {return {label:val, value:val}}));
						set_filterCountry(cleanCountry.map((val) => {return {label:val, value:val}}));
					} 
				} else {
					logout();
				}
				setLoading(false);
			});

	}
    
    //Filtrar
	  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
		if(selectedKeys === '' ){clearFilters && handleReset(clearFilters);}
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
		};
  
		const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText(''); 
		};

		const getColumnCorpProps = (dataIndex) => ({
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			  <div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
				<Select
			style={{ marginBottom: 8, display: 'block', width: 200 }}
			options={([{label:'Todos', value:''}]).concat(_filterCorp)}
			onChange={(e) => setSelectedKeys(e ? [e] : []) }
			showSearch={true}
		  />
				<Space>
				  <Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{
					  width: 90,
					}}
					className="bg-[#1677ff]"
				  >
					Buscar
				  </Button>
				</Space>
			  </div>
			),
			filterIcon: (filtered) => (
		  filtered ?
		  (<Badge className="animate-pulse" dot>
			<SearchOutlined
			  className='w-[20px] h-[20px] text-[18px]'
			  style={{
			  color: filtered ? '#1890ff' : undefined,
			  }}
			/>
		  </Badge>):(<SearchOutlined
			  className='w-[20px] h-[20px] text-[18px]'
			  style={{
			  color: filtered ? '#1890ff' : undefined,
			  }}
			/>)
			),
			onFilter: (value, record) =>{
				if(record[dataIndex]) {
			return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  }else{
			return ''.toString().toLowerCase().includes(value.toLowerCase())
		  }
		},
			onFilterDropdownOpenChange: (visible) => {
			  if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			  }
			},
			render: (text) =>
			  searchedColumn === dataIndex ? (
				<Highlighter
				  highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				  }}
				  searchWords={[searchText]}
				  autoEscape
				  textToHighlight={text ? text.toString() : ''}
				/>
			  ) : (
				text
			  ),
		  });
	
		const getColumnClientProps = (dataIndex) => ({
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
				<Select
			style={{ marginBottom: 8, display: 'block', width: 300}}
			options={([{label:'Todos', value:''}]).concat(_filterClient)}
			onChange={(e) => setSelectedKeys(e ? [e] : []) }
			showSearch={true}
		/>
				<Space>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{
					width: 90,
					}}
					className="bg-[#1677ff]"
				>
					Buscar
				</Button>
				</Space>
			</div>
			),
			filterIcon: (filtered) => (
		filtered ?
		(<Badge className="animate-pulse" dot>
			<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>
		</Badge>):(<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>)
			),
			onFilter: (value, record) =>{
				if(record[dataIndex]) {
			return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		}else{
			return ''.toString().toLowerCase().includes(value.toLowerCase())
		}
		},
			onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
			},
			render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
				highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				}}
				searchWords={[searchText]}
				autoEscape
				textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
		});

		const getColumnCountryProps = (dataIndex) => ({
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			  <div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
				<Select
			style={{ marginBottom: 8, display: 'block', }}
			options={([{label:'Todos', value:''}]).concat(_filterCountry)}
			onChange={(e) => setSelectedKeys(e ? [e] : []) }
		  />
				<Space>
				  <Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{
					  width: 90,
					}}
					className="bg-[#1677ff]"
				  >
					Buscar
				  </Button>
				</Space>
			  </div>
			),
			filterIcon: (filtered) => (
		  filtered ?
		  (<Badge className="animate-pulse" dot>
			<SearchOutlined
			  className='w-[20px] h-[20px] text-[18px]'
			  style={{
			  color: filtered ? '#1890ff' : undefined,
			  }}
			/>
		  </Badge>):(<SearchOutlined
			  className='w-[20px] h-[20px] text-[18px]'
			  style={{
			  color: filtered ? '#1890ff' : undefined,
			  }}
			/>)
			),
			onFilter: (value, record) =>{
				if(record[dataIndex]) {
			return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		  }else{
			return ''.toString().toLowerCase().includes(value.toLowerCase())
		  }
		},
			onFilterDropdownOpenChange: (visible) => {
			  if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			  }
			},
			render: (text) =>
			  searchedColumn === dataIndex ? (
				<Highlighter
				  highlightStyle={{
					backgroundColor: '#ffc069',
					padding: 0,
				  }}
				  searchWords={[searchText]}
				  autoEscape
				  textToHighlight={text ? text.toString() : ''}
				/>
			  ) : (
				text
			  ),
	  });

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	const monthFilters = (month) => {
		switch((month+1)){
			case 1: return 'Enero';
			case 2: return 'Febrero';
			case 3: return 'Marzo';
			case 4: return 'Abril';
			case 5: return 'Mayo';
			case 6: return 'Junio';
			case 7: return 'Julio';
			case 8: return 'Agosto';
			case 9: return 'Septiembre';
			case 10: return 'Octubre';
			case 11: return 'Noviembre';
			case 12: return 'Diciembre';
			default: return 'Enero'
		}
	};

    const month = [];		
    for(let i=0; i<12; i++){
        month.push(
            {
                title: monthFilters(i).substring(0, 3),
                dataIndex: `${(i+1)}`,
                align: 'right',
								width:60,
                ellipsis: true,
                responsive: actualMonth === i ? '' : ['xxl', 'xl', 'lg'],
                render: (e) => e ? e : 0,
            })
    }

	const monthFilterCSV = [];
    for(let i=0; i<12; i++){
        monthFilterCSV.push(
            {
                label: monthFilters(i),
                key: `${(i+1)}`,
            })
    }

	const headerCSV = [
		{ label: 'Clv Corporativo', key: 'idCompany' },
		{ label: 'Corporativo', key: 'codeClient' },
		{ label: 'Clv Cliente', key: 'idBranch' },
		{ label: 'Cliente', key: 'description' },
		{ label: 'País', key: 'country' },
		{ label: 'Fecha de captura', key: 'dateCapture' },
		{ label: 'Inicio Contrato', key: 'startDate' },
		{ label: 'Fin Contrato', key: 'endDate' },
		{ label: 'Año', key: 'year' },
	]

	const columnas = [
		{
			title: 'Corporativo',
			dataIndex: 'codeClient',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			onFilter:(x,y,z)=>{},
			...getColumnCorpProps('codeClient'),
		},
		{
			title: 'Cliente',
			dataIndex: 'description',
      width:150,
			ellipsis: true,
			onFilter:(x,y,z)=>{},
			...getColumnClientProps('description'),
		},
		{
			title: 'País',
			dataIndex: 'country',
			width: 50,
			ellipsis: true,
			onFilter:(x,y,z)=>{},
			...getColumnCountryProps('country'),
		},
		{
			title: 'Año',
			dataIndex: 'year',
			align: 'right',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'F. Captura',
			dataIndex: 'dateCapture',
			align: 'right',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'F. Inicio',
			dataIndex: 'startDate',
			align: 'right',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'F. Fin',
			dataIndex: 'endDate',
			align: 'right',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},

	];

	let findUserRights = (findRight) => {		
		let userRights = rights;

		for (let i in userRights) {			
			let x = userRights[i].idOperation;

			if(x === findRight){					
				return true
			}
		}
	}

	return (
		<div className=' flex w-full 3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[700px] md:min-w-[300px]  sm:min-w-[300px] min-w-[300px]'>
			<Spin spinning={loading} size='large'>
			<Card title="" className=' flex w-full
      3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[700px] md:min-w-[300px]  sm:min-w-[300px] min-w-[300px]'>
				{ findUserRights(69) ? <div className='flex flex-col flex-wrap gap-4
         3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[700px] md:min-w-[300px]  sm:min-w-[300px] min-w-[300px]'>
					<div className="flex gap-4 items-center flex-wrap">
						{/* FILTRO POR AÑO */}
						<div className="flex gap-2 justify-center items-center">
							<label>Año:</label>
							<Select
								value={_yearValue}
								options={yearFilters}
								onChange={handleYear}
								className="dropdow-year-filter"
								placeholder="Selecciona un año"
								allowClear
								clearIcon={<CloseOutlined className='text-[14px] text-black' />}
								onClear={clearDropYear}
							/>
						</div>

						{/* BOTÓN FILTRAR */}
						<div width={1} className="flex justify-center">
							<Button 
								text='Filtrar' 
								className="bg-[#1890ff] text-white flex justify-center items-center" 
								onClick={(e) => getPerMonth()}
								icon={<SearchOutlined className='text-xl'/>}
							/>
						</div>

						{/* FILTRAR CERO */}
						<div className="flex gap-2 justify-center items-center">
							<Checkbox 
								onChange={handlerActiveZero}
								defaultChecked={_activeZero}
							/>
							<label>Sin acumulados {_activeZero ? `Mes:` : ''}</label>
							{_activeZero ? <Select
								value={_filterZero}
								options={monthOptions}
								onChange={handlerFilZero}
							/>:''}
						</div>
					</div>
					
					<Table 
						columns={columnas.concat(month)}
						dataSource={ _activeZero ? _dataZero : _filteredData}
						className="headerTable w-full branchNotiReport
            flex"
						size="small"
						rowKey={(r,i) => 'acumPerMonth'+i}
						onRow={(record,i)=>{
							return{
								onClick:(event) => {
									$('.branchNotiReport tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
									$(`[data-row-key="acumPerMonth${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
								}
							}
						}}	
					/>
					<CSVLink data={_filteredData} headers={ headerCSV.concat(monthFilterCSV) } className='flex w-fit'
						filename={"Reporte_por_mes.csv"}
					>
						<ButtonUI 
							text='Exportar'
							icon={<DownloadOutlined className='text-xl'/>}
							className='text-white bg-[#1890ff] flex justify-center items-center'
						/>
					</CSVLink>
				</div> : <NotAllow />}
			</Card>
			</Spin>
		</div>
	);
}
export default React.memo(AcumulatedPerMonth)
import React, { useState, useEffect } from 'react';
import './Menu.css';
import { Menu, Dropdown, Avatar } from 'antd';
import { userService } from '../services/services';
import { AppstoreOutlined, ReadOutlined, ControlOutlined, FileSearchOutlined, UserOutlined, ExportOutlined  } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import urlAPI from '../urlAPI/urlAPI.json';

function MainMenu() {
	const [ username, setUsername ] = useState('');
	const [ isAbrhil, setIsAbrhil ] = useState(false)
	const [ _access_module, set_access_module ] = useState(false);
	const [ _access_catalog, set_access_catalog ] = useState(false);
	const [ _access_admin, set_access_admin ] = useState(false);
	const [ _access_report, set_access_report ] = useState(false);
	const [ _access_worker, set_access_worker ] = useState(false);
	let location = useLocation();
	
	const [current, setCurrent] = useState(location.pathname);
	const onClick = (e) => {
	  setCurrent(e.key||'/');
	};

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	function accessLog(){
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		if(!Array.isArray(userRights)) {logout(); return;}
		let access = userRights.map((operation) => {return operation['idOperation']});
		access.includes(1) ? set_access_module(true) : set_access_module(false)
		access.includes(10) ? set_access_catalog(true) : set_access_catalog(false)
		access.includes(44) ? set_access_admin(true) : set_access_admin(false)
		access.includes(49) ? set_access_report(true) : set_access_report(false)
		access.includes(61) ? set_access_worker(true) : set_access_worker(false)
	}

function rights(){
	const beforeStorage = JSON.parse(localStorage.getItem('userRights'))
	const accessStorage = Number(localStorage.getItem('access'))
	if(!Array.isArray(beforeStorage)){logout(); return;}
	if(beforeStorage.length !== accessStorage){
		fetch(urlAPI.url + `userRights/`, {
			method: 'GET',
			headers: {
					'Content-Type': 'application/json',
					'x-access-token': localStorage.getItem('x-access-token'),
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
				if (json.status) {
						if(beforeStorage.length !== json.access) {logout(); return;}
						else accessLog();
				}
		});
	}else accessLog();
}

	useEffect(() => {		
		if (localStorage.getItem('x-access-token') === null) {logout ()}
		rights()
		let usuario = localStorage.getItem('username')
		setUsername(usuario);
	},[]);
	const items = [
		{
		},
		_access_module ? {
			label:(<a href='/' rel='noopener noreferrer'>Modulos</a>),
			key:'/',
			icon:<AppstoreOutlined />,
		} : '',
		_access_catalog ? {
			label:(<a href='/catalogue' rel='noopener noreferrer'>Catalogos</a>),
			key:'/catalogue',
			icon:<ReadOutlined />,
		} : '',
		_access_admin ? {
			label:(<a href='/usersAdmin' rel='noopener noreferrer'>Administracion</a>),
			key:'/usersAdmin',
			icon:<ControlOutlined />,
		} : '',
		_access_report ? {
			label:(<a href='/reporting' rel='noopener noreferrer'>Reportes</a>),
			key:'/reporting',
			icon:<FileSearchOutlined />,
		} : '',
		_access_worker ? {
			label:(<a href='/workers' rel='noopener noreferrer'>Listado Especial</a>),
			key:'/workers',
			icon:<UserOutlined />,
		} : '',
	]

	const userMenu = [
		{
			label:'Salir',
			key:0,
			icon: <ExportOutlined />,
			onClick: () => { logout() },
			danger:true,
		},
	]
	
	return (
		<div className='flex h-'>
			<Menu onClick={onClick} mode="horizontal" items={items} selectedKeys={[current]} className='w-1/2' />
			<div className='flex items-center justify-end w-1/2 px-12 py-2'>
				<Dropdown menu={{items:userMenu}} className="flex items-center justify-end min-h-full p-2 border-2 rounded-lg"  >
					<div className='text-lg flex gap-2'>
						<Avatar size={'small'} icon={<UserOutlined />} />
						{username}
					</div>
				</Dropdown>
			</div>
		</div>
	);
}

export default React.memo(MainMenu)
import React from "react";
import { Table } from "antd";

function TableComponent(props){

    return (<Table 
        selections={false}
        title= {props.title ? props.title : ''}
        bordered= {props.bordered ? props.bordered : false}
        columns= {props.columns ? props.columns : []}
        dataSource= {props.dataSource ? props.dataSource : []}
        rowClassName= {props.rowClassName ? props.rowClassName : ()=>{return ''}}
        rowKey= {props.rowKey ? props.rowKey : (record,i) => 'nonTable_'+i}
        showHeader= {props.showHeader ? props.showHeader : true}
        size= {props.size ? props.size : 'middle'}
        onRow= {props.onRow ? props.onRow : null}
        className= {props.className ? props.className : ''}
    />)

}
export default React.memo(TableComponent)
import React, { useState, useEffect } from 'react';
import Worker from './Worker';
import MainMenu from '../menu/Menu';
import { Card, Input, Button, notification, Spin, Modal, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import urlAPI from "../urlAPI/urlAPI.json";
import { userService } from '../services/services';
import { useNavigate } from 'react-router-dom';
import NotPermission from '../notPermission';
const { Search } = Input;

function Workers() {
	const [rights, setRights] = useState([]);
	const [_name, set_name] = useState('');
	const [_firstSurname, set_firstSurname] = useState('');
	const [_secondSurname, set_secondSurname] = useState('');
	const [_rfc, set_rfc] = useState('');
	const [_curp, set_curp] = useState('');
	const [_nss, set_nss] = useState('');
	const [loading, setLoading] = useState(false);
	const [_worker, set_worker] = useState({});
	const [_idMovementWorker, set_idMovementWorker] = useState(null);
	const [_idAutorization, set_idAutorization] = useState(null);
	const [_countryType, set_countryType] = useState('MEX');
	const [showModalStatus, setShowModalStatus] = useState(false);
	// VARIABLES PARA MOSTRAR MENSAJES
	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');
	const [_message, set_message] = useState('');
	const [showModalDelete, setShowModalDelete] = useState(false);

	let stringUserRights, userRights;

	useEffect(() => {
		if (localStorage.getItem('x-access-token') === null) {logout ()}
		stringUserRights = localStorage.getItem('userRights');
		userRights = JSON.parse(stringUserRights);
		setRights(userRights)
		set_countryType(localStorage.getItem('country'))
	},[]);

	let findUserRights = (findRight) => {
		let userRights = rights;
		for (let i in userRights) {
			let x = userRights[i].idOperation;
			if (x == findRight) {
				return true;
			}
		}
	};

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};
	const name = (val) => set_name((val.target.value).trim());
	const firstSurname = (val) => set_firstSurname((val.target.value).trim());
	const secondSurname = (val) => set_secondSurname((val.target.value).trim());
	const rfc = (val) => set_rfc((val.target.value).trim());
	const curp = (val) => set_curp((val.target.value).trim());
	const nss = (val) => set_nss((val.target.value).trim());
	const checkConfig = (country) => {
		switch(country){
			case 'MEX': return 'RFC';
			case 'CHL': return 'RFC';
			case 'CRI': return 'RFC';
			case 'JAM': return 'RFC';
			case 'DOM': return 'Cedula'; 
			case 'COL': return 'RFC';
			case 'PER': return 'RFC';
			case 'LCA': return 'RFC';
			case 'GRD': return 'RFC';
			default: return 'RFC';
		}
	}
	function searchWorker(data){
		cleanFields(data);
    const { nombre, paterno, materno, rfc, curp, nss } = data;
		let token = localStorage.getItem("x-access-token");
		setLoading(true)
		fetch(urlAPI.url + `findWorkerOnLeave/?param1=${nombre}&param2=${paterno}&param3=${materno}&param4=${rfc}&param5=${nss}&param6=${curp}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"x-access-token": token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
		if (json.status) {
			if((json.data).length !== 0){set_worker(json.data[0])}
			else{
				setColorMessage('warning');
				setRespMessage('No se encontro el trabajador');
				set_worker({})
			}
		}
		setLoading(false)
		});
	}

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function change_status(){
		let token = localStorage.getItem("x-access-token");
		let body = {
			'idMovementWorker':_idMovementWorker,
			'reentry':true,
			'cause':_message
		}
		setLoading(true)
		fetch(urlAPI.url + 'findWorkerOnLeave/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(body),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			set_message('');
			closeModal();
			setLoading(false);
		}).finally(()=>{
			searchWorker({
				nombre:_name,
				paterno:_firstSurname,
				materno:_secondSurname,
				rfc:_rfc,
				nss:_nss,
				curp:_curp,});
		});
	}

	function deleteAutorization(){
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + `findWorkerOnLeave/${_idAutorization}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			closeModalDelete();
			setLoading(false);
		}).finally(()=>{
			searchWorker({
				nombre:_name,
				paterno:_firstSurname,
				materno:_secondSurname,
				rfc:_rfc,
				nss:_nss,
				curp:_curp,});
		});
	}
  const onSearchRFC = (value) => {
		let find = {
			nombre:'',
			paterno:'',
			materno:'',
			rfc:value,
			nss:'',
			curp:'',
		}
		if(!value){cleanFields(find);set_worker({}); return};
		searchWorker(find);
	};

  const onSearchCURP = (value) => {
		let find = {
			nombre:'',
			paterno:'',
			materno:'',
			rfc:'',
			nss:'',
			curp:value,
		}
		if(!value){cleanFields(find);set_worker({}); return};
		searchWorker(find);
	};

  const onSearchNSS = (value) => {
		let find = {
			nombre:'',
			paterno:'',
			materno:'',
			rfc:'',
			nss:value,
			curp:'',
		}
		if(!value){cleanFields(find);set_worker({}); return};
		searchWorker(find);
	};

	function cleanFields(fields){
		set_name(fields.nombre)
		set_firstSurname(fields.paterno)
		set_secondSurname(fields.materno)
		set_rfc(fields.rfc)
		set_curp(fields.curp)
		set_nss(fields.nss)
	}

	const onButtonSearch = () => {
		if( !_name )return;
    if( !_firstSurname && !_secondSurname) return;
		let find = {
			nombre:_name,
			paterno:_firstSurname,
			materno:_secondSurname,
			rfc:'',
			nss:'',
			curp:'',
		}
		searchWorker(find);
	}

	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])

    const alertMessage = (type) =>{
		switch(type){
		  case 'success': return 'Correcto';
		  case 'warning': return 'Advertencia';
		  case 'error': return 'Error';
		}
	}
	
	function openNotification () {
		api[colorMessage]({
		message: alertMessage(colorMessage),
		description: respMessage,
		placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

	function mensajeHandler(e) {
		set_message(e.target.value.toUpperCase())
	}

	const openModal = (data) => {
		set_idMovementWorker(data.idMovementWorker)
		set_message(data?.comment)
		setShowModalStatus(true);
	}

	const openModalDelete = (data) => {
		set_idAutorization(data.autorization)
		setShowModalDelete(true);
	}
	
	const closeModal = () => setShowModalStatus(false);
	const closeModalDelete = () => setShowModalDelete(false);

	return (
		<div className='w-full flex flex-col fle-wrap gap-5'>
		{contextHolder}
		{findUserRights('61') ? 
			<Spin spinning={loading} size='large'>	
			{<MainMenu activeItem={'workers'} />}
			<div className='w-full flex flex-col gap-2 justify-center px-4'>
				<Card title="Buscar" className='flex flex-col flex-wrap 
				3xl:items-start 2xl:items-start xl:items-start lg:items-start md:items-start sm:items-center xs:items-center items-center
				3xl:justify-end 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center xs:justify-center justify-center'>
					<div className='flex gap-2 flex-wrap w-full
					3xl:justify-end 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center xs:justify-center justify-center
					3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col'>
						<div className='flex gap-2 flex-wrap 
						3xl:justify-end 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center xs:justify-center justify-center
						3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col'>
							<Input
								placeholder="Nombre"
								name='name'
								onChange={name}
								value={_name}
								status={_name ? '' : 'error'}
								style={{
									width: 250,
								}}
							/>
							<Input
								placeholder="Apellido paterno"
								name='firstSurname'
								onChange={firstSurname}
								value={_firstSurname}
								style={{
									width: 250,
								}}
							/>
							<Input
								placeholder="Apellido materno"
								name='secondSurname'
								onChange={secondSurname}
								value={_secondSurname}
								style={{
									width: 250,
								}}
							/>
							<Button type="primary" icon={<SearchOutlined />} disabled={!_name || (!_firstSurname && !_secondSurname) } className='bg-[#1677ff] max-w-[250px]' onClick={onButtonSearch}>Buscar</Button>
						</div>
						<div className='flex gap-2 flex-wrap 
						3xl:justify-end 2xl:justify-end xl:justify-end lg:justify-end md:justify-end sm:justify-center xs:justify-center justify-center
						3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col'>
							<Search
								placeholder={checkConfig(localStorage.getItem('country'))}
								name="rfc"
								onSearch={onSearchRFC}
								onChange={rfc}
								value={_rfc}
								style={{
									width: 250,
								}}
							/>
							{_countryType === 'DOM' ? '' :
								<Search
									placeholder="CURP"
									name="curp"
									onSearch={onSearchCURP}
									onChange={curp}
									value={_curp}
									style={{
										width: 250,
									}}
								/>
							}
							{_countryType === 'DOM' ? '' :
								<Search
									placeholder="NSS"
									name="nss"
									onSearch={onSearchNSS}
									onChange={nss}
									value={_nss}
									style={{
										width: 250,
									}}
								/>
							}
						</div>
					</div>
				</Card>
				<div className="flex 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
					<Worker data={_worker} country={_countryType} openModal={openModal} openModalDelete={openModalDelete} />
				</div>
			</div>
			</Spin> 
			: 	<NotPermission />
		}

		<Modal
		title={'Autorizar reingreso'}
		open={showModalStatus} 
		onOk={change_status} 
		onCancel={closeModal} 
		cancelText='Cancelar'
		okText='Guardar'
		okButtonProps={{className:'bg-[#1677ff]'}}
	
		>
		<Card className="flex flex-col gap-4">
			<div className='flex flex-col gap-3 mb-2'>
				<label>Motivo del cambio</label>
				<Input.TextArea
					placeholder="El colaborador se compromete a ..."
					autoSize={{ minRows: 3, maxRows: 6 }}
					onChange={(e) => mensajeHandler(e)}
					maxLength={600}
					value={_message}
				/>
			</div>
		</Card>
		</Modal>

		<Modal
		title={'Eliminar autorización de reingreso'}
		open={showModalDelete} 
		onOk={deleteAutorization} 
		onCancel={closeModalDelete} 
		cancelText='Cancelar'
		okText='Eliminar'
		okButtonProps={{className:'bg-[#ff4d4f]'}}
		>
		</Modal>
		</div>
	);
}
export default React.memo(Workers); 
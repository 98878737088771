
import React, { useEffect, useState } from "react";
import { Card, Button, Table, Switch, Tooltip, Drawer, Popconfirm, Select, Input, Dropdown } from "antd";
import { ApiOutlined, AppstoreAddOutlined, CalendarOutlined, DeleteOutlined, DownCircleOutlined, EllipsisOutlined, MessageOutlined, PlusSquareOutlined, UpCircleOutlined } from "@ant-design/icons";
import UiButton from '../../@ui/Button'
import $ from 'jquery'
function  DrawerConfronta(props){

    const [rights, setRights] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [RFC, setRFC] = useState('');
    const [_showAddMsj, set_showAddMsj] = useState(false);

    useEffect(() =>{
      let stringUserRights = localStorage.getItem('userRights');
      let userRights = JSON.parse(stringUserRights);
      setRights(userRights);
    },[])

    function hanlderConfirm(idMessage, blockeade){
      props.data.drawerMsg.assingMsg(idMessage,blockeade);
      set_showAddMsj(false);
    }

    const columnsRFCs = [
      {
        title: "RFC",
        dataIndex: "RFC",
        width:50,
				ellipsis: true,
      },
      {
        title:'Activo',
				ellipsis: true,
        children: [
          {
            title: () => {
              return( 
                <div className="flex flex-col gap-2">
                  <label className="">Timbrasoft</label>
                  <div className="flex">
                    <Switch 
                      checkedChildren="Activado" 
                      unCheckedChildren="Desactivado" 
                      className="bg-slate-400 flex" 
                      checked={props.data.switch._timbraActiveAll}
                      onChange={props.data.switch.toggleTimbraActiveAll}
                    />
                  </div>
                </div>
              )
            },
            align:'center',
            width:80,
            dataIndex: "activeTimbra",
            key: "activeTimbra",
            ellipsis: true,
            render: (x,propsAction) => {
              return (
              <div className="flex gap-2 
              3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col flex-col">
                <div>
                  {props.data.findUserRights("58") ? (
                    <Switch
                    className="bg-slate-400"
                    checked={ x }
                    onChange={(check)=>{
                      props.data.activoTimbra.toggleTimbraActiveRow(
                        propsAction,
                        check
                        )
                      }}
                      />
                      ) : null}
                </div>
                <div>
                  <Button
                      className={`flex justify-center items-center  ${propsAction.idMessage === 0 ? '' : 'bg-green-300'}`}
                      disabled={!x}
                      onClick={(val)=>{ 
                        props.data.drawerMsg.modalMsgHandler(propsAction.idconfrontBranchRFC, propsAction.idMessage, propsAction.blockade)
                      }}
                  >
                      <MessageOutlined className={`text-xl`}/>
                  </Button>
                </div>
              </div>
              );
            },
          },
          {
            title: () => {
              return( 
                <div className="flex flex-col gap-2">
                  <label className="">Confronta</label>
                  <div className="flex">
                    <Switch 
                      checkedChildren="Activado" 
                      unCheckedChildren="Desactivado" 
                      className="bg-slate-400 flex" 
                      checked={props.data.switch._confrontActiveAll}
                      onChange={props.data.switch.toggleConfrontActiveAll}
                    />
                  </div>
                </div>
              )
            },
            align:'center',
            width:80,
            dataIndex: "active",
            key: "active",
            ellipsis: true,
            render: (x,propsAction) => {
              return (
                <div>
                  {props.data.findUserRights("58") ? (
                    <Switch
                      className="bg-slate-400"
                      checked={ x }
                      onChange={(check)=>{
                          props.data.activo.toggleConfrontActiveRow(
                          propsAction,
                          check
                        )
                      }}
                    />
                  ) : null}
                </div>
              );
            },
          }
        ]
      },
      {
        title: "Acción",
        align:'center',
        width:50,
				ellipsis: true,
        render: (propsAction) => {
          return (
            <div className="flex justify-center">
              {props.data.findUserRights("58") ? (
                <Tooltip placement="top" title={'Accesos'}>
                  <Button
                      className="flex justify-center items-center"
                      disabled={!propsAction.active}
                      onClick={() => {
                        props.data.showYearMonths(
                          propsAction.idconfrontBranchRFC,
                          propsAction.RFC,
                          propsAction.active
                          )}
                      }
                  >
                      <CalendarOutlined className="text-xl"/>
                  </Button>
                </Tooltip>
              ) : null}
            </div>
          );
        },
				responsive: ['xxl', 'xl', 'lg', 'md'],
      },
      {
        title: "Desligar",
        align:'center',
        width:50,
				ellipsis: true,
        render: (propsAction) => {
          return (
            <div className="flex justify-center">
              {props.data.findUserRights("58") ? (
                <Popconfirm
                title="Desligar"
                description={'¿Desea delsligar el RFC  "'+ propsAction.RFC +'" ?'}
                onConfirm={() => {
                  props.data.drawerRFC.unassignRFC(propsAction.idRelBranchRFC);
                }}
                onCancel={()=>{}}
                okText="Eliminar"
                cancelText="Cancelar"
                okButtonProps={{className:'bg-[#1677ff]'}}
                disabled={propsAction.active || propsAction.activeTimbra}
              >
                  <Button
                      disabled={propsAction.active || propsAction.activeTimbra}
                      className="flex justify-center items-center"
                  >
                      <ApiOutlined className="text-xl"/>
                  </Button>
                </Popconfirm>
              ) : null}
            </div>
          );
        },
				responsive: ['xxl', 'xl', 'lg', 'md'],
      },
      {
        title: "Acciones",
        align:"center",
        width:50,
				ellipsis: true,
				responsive: ['xs'],
        render: (propsAction) => {
          return (
            <Dropdown 
            menu={{
                items: [
                findUserRights("58") ? ({
                  label: 'Accesos',
                  key: 'Access',
                  icon:<CalendarOutlined className="text-xl"/>,
                  disabled:!propsAction.active,
                  onClick:()=>{
                    props.data.showYearMonths(
                    propsAction.idconfrontBranchRFC,
                    propsAction.RFC,
                    propsAction.active
                  )}
                }):null,
                findUserRights("58") ? ({
                  label: 
                    <Popconfirm
                      title="Desligar"
                      description={'¿Desea delsligar el RFC  "'+ propsAction.RFC +'" ?'}
                      onConfirm={() => {
                        props.data.drawerRFC.unassignRFC(propsAction.idRelBranchRFC);
                      }}
                      onCancel={()=>{}}
                      okText="Eliminar"
                      cancelText="Cancelar"
                      okButtonProps={{className:'bg-[#1677ff]'}}
                      disabled={propsAction.active || propsAction.activeTimbra}
                    >
                    <Button className="flex justify-center items-center bg-red-200 w-full" >
                      <ApiOutlined className="text-xl"/> Desligar
                    </Button> 
                    </Popconfirm>,
                  key: 'Desligar',
                  danger: true,
                }):null,
              ]
              }}>
                <EllipsisOutlined />
              </Dropdown>
              )
        }
      },
    ];   

    const columnsYearsMonths = [
      {
        title: "Año",
        dataIndex: "year",
        align:'right',
      },
      {
        title: "Acción",
        align: 'center',
        render: (propsRender) => {
          return (
            <div>

              {/* Agregar nuevo permiso eliminar los años y meses */}
              {props.data.findUserRights("58") ? (
                <Tooltip placement="top" title={'Eliminar'} >
                <Popconfirm
                  title="Eliminar"
                  description={'¿Desea eliminar  "'+ propsRender.year +'" ?'}
                  onConfirm={() => {
                    props.data.drawer.deleteYearMonth(propsRender.idYearMonth, propsRender.idconfrontBranchRFC);
                  }}
                  onCancel={()=>{}}
                  okText="Eliminar"
                  cancelText="Cancelar"
                  okButtonProps={{className:'bg-[#1677ff]'}}
                >
                  <Button className="">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
    ];

    const year = new Date().getFullYear();
    const optionsYear = [];

    for (var i = 2020; i < year + 1; i++) {
      optionsYear.push({ value: i, label: i });
    }

    const columsNotAssigned = [
      {
        title:'RFC',
        dataIndex: "RFC",
      },
      {
        title: "Asignar",
        align: 'center',
        render: (propsRender) => {
          return (
            <div>
              {props.data.findUserRights("58") ? (
                <Tooltip placement="top" title={'Asignar'} >
                  <Button className="" 
                    onClick={()=>{props.data.drawerRFC.assingRFC(propsRender.idRFC); set_showAddMsj(false)}}
                  >
                    <AppstoreAddOutlined />
                  </Button>
                </Tooltip>
              ) : (
                <Button className="opacity-.5">
                    <AppstoreAddOutlined />
                  </Button>
              )}
            </div>
          );
        },
      },
    ]

    const columnasMsg = [
			{
				title: 'Mensaje',
				dataIndex: 'message',
        with:500
			},
			{
				title: 'Bloqueo',
				dataIndex: 'blockade',
				width:40,
				className:'text-center ',
				render: (props) => props ? 'SI' : 'NO',
			},
			{
				title: 'Ligar',
				align:'center',
				width:80,
				className:'text-center ',
        render: (text, record, index ) => {
          return (
            <div className='flex justify-center items-center'>
              <Popconfirm 
                title='¿ Bloquear RFC al ligar mensaje ?'
                onConfirm={()=>hanlderConfirm(record.idMessage, true)}
                onCancel={()=>hanlderConfirm(record.idMessage, false)}
                okText='Si'
                cancelText='No'
                overlayClassName='text-ellipsis text-xs w-[350px] max-h-[150px]'
                okButtonProps={{'className':'bg-[#1677ff]'}}
              >
                  <Button className="flex justify-center items-center bg-blue-200" >
                    <AppstoreAddOutlined className='text-xl'/>
                  </Button>
              </Popconfirm>
            </div>
          );
        }
			},
		];

    const columnasSelMsg = [
			{
				title: 'Mensaje',
				dataIndex: 'message',
        with:500
			},
			{
				title: 'Bloqueo',
				width:40,
				className:'text-center ',
        render: (propsAction) => {
          return (
            <div className='flex gap-2'>  
              <Switch
                className="bg-slate-400"
                checked={ props.data.drawerMsg._statusAsociateMsg }
                onChange={(check)=>{
                  props.data.drawerMsg.blockadeMSg(check,propsAction.idMessage);
                }}
              />
            </div>
          );
        },
			},
			{
				title: 'Desligar',
				align:'center',
				width:80,
				className:'text-center ',
        render: (propsAction) => {
          return (
            <div className='flex gap-2'>  
                <Popconfirm 
                  title='¿ Desligar el mensaje ?'
                  onConfirm={props.data.drawerMsg.unassignMsg }
                  okText='Desligar'
                  cancelText='Cancelar'
                  overlayClassName='text-ellipsis text-xs w-[350px] max-h-[150px]'
                  okButtonProps={{'className':'bg-[#1677ff]'}}
                >
                  <Button className="flex justify-center items-center p-2 bg-red-200" >
                    <ApiOutlined className='text-lg'/>
                  </Button>
                </Popconfirm>
            </div>
          );
        },
			},
		];

    let findUserRights = (findRight) => {
      let userRights = rights;
  
      for (let i in userRights) {
        let x = userRights[i].idOperation;
  
        if (x == findRight) {
          return true;
        }
      }
    };

    let handlerAdd = () =>{ setShowAdd(true) }
    let rfcHandler = (e) =>{e.target.value = e.target.value.toUpperCase(); setRFC(e.target.value.toUpperCase());}
    let handlerClean = () => {setRFC(''); setShowAdd(false);}
    
    return (
        <Drawer 
          title={props.title} 
          open={props.open} 
          onClose={props.onClose}
          width={'80%'}
        >
            <Card title="" 
              className="w-full flex justify-center">
                <Table
						        bordered={true}
                    columns={columnsRFCs}
                    dataSource={props.dataSource}
                    className="headerTable drawerConfront max-w-[900px]"
                    rowKey={(r,i) => 'drawerConfront_'+i}
                    onRow={(record,i)=>{
                        return{
                            onClick:(event) => {
                                $('.drawerConfront tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                                $(`[data-row-key="drawerConfront_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                            }
                        }
                    }}	
                    size="small"
                />

                <div className="m-2 flex  gap-2">
                    <Button
                      onClick={props.data.drawerRFC.viewNotAssigned}
                      className="bg-[#1677ff] text-white"
                    >Agregar RFC</Button>
                </div>
            </Card>

            <Drawer 
              open={props.data.drawer.open}
              title={props.data.drawer.title} 
              onClose={props.data.drawer.onClose}
            >
              <Card>
                <div className="w-full flex flex-col gap-2 m-2">
                  <label>Agregar año</label>
                  <div className="w-full flex gap-2">
                    <Select
                      options={optionsYear}
                      disabled={props.data.drawer.select.disabled}
                      value={props.data.drawer.select.value}
                      onChange={props.data.drawer.select.onChange}
                      className="w-3/4"
                      />
                    <Button
                      onClick={props.data.drawer.btnNew.onClick}
                      disabled={!props.data.drawer.btnNew.disabled}
                      className="w-1/4 bg-[#1677ff] text-white"
                    >Nuevo</Button>
                  </div>
                  <Button
                      onClick={props.data.drawer.btnSave.onClick}
                      disabled={!props.data.drawer.select.value || props.data.drawer.btnSave.disabled }
                      className="w-1/4 bg-[#1677ff] text-white"
                  > Guardar </Button>
                </div>
                <Table 
						      bordered={true}
                  columns={columnsYearsMonths}
                  dataSource={props.data.drawer.dataSource}  
                  rowKey={(r,i) => 'confrontYear_'+i}
                  className="headerTable confrontYear"
                  size='small'
                  onRow={(record,i)=>{
                      return{
                          onClick:(event) => {
                              $('.confrontYear tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                              $(`[data-row-key="confrontYear_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                          }
                      }
                  }}	
                />
              </Card>

            </Drawer>
            
            <Drawer
              open={props.data.drawerRFC.open}
              title={props.data.drawerRFC.title} 
              onClose={props.data.drawerRFC.onClose}
            >
              <Card>
                <Table 
						        bordered={true}
                    columns={columsNotAssigned}
                    dataSource={props.data.drawerRFC.dataSource}
                    className="w-full headerTable drawerRFCNotAssigned"
                    rowKey={(r,i) => 'drawerRFCNotAssigned_'+i}
                    onRow={(record,i)=>{
                        return{
                            onClick:(event) => {
                                $('.drawerRFCNotAssigned tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                                $(`[data-row-key="drawerRFCNotAssigned_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                            }
                        }
                    }}	
                    size="small"
                />
              </Card>
              {!showAdd ? (<div className="row padding-btn-agregar margin-agregar-msn">
                {findUserRights('3') ? (
                  <UiButton
                    className='bg-[#1677ff] flex justify-center items-center'
                    type='primary'
                    icon={<PlusSquareOutlined className='text-lg'/>}
                    onClick={handlerAdd}
                    text="Agregar RFC"
                  />
                ) : (
                  <UiButton
                    className='bg-[#1677ff] flex justify-center items-center'
                    type='primary'
                    icon={<PlusSquareOutlined className='text-lg'/>}
                    disabled={true}
                    text="Agregar RFC"
                  />
                )}
              </div>):''}
              {showAdd ?
              (<Card>
                  <div className="flex gap-2">
                    <Input 
                      className="border-blue-600"
											name='RFC'
											placeholder="RFC"
											maxLength="13"
                      value={RFC}
											onChange={(e)=>{rfcHandler(e)}}
                    />
                    <UiButton 
                      className='bg-[#1677ff] flex justify-center items-center'
                      type='primary'
                      icon={<PlusSquareOutlined className='text-lg'/>}
                      onClick={()=>{
                        props.data.drawerRFC.addRFC(RFC);
                        handlerClean();
                      }}
                      text="Guardar"
                    />
                  </div>
                </Card>):''
              }
            </Drawer>

            <Drawer
              open={props.data.drawerMsg.open}
              title={props.data.drawerMsg.title} 
              onClose={props.data.drawerMsg.onClose}
              width={'auto'}
              className=" 3xl:min-w-[750px] 2xl:min-w-[750px] xl:min-w-[750px] lg:min-w-[550px] md:min-w-[200px] 
              sm:min-w-[200px] xs:min-w-[200px] min-w-[200px]"
            >
              <Card title="Mensaje asociado" size="small">
                <div className="flex flex-col m-2 gap-4">
                  <Table
                    bordered={true}
                    columns={columnasSelMsg}
                    dataSource={props.data.drawerMsg._asociateMsg}
                    className='max-w-[850px] headerTable selMessageRFC'
                    size='small'
                    pagination={false}
                    rowKey={(r, i) => 'selMsgRFC_'+i}	
                    locale={{emptyText:"No tiene un mensaje ligado"}}
                    footer={()=>{
                      return(
                        <div>
                            {_showAddMsj ? <Table
                              bordered={true}
                              columns={columnasMsg}
                              dataSource={props.data.drawerMsg.dataSource}
                              className={`max-w-[850px] headerTable messageRFC`}
                              size='small'
                              rowKey={(r, i) => 'msgRFC_'+i}
                              onRow={(record,i)=>{
                                return{
                                  onClick:(event) => {
                                    $('.messageRFC tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                                    $(`[data-row-key="msgRFC_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                                  }
                                }
                              }}	
                            /> : ''}
                          <Button onClick={()=> _showAddMsj ? set_showAddMsj(false) : set_showAddMsj(true)} className="flex justify-center items-center">
                            {_showAddMsj ? 'Ocutar mensajes' : (props.data.drawerMsg._asociateMsg).length === 0 ? 'Agregar mensaje' : 'Cambiar mensaje'}
                            {_showAddMsj ? <UpCircleOutlined className="text-md"/> : <DownCircleOutlined className="text-md"/>}
                          </Button>
                        </div>
                      )
                    }}
                  />

                </div>
              </Card>
            </Drawer>

        </Drawer>
    );
}
export default React.memo(DrawerConfronta)
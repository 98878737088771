import React, { useState, useEffect, useRef } from 'react';
import './MessagesAdmin.css';
import '../MainStyle.css';
import { Button, Badge, Popconfirm, Modal, Input, notification, Tooltip, Table, Card, Switch, Space, Spin, Tag, Select } from "antd";
import { EditOutlined, PlusSquareOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'; 
import urlAPI from '../urlAPI/urlAPI.json';
import Highlighter from 'react-highlight-words';
import $ from 'jquery'

function MessagesTable(){

	const [itemsMessages, setItemsMessages] = useState([]);

	const [openModEditar, setOpenModEditar] = useState(false);
	const [openModEliminar, setOpenModEliminar] = useState(false);
	const [openModNuevo, setOpenModNuevo] = useState(false);

	const [_idMensaje, set_idMensaje] = useState('');
	const [_message, set_message] = useState('');
	const [_messageEn, set_messageEn] = useState('');
	const [_bloqueo, set_bloqueo] = useState(true);
  const [_typeMsg, set_typeMsg] = useState('0');

	// VARIABLES PARA MOSTRAR MENSAJES
	const [existMessage, setExistMessage] = useState(false);
	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');

	const [classMessage, setClassMessage] = useState(0);

	//PERMISOS DE USUARIO
	const [rights, setRights] = useState([]); 
	const [isEdit, setIsEdit] = useState(false);

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [loading, setLoading] = useState(false);

	function editarMensajeModal(props) {
		set_idMensaje(props.idMessage);
		set_message(props.message);
		set_messageEn(props.messageEn);
		set_bloqueo(props.blockade);
    set_typeMsg(props.typeMessage);
		setIsEdit(true);
		showNuevo();
	}

	function mensajeHandler(e) {
		set_message(e.target.value.toUpperCase())
	}

	function mjsIngHandler(e) {
		set_messageEn(e.target.value.toUpperCase())
	}

	const toggle = (checked) => {set_bloqueo(checked);}

  const typeHandler = (selected) => set_typeMsg(selected);

  const typeOption = [
    { label: "Abrhilsoft", value: '0' },
    { label: "Timbrasoft", value: '1' }
  ]
	useEffect(() => {loadMessages()},[])

	function loadMessages(){

		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'messages/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsMessages(json.data[0])
			} 
			setLoading(false)
		});
	}

	function agregarMensaje() {
		if(_message === '' )return
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevoMensaje = {
			idMessage: isEdit ? _idMensaje : '',
			message: _message,
			messageEn: _messageEn,
			blockade: blockingTrueFalse(_bloqueo),
			userCap: userLogged,
      typeMessage:_typeMsg
		};
		setLoading(true)
		fetch(urlAPI.url + 'messages/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevoMensaje),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadMessages();
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			closeNuevo();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function eliminarMensaje(idMensaje) {
		
		let id = idMensaje;
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + `messages/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadMessages();
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			//closeEliminar();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function limpiaCampos() {
		set_idMensaje('');
		set_message('');
		set_messageEn('');
		set_bloqueo(true);
    set_typeMsg('0');
		setIsEdit(false);
	}

	function showNuevo() {
		setOpenModNuevo(true)
	}

	function closeNuevo() {
		setOpenModNuevo(false)
		setIsEdit(false);
		limpiaCampos()
	};

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function transitionMsn(){
		setTimeout(() => {
			setClassMessage(1);
		}, 4000);
	};

	function limpiarMensaje(){
		setTimeout(() => {
			setExistMessage(false);
			setRespMessage('')
			setColorMessage('')
			setClassMessage(0)
		}, 6000);
	};

	function blockingTrueFalse(campo){
		switch (campo) {
			case false: {
				return 0;
			}
			case true: {
				return 1;
			}
			default: {
				return 'N/A';
			}
		}
	};


	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
	clearFilters();
	setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Input
			ref={searchInput}
			placeholder={`Buscar ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ marginBottom: 8, display: 'block', }}
			/>
			<Space>
			<Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
			>
				Buscar
			</Button>
			<Button
				onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				size="small"
				style={{
				width: 90,
				}}
			>
				Borrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				confirm({
					closeDropdown: false,
				});
				setSearchText(selectedKeys[0]);
				setSearchedColumn(dataIndex);
				}}
			>
				Filtrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				close();
				}}
			>
				Cerrar
			</Button>
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
		filtered ?
		(<Badge className="animate-pulse" dot>
			<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>
		</Badge>):(<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>)
		),
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});
  
	
		const columnas = [
			{
				title: 'Mensaje',
				dataIndex: 'message',
				ellipsis: true,
				...getColumnSearchProps('message')
			},
			{
				title: 'Tipo',
				dataIndex: 'typeMessage',
				width:120,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
				render: (props) => {
					let color, type;
					switch(props){ 
						case '0': color='cyan'; type='Abrhilsoft'; break; 
						case '1': color='magenta'; type='Timbrasoft'; break; 
						default: color='cyan'; type='Abrhilsoft'; break; 
					}
					return(
						<Tag color={color} key={props}>
							{type.toUpperCase()}
						</Tag>
					)
				},
			},
			{
				title: 'Bloqueo',
				dataIndex: 'blockade',
				className:'text-center min-w-[50px] max-w-[80px]',
				width:100,
				ellipsis: true,
				responsive: ['xxl', 'xl', 'lg'],
				render: (props) => {
					return props ? 'SI' : 'NO'

				},
			},
			{
				title: 'Acción',
				align:'center',
				className:'text-center min-w-[100px] max-w-[100px]',
				ellipsis: true,
				width:100,
				render: (props) => {
					return (
						<div className='flex gap-2'>
							{findUserRights('42') ? (
							<Tooltip placement="top" title={'Editar'} >
								<Button
									className="flex justify-center items-center bg-blue-200 p-2"
									onClick={() => {
										editarMensajeModal(props);
									}}
								>
									<EditOutlined />
								</Button>
							</Tooltip>
							) : null}

							{findUserRights('43') ? (
								<Tooltip placement="top" title={'Eliminar'} >
								<Popconfirm
									title="Borrar mensaje"
									description={'¿Desea eliminar el mensaje?'}
									onConfirm={() => {
										eliminarMensaje(props.idMessage);
									}}
									onCancel={()=>{}}
									okText="Eliminar"
									cancelText="Cancelar"
									okButtonProps={{className:'bg-[#1677ff]'}}
								>
									<Button className="flex justify-center items-center bg-red-200 p-2">
										<DeleteOutlined />
									</Button>
								</Popconfirm>
								</Tooltip>
							) : null}
						</div>
					);
				},
			},
		];

		let findUserRights = (findRight) => {
			let userRights = rights;

			for (let i in userRights) {
				let x = userRights[i].idOperation;

				if (x == findRight) {
					return true;
				}
			}
		};

		const alertMessage = (type) =>{
			switch(type){
			  case 'success': return 'Correcto';
			  case 'warning': return 'Advertencia';
			  case 'error': return 'Error';
			}
		  }

		//Alert
		const [api, contextHolder] = notification.useNotification();
		useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])
		function openNotification () {
			api[colorMessage]({
			message: alertMessage(colorMessage),
			description: respMessage,
			placement:'bottomRight',
			});
			setColorMessage('')
			setRespMessage('')
		}
		const colEn=[{
			title: 'Mensaje en Ingles',
			dataIndex: 'messageEn',
		}]
		return (
			<div className='flex justify-center'>
			{contextHolder}
			<Spin spinning={loading} size='large'>
				<Table
					bordered={true}
					columns={columnas}
					expandable={{
						expandedRowRender: (record) => (
							(<div className='flex justify-center gap-4 p-2 max-w-[1200px] border-2 border-blue-500 
							3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[800px] md:min-w-[500px] sm:min-w-[250px] xs:min-w-[250px] min-w-[250px]'>
								<div className='w-1/5 text-sm break-words'>
									<label className='text-sm break-words'>Msj en ingles:</label>
								</div>
								<div className='w-4/5'>
									{record.messageEn}
								</div>
							</div>)
						),
						rowExpandable: (record) => record.messageEn !== 'No hay mensajes',
					}}
					dataSource={itemsMessages}
					className='max-w-[1300px] headerTable messageAdmin flex 
					3xl:min-w-[1500px] 2xl:min-w-[1400px] xl:min-w-[1000px] lg:min-w-[800px] md:min-w-[500px] sm:min-w-[300px] xs:min-w-[300px] min-w-[300px]'
					size='small'
					rowKey={(r, i) => 'msjAdmin_'+i}
					onRow={(record,i)=>{
						return{
							onClick:(event) => {
								$('.messageAdmin tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
								$(`[data-row-key="msjAdmin_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
							}
						}
					}}	
				/>

				<div className="row padding-btn-agregar">
					{findUserRights('41') ? (
						<Button 
						icon={<PlusSquareOutlined className='text-xl'/>}
						onClick={()=>{ setIsEdit(false); showNuevo()}}
						type='primary'
						className='bg-[#1677ff] flex justify-center items-center'
						>
						Nuevo mensaje
						</Button>
					) : null}
				</div>
				<Modal
				title={isEdit? 'Editar mensaje' : 'Nuevo mensaje'}
				open={openModNuevo} 
				onOk={agregarMensaje} 
				onCancel={closeNuevo} 
				cancelText='Cancelar'
				okText='Guardar'
				okButtonProps={{className:'bg-[#1677ff]'}}
			
				>
				<Card className="flex flex-col gap-4">
					<div className='flex flex-col gap-3 mb-2'>
						<label>Mensaje</label>
						<Input.TextArea
							placeholder="Agrega un mensaje"
							autoSize={{ minRows: 3, maxRows: 6 }}
							onChange={(e) => mensajeHandler(e)}
							maxLength={600}
							value={_message}
						/>
					</div>

					<div className='flex flex-col gap-3 mb-2'>
						<label>Mensaje en ingles</label>
						<Input.TextArea
							placeholder="Agrega un mensaje"
							autoSize={{ minRows: 3, maxRows: 6 }}
							onChange={(e) => mjsIngHandler(e)}
							maxLength={600}
							value={_messageEn}
						/>
					</div>

          <div className='flex gap-2'>
					<div className='flex gap-2'>
						<label>Bloqueo</label>
						<Switch onChange={toggle} checked={_bloqueo} className='bg-slate-200'/>
					</div>

					<div className='flex gap-2'>
						<label>Tipo de mensaje:</label>
						<Select 
							options={typeOption} 
							onChange={typeHandler} 
							value={_typeMsg} 
							className=''
						/>
					</div>

          </div>
				</Card>
				</Modal>
			</Spin>
			</div>
		);
}

export default  React.memo(MessagesTable)

import React from "react";
import { Card, Modal, Button, Table } from "antd";
import dayjs from "dayjs";
import $ from 'jquery'

function  ModalHist(props){

    function validLock(campo){
        switch (campo) {
          case 0: {
            return "No bloquear";
          }
          case 1: {
            return "RRHH";
          }
          case 2: {
            return "Nómina";
          }
          case 3: {
            return "Todo";
          }
          case 4: {
            return "Mod. Admin.";
          }
          default: {
            return "N/A";
          }
        }
    }

    const columnsBranchMov = [
        {
          title: "Inicio Contrato",
          dataIndex: "startDate",
          width:100,
          ellipsis: true,
          render: (propsCol) => {
            return dayjs(propsCol).format("DD/MM/YYYY");
          },
          responsive: ['xxl', 'xl', 'lg'],
        },
        {
          title: "Fin Contrato",
          dataIndex: "endDate",
          width:100,
          ellipsis: true,
          render: (propsCol) => {
            return dayjs(propsCol).format("DD/MM/YYYY");
          },
          responsive: ['xxl', 'xl', 'lg'],
        },
        {
          title: "Max Emple Perm",
          dataIndex: "maxEmployees",
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
          width:100,
        },
        {
          title: "Bloquear",
          dataIndex: "lock",
          ellipsis: true,
          width:100,
          render: (propsCol) => {
            return validLock(propsCol);
          },
          responsive: ['xxl', 'xl', 'lg'],
        },
        {
          title: "Comentario",
          dataIndex: "comment",
          ellipsis: true,
        },
        {
          title: "Fecha Mov",
          dataIndex: "createDate",
          width:100,
          ellipsis: true,
          render: (propsCol) => {
            return dayjs(propsCol).format("DD/MM/YYYY");
          },
        },
        {
          title: "Folio HelpDesk",
          dataIndex: "folioHelpDesk",
          width:100,
          responsive: ['xxl', 'xl', 'lg'],
          ellipsis: true,
        },
    ]

  return (
    <Modal 
        title={props.title} 
        open={props.open} 
        onOk={props.onOk} 
        onCancel={props.onCancel}
        width={"80%"}
        footer={[
            <Button key="submit" type="primary" onClick={props.onOk} className="bg-[#1677ff]" >
            Cerrar
            </Button>
        ]}
    >
        <Card title=""
          className="w-full"
        >
            <Table
                bordered={true}
                columns={columnsBranchMov}
                dataSource={props.dataSource}
                className="w-full headerTable modalHist"
                rowKey={(r,i) => 'modalHist_'+i}
                size="small"
                onRow={(record,i)=>{
                    return{
                        onClick:(event) => {
                            $('.modalHist tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                            $(`[data-row-key="modalHist_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                        }
                    }
                }}	
            />
        </Card>
    </Modal>
  );
}

export default React.memo(ModalHist)

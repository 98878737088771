import React from 'react';
import { Button, Input, Space, Table, Card, Switch, Drawer, Descriptions } from "antd";
import { PlusSquareOutlined } from '@ant-design/icons';
import RangePicker from '../../@ui/DateRangerPicker'
import $ from 'jquery'
import dayjs from 'dayjs';

const MultiDrawer = (props) => {
    const { TextArea } = Input;
   return ( 
        <Drawer open={props.open} onClose={props.onClose} title={props.title} width={"80%"}>
                <div className='flex flex-col gap-2'>
                        <div>
                            <Card title="Lista de Mensajes" 
                            className='3xl:min-w-[500px] 2xl:min-w-[500px] xl:min-w-[500px] lg:min-w-[300px]' 
                            extra={
                                  props.card.onClick != '' ? ( <Button 
                                        icon={<PlusSquareOutlined className='text-xl'/>}
                                        onClick={props.card.onClick}
                                        type='primary'
                                        className='bg-[#1677ff] flex justify-center items-center'
                                        disabled={props.card.disabled}
                                    >
                                        Nuevo mensaje
                                    </Button>): <div></div>
                                } >
                                <div className=' flex flex-col gap-2'>
                                    <Table 
                                        bordered={true}
                                        className='headerTable catMessageMulti 3xl:min-w-[500px] 2xl:min-w-[500px] xl:min-w-[500px] lg:min-w-[300px]'
                                        columns={props.card.table.columns}
                                        dataSource={props.card.table.dataSource}
                                        size='small'
                                        rowKey={(r, i) => 'catMesMulti_'+i}
                                        onRow={(record,i)=>{
                                            return{
                                                onClick:(event) => {
                                                    $('.catMessageMulti tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
                                                    $(`[data-row-key="catMesMulti_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
                                                }
                                            }
                                        }}	
                                    />
                                </div>
                            </Card>
                        </div>
                    </div>

                <Drawer
                    title="Mensaje"
                    width={"75%"}
                    onClose={props.childDrawer.onClose}
                    open={props.childDrawer.open}
                    bodyStyle={{paddingBottom: 80}}
                    extra={
                        <Space className='flex flex-col'>
                            <Button onClick={props.childDrawer.cancel}>Cancelar</Button>
                            <Button onClick={props.childDrawer.save} type="primary" className='bg-[#1677ff] flex justify-center items-center'>
                            Guardar
                            </Button>
                        </Space>
                    }
                >
                    <div className=''>
                        <Card title={props.childDrawer.title}> 
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-2">
                                <label>Mensaje</label>
                                    {props.childDrawer.isEdit === false ?
                                    <TextArea
                                        className=""
                                        value={props.childDrawer.message}
                                        placeholder="Escriba el mensaje"
                                        onChange={(e) => props.childDrawer.messageHandler(e)}
                                        autoSize={{minRows: 2, maxRows: 6}}
                                    />:<Descriptions.Item>
                                            {props.childDrawer.message}
                                    </Descriptions.Item>
                                        }
                                </div>
                                
                                <div className='flex gap-4 items-end  3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col flex-col'>
                                    <RangePicker
                                        title={"Seleciona las fechas de inicio y fin"}
                                        placeholder={["Fecha inicio", "Fecha fin"]}
                                        format="DD/MM/YYYY"
                                        value={[dayjs(props.childDrawer.startDate), dayjs(props.childDrawer.endDate)]}
                                        onChange={(data)=>{
                                            if(data)props.childDrawer.rangeDays(data)
                                        }}
                                    />
                                    <div className="flex gap-4">
                                        <label className="font-titulo-fechas label-bloquear">Bloquear</label>
                                        <Switch
                                            className="bg-slate-200"
                                            checked={props.childDrawer.block}
                                            onChange={props.childDrawer.toggleBlock}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Drawer>
        </Drawer>
    );
}
export default React.memo(MultiDrawer)
import React from "react";
import { Checkbox } from "antd";

function CheckboxComponent(props){
    return(
        <Checkbox 
            checked = {props.checked ? props.checked : false}
            defaultChecked = {props.defaultChecked ? props.defaultChecked : false}
            disabled = {props.disabled ? props.disabled : false}
            onChange = {props.onChange ? props.onChange : null}
            value = {props.value ? props.value : null}
        />
    )
}
export default React.memo(CheckboxComponent)
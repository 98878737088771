import React from "react";
import { Button } from "antd";

function ButonComponent(props){
    
    return(
        <Button 
        block = {props.block ? props.block : false}
        className = {props.className ? props.className : ''}
        danger = {props.danger ? props.danger : false}
        disabled = {props.disabled ? props.disabled : false}
        icon = {props.icon ? props.icon : null}
        size = {props.size ? props.size : "middle"}
        type = {props.type ? props.type : "default"}
        onClick={props.onClick}
        >
          {props.text ? props.text : ''}  
        </Button>
    )
}

export default React.memo(ButonComponent);
import React, { useEffect, useState, useRef } from 'react';
import './CompaniesAdmin.css';
import '../MainStyle.css';
import Highlighter from 'react-highlight-words';
//import { Button, Header, Modal, Form, Icon, Input, Message, Label } from 'semantic-ui-react';
import { Button, Modal, Input, Tooltip, Popconfirm, notification, Space, Spin, Badge, Switch, Card } from "antd";
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { DeleteOutlined, PlusSquareOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Table from '../../@ui/Table';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'

function CompaniesTable () {

	const [itemsCompanies, setItemsCompanies] = useState([]);

	const [openModNuevo, setOpenModNuevo] = useState(false);
	const [_shared_info, set_shared_info] = useState(false);
	const [_idEmpresa, set_idEmpresa] = useState('');
	const [_codigoCiente, set_codigoCiente] = useState('');
	const [_descripcion, set_descripcion] = useState('');

	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');

	const [rights, setRights] = useState([]);
	const [isEdit, setIsEdit] = useState(false);

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [ loading, setLoading ] = useState(false);

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	function codigoClienteHandler(e) {
		set_codigoCiente((e.target.value).toUpperCase());
	}

	function descripcionHandler(e) {
		set_descripcion((e.target.value).toUpperCase());
	}

	useEffect(()=>{
		loadCompanies()
	},[])

	function loadCompanies(){
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights)

		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'companies/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === false) logout();
			if (json.status) {
				setItemsCompanies(json.data[0])
			}
			setLoading(false)
		});
	}

	function agregarEmpresa() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevaEmpresa = {
			idCompany: isEdit ? _idEmpresa : '',
			codeClient: _codigoCiente,
			descCompany: _descripcion,
			sharedInfo: _shared_info,
			userCap: userLogged,
		};

		if(_codigoCiente === '' || _descripcion === '')return
		setLoading(true)
		fetch(urlAPI.url + 'companies/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevaEmpresa),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			closeNuevo();
			limpiarMensaje();
			loadCompanies();
			setLoading(false)
		});
	}

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	// Eliminar
	function eliminarEmpresa(props) {
		
		let token = localStorage.getItem('x-access-token');
		let id = props.idCompany;
		setLoading(true)
		fetch(urlAPI.url + `companies/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			
			limpiarMensaje();
			loadCompanies();
			setLoading(false)
		});
	}

	function limpiaCampos() {
		set_idEmpresa('');
		set_codigoCiente('');
		set_descripcion('');
	}

	function showNuevo(){
		setIsEdit(false);
		limpiaCampos();
		setOpenModNuevo(true);
	}
	
	function closeNuevo(){
		setIsEdit(false);
		setOpenModNuevo(false); 
		limpiaCampos();	
	}

	useEffect(() => {},[_idEmpresa, _codigoCiente, _descripcion])
	function showEditar(props){
		set_idEmpresa(props.idCompany);
		set_codigoCiente(props.codeClient);
		set_descripcion(props.descCompany);
		set_shared_info(props.sharedInfo)
		setIsEdit(true);
		setOpenModNuevo(true);
	}

	function limpiarMensaje(){
		setTimeout(() => {
			setRespMessage('');
			setColorMessage('');
		}, 6000);
	};

	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
	clearFilters();
	setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Input
			ref={searchInput}
			placeholder={`Buscar ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ marginBottom: 8, display: 'block', }}
			/>
			<Space>
			<Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
			>
				Buscar
			</Button>
			<Button
				onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				size="small"
				style={{
				width: 90,
				}}
			>
				Borrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				confirm({
					closeDropdown: false,
				});
				setSearchText(selectedKeys[0]);
				setSearchedColumn(dataIndex);
				}}
			>
				Filtrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				close();
				}}
			>
				Cerrar
			</Button>
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
		filtered ?
		(<Badge className="animate-pulse" dot>
			<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>
		</Badge>):(<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>)
		),
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});

	const columnas = [
		{
			title: 'Empresa',
			dataIndex: 'codeClient',
			key: 'codeClient',
			className:'p-2 text-sm',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			...getColumnSearchProps('codeClient'),
		},
		{
			title: 'Descripción',
			dataIndex: 'descCompany',
			key: 'descCompany',
			className:'p-2 text-sm',
			ellipsis: true,
			...getColumnSearchProps('descCompany'),
		},
		{
			title: 'Acción',
			key: 'action',
			align:'center',
			className:'p-2',
			width:100,
			ellipsis: true,
			render: (props) => {
				return (
					<div className='flex gap-1'>
						{findUserRights('19') ? (
							<Tooltip placement="top" title={'Editar'} >
								<Button
									className="flex justify-center items-center bg-blue-200 p-2"
									onClick={() => {
										showEditar(props);
									}}
								>
									<EditOutlined className='text-lg'/>
								</Button>
							</Tooltip>
						) : null}
						{findUserRights('20') ? (
							<Tooltip placement="top" title={'Eliminar'} >
								<Popconfirm
									title="Borrar empresa"
									description={"¿Desea eliminar la empresa " + props.codeClient}
									onConfirm={() => {
										eliminarEmpresa(props)
									}}
									onCancel={()=>{}}
									okText="Eliminar"
									cancelText="Cancelar"
									okButtonProps={{className:'bg-[#1677ff]'}}
								>
									<Button className="flex justify-center items-center bg-red-200 p-2">
										<DeleteOutlined className='text-lg'/>
									</Button>
								</Popconfirm>
							</Tooltip>
						) : null}
					</div>
				);
			},
		},
	];

	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};

	const alertMessage = (type) =>{
		switch(type){
			case 'success': return 'Correcto';
			case 'warning': return 'Advertencia';
			case 'error': return 'Error';
			default: return 'Correcto';
		}
	}

	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])
	function openNotification () {
		api[colorMessage]({
		message: alertMessage(colorMessage),
		description: respMessage,
		placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

		return (
			<div>
				{contextHolder}
			<Spin spinning={loading} size='large'>
				<Table
					bordered={true}
					columns={columnas}
					dataSource={itemsCompanies}
					className="headerTable companiesAdmin
					3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[800px] md:min-w-[500px] sm:min-w-[300px] xs:min-w-[300px] min-w-300px]"
					rowClassName={'cursor-pointer p-2 text-sm'}
					rowKey={(r,i) => 'companiesAdmin_'+i}
					size='small'
					onRow={(record,i)=>{
						return{
							onClick:(event) => {
								$('.companiesAdmin tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
								$(`[data-row-key="companiesAdmin_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
							}
						}
					}}	
					
				/>

				<div className="row padding-btn-agregar">
					{findUserRights('18') ? (
						<Button 
						icon={<PlusSquareOutlined className='text-xl'/>}
						onClick={()=>{showNuevo()}}
						type='primary'
						className='bg-[#1677ff] flex justify-center items-center'
						>
						Agregar Empresa
						</Button>
					) : null}
				</div>
				<Modal 
					title={isEdit? 'Editar empresa' : 'Nueva empresa'}
					open={openModNuevo} 
					onOk={agregarEmpresa} 
					onCancel={closeNuevo} 
					cancelText='Cancelar'
					okText='Guardar'
					okButtonProps={{className:'bg-[#1677ff]'}}
				>
					<div className='flex flex-col gap-4'>
							<div>
								<label>Empresa</label>
								<Input
									placeholder="ej. EXCELLECE"
									onChange={(e) => codigoClienteHandler(e)}
									maxLength={50}
									value={_codigoCiente}
									/>
							</div>
							<div>
								<label>Descripción</label>
								<Input
									placeholder="Agregue una pequeña descripcion de la empresa"
									onChange={(e) => descripcionHandler(e)}
									maxLength={100}
									value={_descripcion}
									/>
							</div>
							<div>
								<Card title='Lista Plus'>
									<div className='flex gap-4'>
										<label>Compartir información</label>
										<Switch
											className='flex w-[100px]'
											unCheckedChildren="Desactivado"
											checkedChildren="Activado"
											checked={_shared_info}
											onChange={set_shared_info}
											/>
									</div>
								</Card>
							</div>
					</div>
				</Modal>
			</Spin>
			</div>
		);
}
export default React.memo(CompaniesTable)
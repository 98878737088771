
import React from "react";
import { Card, Drawer, Input, DatePicker, Switch, Select, Checkbox, Button } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function  DrawerClient(props){
    
    const optionsLock = [
      { label: "No bloquear", value: 0 },
      { label: "RRHH", value: 1 },
      { label: "Nómina", value: 2 },
      { label: "Mod. Admin.", value: 4 },
      { label: "Todo", value: 3 },
    ];

    const optnsCountry = [
      { label: "MEX (México)", value: "MEX" },
      { label: "CHL (Chíle)", value: "CHL" },
      { label: "CRI (Costa Rica)", value: "CRI" },
      { label: "JAM (Jamaica)", value: "JAM" },
      { label: "DOM (República Dominicana)", value: "DOM" },
      { label: "COL (Colombia)", value: "COL" },
      { label: "PER (Perú)", value: "PER" },
      { label: "LCA (Santa Lucía)", value: "LCA" },
      { label: "GRD (Granada)", value: "GRD" },
      { label: "ATG (Antigua y Barbuda)", value: "ATG" },
    ];

    const optnsLenguage = [
      { label: "ES-(Español)", value: "ES" },
      { label: "EN-(Inglés)", value: "EN" },
    ];

    const optnsTypeBranch = [
      { label: "Real", value: "1" },
      { label: "Demo", value: "0" },
    ];

    const optionsRfcEmisor = [
        { label: "ABRHIL SOFT S.C ASO070810PU0", value: "ASO070810PU0" },
        { label: "ABRL SISTEMAS & PROYECTOS SA DE CV ASA111013EH7", value: "ASA111013EH7", },
        { label: "JOVINT SA DE CV JOV121219BJ3", value: "JOV121219BJ3" },
        { label: "SGCH DEL SURESTE SA DE CV SSU1810314U8", value: "SSU1810314U8", },
        { label: "SERVICIOS ANHORT PENINSULAR SC SAP131211I49", value: "SAP131211I49", },
    ];

    //0=Sin programación 1= programado 2= en instalación 3=Liberado
    const optStatusPjc = [
        { label: "Sin programación", value: 0 },
        { label: "Programado", value: 1, },
        { label: "En instalación", value: 2 },
        { label: "Liberado", value: 3 },
    ];
    //0=Acumulado 1=Fijo con acumulado 2=Fijo sin acumulado
    const optInvoice = [
        { label: "Acumulado", value: 0 },
        { label: "Fijo con acumulado", value: 1, },
        { label: "Fijo sin acumulado", value: 2 },
    ];

    //únicamente se debe activar si el usuario selecciona la opción "Fijo sin acumulado", el cual se vuelve obligatorio capturar el comentario
    const requireComent = (select) => select === 2 ? true : false

    function BtnBranch(){
        return(
            <div className="flex gap-2 w-full justify-end">
                <div className="flex flex-col gap-2 w-1/2 items-end justify-end">
                    <Button 
                        icon={<PlusSquareOutlined className='text-xl'/>}
                        onClick={()=>{}}
                        type='primary'
                        className='bg-[#1677ff] flex justify-center items-center'
                    >
                        Sucursales
                    </Button>
                </div>
            </div>
        )
    }

    return (<>
        <Drawer
        title={props.title}
        className="3xl:min-w-[720px] 2xl:min-w-[720px] xl:min-w-[720px] lg:min-w-[720px] md:min-w-[720px] sm:min-w-[350px] xs:min-w-[350px] min-w-[350px]
        3xl:w-[40%] 2xl:w-[40%] xl:w-[40%] lg:w-[40%] md:w-[40%] sm:w-[80%] xs:w-[80%] w-[80%]"
        onClose={props.onClose}
        open={props.open}
        extra={props.extra}
        width={'auto'}
        >
            <Card title="" className="w-full">
                <div className="flex flex-col gap-3">
                    <div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                        <div className="flex flex-col gap-2 
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12" >
                            <label>Descripción</label>
                            <Input 
                                status={props.data.description === '' ? 'error' : ''}
                                pattern="[A-Z]" 
                                placeholder="Ej. EXCELLENCE"
                                onChange={(e) => props.data.descripcionHandler(e)}
                                maxLength={50}
                                showCount={true}
                                allowClear={true}
                                value={props.data.description}
                                
                            />
                        </div>
                        <div className="flex flex-col gap-2 
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12" >
                            <label>Alias</label>
                            <Input 
                                status={props.data.alias === '' ? 'error' : ''}
                                pattern="[A-Z]"
                                placeholder="Ej. HOTEL EXCELLENCE Z.H."
                                maxLength={50}
                                showCount={true}
                                allowClear={true}
                                value={props.data.alias}
                                onChange={(e) => props.data.aliasHandler(e)}
                                
                            />
                        </div>
                    </div>

                    <div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                        <div className="flex flex-col gap-2 
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label>Fecha de inicio y fin de contrato</label>
                                <RangePicker
                                    className={props.className||''}
                                    placeholder="Selecciona una fecha"
                                    format="DD/MM/YYYY"
                                    value={props.data.rangePicker.value||[dayjs(),dayjs()]}
                                    onChange={props.data.rangePicker.onChange}
                                />
                        </div>
                        <div className="flex flex-col gap-2 
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            {props.data.isEdit ? 
                              <div className="flex flex-col gap-2">
                                  <label ># empleados vig base real</label>
                                  <Input 
                                      value={props.data.tope._numEmpleados} 
                                      onChange={(e)=>props.data.tope.numEmployeesHandler(e)} 
                                      type="number"
                                      maxLength={6}
                                      placeholder="Ej. 1000"
                                      title="Número de empleados que tiene actualmente la sucursal"
                              />
                              </div> : '' }
                        </div>
                    </div>

                    <div className="flex gap-2 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                        <div className="flex gap-2 justify-center items-center 
                        3xl:w-1/5 2xl:w-1/5 xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label>Sin tope</label>
                            <Switch onChange={props.data.tope.onChange} checked={props.data.tope._noLimit} className="bg-slate-200"/>
                        </div>

                        <div className="flex flex-col gap-2 
                        3xl:w-2/5 2xl:w-2/5 xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-11/12 xs:w-11/12 w-11/12 ">
                            <label className=""># empleados perm por contrato</label>
                            <Input
                              status={props.data._maxEmpleados === '' ? 'error' : ''}
                              type="number"
                              placeholder="Ej. 1000"
                              onChange={(e) => props.data.maxEmployeesHandler(e)}
                              maxLength={5}
                              title="Número máximo de empleados permitido para la sucursal"
                              disabled={props.data.tope.noLimit}
                              value={props.data.tope._maxEmpleados}
                            />

                        </div>
                        
                        <div className="flex flex-col gap-2 
                        3xl:w-2/5 2xl:w-2/5 xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-11/12 xs:w-11/12 w-11/12 ">
                            <label className=""># anterior de empleados perm</label>
                            <Input
                              disabled={true}
                              value={props.data.tope._maxEmpleadosBefore ? props.data.tope._maxEmpleadosBefore : 0}
                            />

                        </div>

                    </div>

                    <div className="flex gap-2 w-full items-center 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                        <div className="flex gap-2 justify-center items-center
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label>Activo</label>
                            <Switch onChange={props.data.blocked.toggleActive} checked={props.data.blocked._activo} className="bg-slate-200" />
                        </div>
                        <div className="flex flex-col gap-2
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label >Bloquear</label>
                            <Select value={props.data.blocked._bloqueo} onChange={props.data.blocked.lockHandler} options={optionsLock}/>
                        </div>
                    </div>

                    <div className="flex gap-2 w-full 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                        <div className="flex flex-col gap-2 justify-center items-center
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <Checkbox onChange={props.data.notification.chkNotifyHndlr} checked={props.data.notification.notification}>Notificación Mensual</Checkbox>
                        </div>
                        <div className="flex flex-col gap-2
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label >País</label>
                            <Select value={props.data.country._pais} onChange={props.data.country.countryHndlr} options={optnsCountry}/>
                        </div>
                    </div>

                    <div className="flex gap-2 w-full">
                        <div className="flex flex-col gap-2
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label >Idioma</label>
                            <Select value={props.data.language._idioma} onChange={props.data.language.languageHndlr} options={optnsLenguage}/>
                        </div>
                        <div className="3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">       
                        <div className={`w-full flex flex-col gap-2 ${props.data.isEdit ? '' : 'hidden' }`}>
                            <label >URL API Local</label>
                            <Input 
                                value={props.data.api._localApiUrl} 
                                onChange={props.data.isEdit ? props.data.api.localApiUrlHndlr : ''} 
                                maxLength={100}
                                placeholder="ej: http://localhost/"
                          />
                        </div>
                        <div className={`w-full flex flex-col gap-2 ${props.data.isEdit ? 'hidden' : '' }`}>
                            <label >Corporativo</label>
                            <Select value={props.data.isEdit ? '' : props.data.corporation._dropValueSelectedCorp} 
                            onChange={props.data.isEdit ? '' : props.data.corporation.companyHandler} 
                            options={props.data.isEdit ? '' : props.data.corporation.companyOptions}/>
                        </div>
                        </div>
                    </div>
                    <div className="flex gap-2 w-full">
                        <div className="flex flex-col gap-2 w-1/2">
                            <label >RFC Emisor</label>
                            <Select value={props.data.rfc._rfcEmisor} onChange={props.data.rfc.rfcEmisorHndlr} options={optionsRfcEmisor}/>
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                            <label >Tipo de empresa</label>
                            <Select value={props.data.enterprise._realDemoBranch} onChange={props.data.enterprise.realDemoBranchHndlr} options={optnsTypeBranch}/>
                        </div>
                    </div>
                    <div className="flex gap-2 w-full">
                        <div className="flex flex-col gap-2 w-1/2">
                            <label >Estado de proyecto</label>
                            <Select value={props.data.invoice._statusProject} onChange={props.data.invoice.stausProjectHandler} options={optStatusPjc}/>
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                            <label >Tipo de factura</label>
                            <Select value={props.data.invoice._typeInvoice} onChange={ props.data.invoice.invoiceTypeHandler } options={optInvoice}/>
                        </div>
                    </div>
                    <div className="flex gap-2 w-full items-center 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col">
                        <div className="flex gap-2 justify-start items-start
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                            <label>Lista plus</label>
                            <Switch onChange={props.data.plusList.togglePlusList} checked={props.data.plusList._plusList} className="bg-slate-200" />
                        </div>
                        <div className="flex flex-col gap-2
                        3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
                        </div>
                    </div>
                    {props.data.invoice._requireComent ? <div className="flex gap-2 w-full">  
                        <div className={`w-full flex flex-col gap-2 `}>
                            <label >Escriba donde se obtiene la información:</label>
                            <Input 
                                value={props.data.invoice._invoiceComent} 
                                onChange={props.data.invoice.invoiceComentHandler} 
                                maxLength={200}
                                status={(props.data.invoice._invoiceComent).length === 0 ? "error" : ''}
                                placeholder="Comentario"
                          />
                        </div>
                    </div> : ''}
                    {/*props.data.isEdit ? <BtnBranch />:''*/}
                </div>
            </Card>
        </Drawer>
    </>);
}

export default React.memo(DrawerClient)
import React, { useState, useEffect, useRef } from 'react';
import './ComputersAdmin.css';
import '../MainStyle.css';
import { Button, Badge, Tooltip, Modal,Input, Popconfirm, Table, notification, Space, Spin } from "antd";
import { PlusSquareOutlined, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import $ from 'jquery'
import Highlighter from 'react-highlight-words';
import urlAPI from '../urlAPI/urlAPI.json';


function ComputersTable(){

	const [itemsComputers, setItemsComputers ]= useState([])

	const [openModNuevo, setOpenModNuevo ]= useState(false)

	const [_idComputadora, set_idComputadora ]= useState('')
	const [_serialNumber, set_serialNumber ]= useState('')
	const [_descripcion, set_descripcion ]= useState('')

	// VARIABLES PARA MOSTRAR MENSAJES
	const [existMessage, setExistMessage ]= useState(false)
	const [respMessage, setRespMessage ]= useState('')
	const [colorMessage, setColorMessage ]= useState('')

	const [classMessage, setClassMessage ]= useState(0)

	//PERMISOS DE USUARIO
	const [rights, setRights] = useState([])
	const [isEdit, setIsEdit] = useState(false);


	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const [loading, setLoading] = useState(false);

	function serialNumberHandler(e) {
		set_serialNumber( (e.target.value).toUpperCase() );
	}

	function descripcionHandler(e) {
		set_descripcion((e.target.value).toUpperCase())
	}

	useEffect(() => {
		loadComputers()
	},[])

	function loadComputers(){
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights)

		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'computers/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsComputers(json.data[0]);
			} 
			setLoading(false)
		});
	}

	function agregarComputadora() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevaComputadora = {
			idComputer: isEdit ? _idComputadora : '',
			serialNumber: _serialNumber,
			description: _descripcion,
			userCap: userLogged,
		};
		setLoading(true)
		fetch(urlAPI.url + 'computers/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevaComputadora),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadComputers();
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			limpiaCampos();
			closeNuevo();
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function eliminarComputadora(idComputer) {
		
		let id = idComputer;
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + `computers/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadComputers();
				setExistMessage(true)
				setRespMessage(json.data[0].mensaje)
				setColorMessage(tipoMensaje(json.data[0].numMensaje))
			} else {
				setExistMessage(true)
				setRespMessage(json.mensaje)
				setColorMessage('error')
			}
			transitionMsn();
			limpiarMensaje();
			setLoading(false)
		});
	}

	function limpiaCampos() {
		set_idComputadora('')
		set_serialNumber('')
		set_descripcion('')
	}

	function transitionMsn () {
		setTimeout(() => {
			setClassMessage(1);
		}, 4000);
	};

	function limpiarMensaje() {
		setTimeout(() => {
			setExistMessage(false);
			setRespMessage('');
			setColorMessage('');
			setClassMessage('');
		}, 6000);
	};

	function showNuevo(){
		setIsEdit(false);
		limpiaCampos();
		setOpenModNuevo(true);
	}

	function closeNuevo(){
		setIsEdit(false);
		setOpenModNuevo(false);
		limpiaCampos();
	};

	function showEditar(props){
		set_idComputadora(props.idComputer)
		set_serialNumber(props.serialNumber)
		set_descripcion(props.description)
		setIsEdit(true);
		setOpenModNuevo(true);
	}

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};


	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
	clearFilters();
	setSearchText('');
	};
	
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Input
			ref={searchInput}
			placeholder={`Buscar ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ marginBottom: 8, display: 'block', }}
			/>
			<Space>
			<Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
			>
				Buscar
			</Button>
			<Button
				onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				size="small"
				style={{
				width: 90,
				}}
			>
				Borrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				confirm({
					closeDropdown: false,
				});
				setSearchText(selectedKeys[0]);
				setSearchedColumn(dataIndex);
				}}
			>
				Filtrar
			</Button>
			<Button
				type="link"
				size="small"
				onClick={() => {
				close();
				}}
			>
				Cerrar
			</Button>
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
		filtered ?
		(<Badge className="animate-pulse" dot>
			<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>
		</Badge>):(<SearchOutlined
			className='w-[20px] h-[20px] text-[18px]'
			style={{
			color: filtered ? '#1890ff' : undefined,
			}}
			/>)
		),
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});
  
	const columnas = [
		{
			title: 'No. de Serie',
			dataIndex: 'serialNumber',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			...getColumnSearchProps('serialNumber'),
		},
		{
			title: 'Descripción',
			dataIndex: 'description',
			ellipsis: true,
			...getColumnSearchProps('description'),
		},
		{
			title: 'Acción',
			width:100,
			align:'center',
			ellipsis: true,
			render: (props) => {
				return (
					<div className='flex gap-2'>
						{findUserRights('32') ? (
							<Tooltip placement="top" title={'Editar'} >
								<Button
									className="flex justify-center items-center bg-blue-200 p-2"
									onClick={() => {
										showEditar(props);
									}}
								>
									<EditOutlined />
								</Button>
							</Tooltip>
						) : null}

						{findUserRights('33') ? (
								<Tooltip placement="top" title={'Eliminar'} >
									<Popconfirm
										title="Borrar computadora"
										description={'¿Desea eliminar la PC "'+ props.serialNumber +'" ?'}
										onConfirm={() => {
											eliminarComputadora(props.idComputer);
										}}
										onCancel={()=>{}}
										okText="Eliminar"
										cancelText="Cancelar"
										okButtonProps={{className:'bg-[#1677ff]'}}
									>
										<Button className="flex justify-center items-center bg-red-200 p-2">
											<DeleteOutlined />
										</Button>
									</Popconfirm>
								</Tooltip>
						) : null}
					</div>
				);
			},
		},
	];


	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};

    const alertMessage = (type) =>{
		switch(type){
		  case 'success': return 'Correcto';
		  case 'warning': return 'Advertencia';
		  case 'error': return 'Error';
		}
	  }

	//Alert
	const [api, contextHolder] = notification.useNotification();
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])
	function openNotification () {
		api[colorMessage]({
		message: alertMessage(colorMessage),
		description: respMessage,
		placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

	return (
		<div>
		{contextHolder}
		<Spin spinning={loading} size='large'>
			<Table
				bordered={true}
				columns={columnas}
				dataSource={itemsComputers}
				className='headerTable computersAdmin
				3xl:min-w-[1500px] 2xl:min-w-[1400px] xl:min-w-[1000px] lg:min-w-[800px] md:min-w-[500px] sm:min-w-[300px] xs:min-w-[300px] min-w-[300px]'
				size='small'
				rowKey={(r,i) => 'computersAdmin_'+i}
				onRow={(record,i)=>{
					return{
						onClick:(event) => {
							$('.computersAdmin tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
							$(`[data-row-key="computersAdmin_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
						}
					}
				}}	
			/>

			<div className="row padding-btn-agregar">
				{findUserRights('31') ? (
						<Button 
						icon={<PlusSquareOutlined className='text-xl'/>}
						onClick={()=>{showNuevo()}}
						type='primary'
						className='bg-[#1677ff] flex justify-center items-center'
						>
						Nueva PC
						</Button>
				) : null}
			</div>
			<Modal 
				title={isEdit? 'Editar computadora' : 'Nueva computadora'}
				open={openModNuevo} 
				onOk={agregarComputadora} 
				onCancel={closeNuevo} 
				cancelText='Cancelar'
				okText='Guardar'
				okButtonProps={{className:'bg-[#1677ff]'}}
			
			>
				<div className="div-editar">
					<div>
						<label>Número de serie</label>
						<Input
							className=""
							placeholder="Número de serie de la PC"
							onChange={(e) => serialNumberHandler(e)}
							maxLength={100}
							value={_serialNumber}
						/>
					</div>

					<div>
						<label>Descripción</label>
						<Input
							className=""
							placeholder="ej. PC contabilidad"
							onChange={(e) => descripcionHandler(e)}
							maxLength={100}
							value={_descripcion}
						/>
					</div>
				</div>
			</Modal>
		</Spin>	
		</div>
	);
}
export default React.memo(ComputersTable)
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Card, Badge, Popconfirm, Space, notification, Spin } from "antd";
import { AppstoreAddOutlined, SearchOutlined, ApiOutlined, PlusSquareOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
import ClientTable from '../../@ui/ClientTable';
import Table from '../../@ui/Table'
import Button from '../../@ui/Button';
import NotAllow from '../../@ui/NotAllow';


function CatRFC() {
	const [itemsRFC, setItemsRFC] = useState([]);

    const [_idRFC, set_idRFC] = useState('');
    const [_rfc, set_rfc] = useState('');
    const [_businessName, set_businessName] = useState('');
	// Variables para abrir y cerrar modal de RFC
	const [openModRFC, setOpenModRFC] = useState(false);
	const [_isEdit, set_isEdit] = useState(false);
	
	// VARIABLES PARA MOSTRAR MENSAJES
	const [respMessage,setRespMessage] = useState('');
	const [colorMessage,setColorMessage] = useState('');

	const [rights,setRights] = useState([]);
    
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	//Alert
	const [api, contextHolder] = notification.useNotification();

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);


	function logout(){
		userService.logout();
		navigate("/login");
	};

	//useEffect
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])
	
	// Llamadas al API
	useEffect(() =>{
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
		getRFC();
	},[])

	function getRFC(){
        
		let token = localStorage.getItem('x-access-token');
		if (!token) {logout()}
		setLoading(true)
		fetch(urlAPI.url + 'rfc/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					let data = json.data[0];
					setItemsRFC(data);
				} 
			} else {
				logout();
			}
			setLoading(false)
		});
	}
	
	//Agregar RFC
	function addEditRFC() {
		
		let token = localStorage.getItem('x-access-token');
		
		if(_rfc === ''){
			setColorMessage('error')
			setRespMessage('El campo RFC es obligatorios');
			return
		}
		let newRFC = {
			idRFC: _isEdit ? _idRFC : '',
			rfc: _rfc,
		};
        
		setLoading(true)
		fetch(urlAPI.url + 'rfc/', {
			method: _isEdit ? 'PUT' : 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(newRFC),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				getRFC();
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			cleenMessage();
            handlerClose();
			setLoading(false)
		});
	}


	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function cleenMessage(){
		setTimeout(() => {
			setRespMessage('');
			setColorMessage('');
		}, 6000);
	};

	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
	clearFilters();
	setSearchText('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Input
			ref={searchInput}
			placeholder={`Buscar ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ marginBottom: 8, display: 'block', }}
			/>
			<Space>
			<Button
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
				text="Buscar"
			/>

			<Button
				onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				size="small"
				style={{
				width: 90,
				}}
				text="Borrar"
			/>
			
			<Button
				type="link"
				size="small"
				onClick={() => {
				confirm({
					closeDropdown: false,
				});
				setSearchText(selectedKeys[0]);
				setSearchedColumn(dataIndex);
				}}
				text="Filtrar"
			/>
				
			<Button
				type="link"
				size="small"
				onClick={() => {
				close();
				}}
				text="Cerrar"
			/>
				
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
			filtered ?
			(<Badge className="animate-pulse" dot>
			  <SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />
			</Badge>):(<SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />)
		),
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});

	const columsRFC = [
		{
			title: 'RFC',
			dataIndex: 'RFC',
			key: 'RFC',
			className:'',
			...getColumnSearchProps('RFC'),
		},
		{
			title: 'Acciones',
			key: 'Acciones',
			align:'center',
			className:'p-0',
			width:50,
			render: (text,record, index) => {
				return (
                    <div className='flex gap-2'>

					<div className='flex justify-center p-0'>
						{findUserRights('65') ? (
                            <Button className="p-1 flex justify-center items-center bg-blue-200" 
                                icon={<EditOutlined className='text-lg'/>} 
                                onClick={()=>handlerEdit(record)}
                            />
						) : null}
					</div>
                        
					<div className='flex justify-center p-0'>
						{findUserRights('66') ? (
							<Popconfirm
								placement="top" 
								title="¿ Desea eliminar el RFC ?" 
								description={record.RFC} 
								onConfirm={()=>{deleteRFC(record.idRFC)}} 
								okText="Confirmar"
								cancelText="Cancelar"
								okButtonProps={{'className':'bg-red-500'}}
							>
								<Button className="p-1 flex justify-center items-center bg-red-200" 
                                    icon={<DeleteOutlined className='text-lg'/>} 
                                />
							</Popconfirm>
						) : null}
					</div>
                    </div>
				);
			},
		},
	];

	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};
	
    const alertMessage = (type) =>{
      switch(type){
        case 'success': return 'Correcto';
        case 'warning': return 'Advertencia';
        case 'error': return 'Error';
      }
    }
	
	function openNotification () {
		api[colorMessage]({
		  message: alertMessage(colorMessage),
		  description: respMessage,
		  placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

    function rfcHandler(e){e.target.value = e.target.value.toUpperCase(); set_rfc(e.target.value.toUpperCase());}
    function razonHandler(e){e.target.value = e.target.value; set_businessName(e.target.value);}

    function handlerAdd(){
        set_idRFC('');
        set_rfc('');
        //set_businessName('');
        set_isEdit(false);
        setOpenModRFC(true);
    }

    function handlerEdit(record){
        set_idRFC(record.idRFC);
        set_rfc(record.RFC);
        //set_businessName(record.businessName);
        set_isEdit(true);
        setOpenModRFC(true);
    }

    function handlerClose(){
        set_idRFC('');
        set_rfc('');
        //set_businessName('');
        set_isEdit(false);
        setOpenModRFC(false);
    }

    function deleteRFC(idRFC){

		let token = localStorage.getItem("x-access-token");
		setLoading(true)
		fetch(urlAPI.url + `rfc/${idRFC}`, {
		  method: "DELETE",
		  headers: { "x-access-token": token, },
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
		if (json.status) {
			setRespMessage(json.data[0].mensaje)
			setColorMessage(tipoMensaje(json.data[0].numMensaje));
			getRFC();
		} else {
			setRespMessage(json.mensaje)
			setColorMessage('error')
		}
		setLoading(false)
		});
    }

	return (
		<div  className='' >
			{contextHolder}
			<Spin  spinning={loading} size='large'>
			<div className='flex gap-2 justify-center'>
				{findUserRights('64') ? <div className='flex gap-2'>
				    <Card className='flex justify-center items-center' size='small'>
						<Table
							bordered={true}
							columns={columsRFC}
							dataSource={itemsRFC}
							className='headerTable rfcRow 
							3xl:min-w-[1500px] 2xl:min-w-[1400px] xl:min-w-[1000px] lg:min-w-[800px] md:min-w-[500px] sm:min-w-[300px] xs:min-w-[300px] min-w-[300px]'
							size='small'
							rowKey={(r,i) => 'rfcRow_'+i}
							onRow={(record,i)=>{
								return{
									onClick:(event) => {
										$('.rfcRow tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
										$(`[data-row-key="rfcRow_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
									}
								}
							}}	
						/>

						<div className="row padding-btn-agregar margin-agregar-msn">
							{findUserRights('64') ? (
								<Button
									className='bg-[#1677ff] flex justify-center items-center'
									type='primary'
									icon={<PlusSquareOutlined className='text-lg'/>}
									onClick={handlerAdd}
									text="Agregar RFC"
								/>
							) : (
								<Button
									className='bg-[#1677ff] flex justify-center items-center'
									type='primary'
									icon={<PlusSquareOutlined className='text-lg'/>}
									disabled={true}
									text="Agregar RFC"
								/>
                                )}
						</div>
					</Card>
				</div>:<NotAllow />}
			</div>
				
			{/* MODAL ASIGNAR NUEVO RFC */}
			<Modal open={openModRFC} onCancel={handlerClose} title="" okButtonProps={{className:'hidden'}}
			 className='3xl:min-w-[900px] 2xl:min-w-[900px] xl:min-w-[900px] lg:min-w-[600px] md:min-w-[400px] sm:min-w-[300px] xs:min-w-[300px] min-w-[300px]'>
				<div className='flex flex-col gap-2'>
					<div className=''>
						<Card title={_isEdit ? "Editar RFC" : "Agregar RFC"}> 
							<div className="flex gap-2">
								<div className='flex flex-col w-3/4 gap-2'>
									<div className=''>
										<label>RFC</label>
										<Input
											name='RFC'
											placeholder="RFC"
											maxLength="13"
											value={_rfc}
											onChange={(e)=>{rfcHandler(e)}}
										/>
									</div>

									{/*<div className=''>
										<label>Razon Social</label>
										<Input
											placeholder="ej. PC-1 de RRHH"
											value={_businessName}
											onChange={(e) => {razonHandler(e);}}
										/>
									</div>*/}
								</div>
								<div className='flex justify-end items-end'>
									{findUserRights('31') ? (
										<Button
											className='bg-[#1677ff]'
											type='primary'
											onClick={addEditRFC}
											text="Guardar"
										/>
									) : null}
								</div>
							</div>
						</Card>
					</div>
				</div>
			</Modal>
			</Spin>
		</div>
	);
}
export default React.memo(CatRFC);
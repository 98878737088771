
import React from "react";
import { Card, Modal, Input, Button } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const { TextArea } = Input;
function  ModalMessage(props){
    return (<>
        <Modal 
            title="¿Cual es el motivo del cambio?" 
            open={props.open} 
            onOk={props.onOk} 
            onCancel={props.onCancel}
            cancelText={'Cancelar'}
            okText={'Guardar'}
            okButtonProps={{className:'bg-[#1677ff]'}}
        >
            <Card>
                <div className="flex flex-col gap-2">
                    <label htmlFor="">Comentario</label>
                    <TextArea
                        onChange={props.data.commentHndlr}
                        value={props.data._comment}
                        maxLength={250}
                        autoSize={{
                            minRows:3,
                            maxRows:5,
                        }}
                        placeholder="Comentario"
                        />
                    <label htmlFor="">Folio HelpDesk</label>
                    <Input 
                        onChange={props.data.folioHelpDeskHndlr}
                        readOnly={props.data._folioRequired ? false : true}
                        placeholder="Ej. ABR-JJZMYA6"
                        maxLength={100}
                    />
                </div>
            </Card>
        </Modal>
    </>);
}

export default React.memo(ModalMessage)
{/**MODAL AGREGAR COMENTARIO 
        <Modal open={openModComment} size="tiny">
          <Header className="head-modals">
            <Icon.Group>
              <Icon name="commenting" />
              <Icon corner="bottom right" name="add"></Icon>
            </Icon.Group>
            &nbsp;&nbsp;¿Cual es el motivo del cambio?
          </Header>

          <Modal.Content>
            <div className="div-editar">
              <Form className="form-editar">
                <Form.Field>
                  <label>Comentario</label>
                  <TextArea
                    className="txtArea-style"
                    placeholder="Agrega el motivo del cambio..."
                    onChange={(e) => this.commentHndlr(e)}
                    maxLength="250"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Folio HelpDesk</label>
                  <Input
                    value={this.state._folioRequired ? null : "Sin folio"}
                    readOnly={this.state._folioRequired ? false : true}
                    onChange={(e) => this.folioHelpDeskHndlr(e)}
                    placeholder="Ej. ABR-JJZMYA6"
                    maxLength="100"
                  />
                </Form.Field>
              </Form>
            </div>
          </Modal.Content>
          <Modal.Actions className="modal-bottom">
            <Button
              animated
              className="btn-styles"
              onClick={this.cancelComment}
            >
              <Button.Content visible>Cancelar</Button.Content>
              <Button.Content hidden>
                <Icon name="close" />
              </Button.Content>
            </Button>

            <Button
              animated
              primary
              className="btn-styles"
              onClick={(e) => this.modificarSucursal(e)}
              disabled={!this.state._comment || this._folioHelpDesk}
            >
              <Button.Content visible>Guardar</Button.Content>
              <Button.Content hidden>
                <Icon name="save" />
              </Button.Content>
            </Button>
          </Modal.Actions>
        </Modal>

*/}
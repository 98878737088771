import React, { useState, useEffect, useRef } from 'react';
import { Modal, Badge, Col, Input, Card, Switch, Popconfirm, Space, notification, Spin, Button } from "antd";
import { AppstoreAddOutlined, SearchOutlined, ApiOutlined, PlusSquareOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
import ClientTable from '../../@ui/ClientTable';
import Table from '../../@ui/Table'
import ButtonUI from '../../@ui/Button';
import NotAllow from '../../@ui/NotAllow';
import { CSVLink } from 'react-csv';


function RelBranchComputer() {
	const [itemsCompanies,setItemsCompanies] = useState([]);
	const [itemsBranches,setItemsBranches] = useState([]);
	const [itemsRelBranchComputer,setItemsRelBranchComputer] = useState([]);
	const [itemsCatComputers,setItemsCatComputers] = useState([]);

	// Variables para seleccionar que mensaje tiene la empresa o contrato
	const [_idCompanyBranchComputer,set_idCompanyBranchComputer] = useState('');
	const [_idCompanyBranch,set_idCompanyBranch] = useState('');
	const [_nameBranch, set_nameBranch] = useState('Computadoras')
	const [_filteredData,set_filteredData] = useState([]);

	// Variables para la tabla de mensajes
	const [_idComputerTbl,set_idComputerTbl] = useState('');
	const [_serialNumberTbl,set_serialNumberTbl] = useState('');
	const [_descriptionTbl,set_descriptionTbl] = useState('');

	// Variables modal nueva computadora
	const [_serialNumberNew,set_serialNumberNew] = useState('');
	const [_descripcionNew,set_descripcionNew] = useState('');

	// Variables para abrir y cerrar los modales de mensajes
	const [openModNewComputer,setOpenModNewComputer] = useState(false);
	const [_showAllBranches,set_showAllBranches] = useState(false);
	const [_showAllComputers,set_showAllComputers] = useState(false);
	
	// VARIABLES PARA MOSTRAR MENSAJES
	const [respMessage,setRespMessage] = useState('');
	const [colorMessage,setColorMessage] = useState('');

	const [rights,setRights] = useState([]);
	const [_idBranch ,set_idBranch] = useState(0);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	//Alert
	const [api, contextHolder] = notification.useNotification();

	//filtro
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);


	function logout(){
		userService.logout();
		navigate("/login");
	};

	//useEffect
	useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])
	useEffect(()=>{if(itemsBranches==[]){setItemsCatComputers([])}},[itemsBranches])
	// Llamadas al API
	useEffect(() =>{
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
		getCompanies();
	},[])

	function getCompanies(){

		let token = localStorage.getItem('x-access-token');
		if (!token) {logout()}
		setLoading(true)
		fetch(urlAPI.url + 'companiesView/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					let arrCompanies = [];
					let data = json.data[0];

					data.forEach((element) => {
						arrCompanies.push({
							key: element.idCompany,
							label: element.codeClient,
						});
					});
					setItemsCompanies(arrCompanies);
				} 
			} else {
				logout();
			}
			setLoading(false)
		});
	}
	
	function loadBranches(idCompany) {
		let id = idCompany;
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `companyBranch/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsBranches(json.data[0]);
			}
			setLoading(false)
		});
	}
	
	useEffect(()=>{},[itemsRelBranchComputer])

	function loadComputers(idCompanyBranch) {
		let id = idCompanyBranch;
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `companyBranchComputer/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsRelBranchComputer(json.data[0]);
				var filteredData = JSON.parse(JSON.stringify(json.data[0]));
				set_filteredData(filteredData)
			}
			setLoading(false)
		});
	}

	function loadCatComputer() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `companyBranchComputerNotView/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsCatComputers(json.data[0]);
			}
			setLoading(false)
		});
	}

	function loadCatAllComputers() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `computers/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsCatComputers(json.data[0]);
			}
			setLoading(false)
		});
	}

	function loadAllBranches() {
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `branchesView/`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsBranches(json.data[0]);
			}
			setLoading(false)
		});
	}


	function agregarRelCompBranCompu() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');

		let nuevoRelComputer = {
			idCompanyBranchComputer: '',
			idCompanyBranch: _idCompanyBranch,
			idComputer: _idComputerTbl,
			userCap: userLogged,
		};

		setLoading(true)
		fetch(urlAPI.url + 'companyBranchComputer/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevoRelComputer),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadComputers(_idCompanyBranch);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			cleanFieldsNewRelCompu();
			closeNewComputer();
			cleenMessage();
			setLoading(false)
		});
	}

	function eliminarComputadora() {
		let id = _idCompanyBranchComputer;
		let token = localStorage.getItem('x-access-token');

		setLoading(true)
		fetch(urlAPI.url + `companyBranchComputer/${id}`, {
			method: 'DELETE',
			headers: {
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadComputers(_idCompanyBranch);
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			cleanFieldsNewRelCompu();
			cleenMessage();
			setLoading(false)
		});
	}
	//Agregar computaora
	function addNewComputer() {
		
		let token = localStorage.getItem('x-access-token');
		let userLogged = localStorage.getItem('username');
		if(_serialNumberNew === '' || _descripcionNew === ''){
			setColorMessage('error')
			setRespMessage('Los campos Numero de serie y descrición son obligatorios');
			return
		}
		let nuevaComputadora = {
			idComputer: '',
			serialNumber: _serialNumberNew,
			description: _descripcionNew,
			userCap: userLogged,
		};

		setLoading(true)
		fetch(urlAPI.url + 'computers/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(nuevaComputadora),
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				loadCatComputer();
				setRespMessage(json.data[0].mensaje);
				setColorMessage(tipoMensaje(json.data[0].numMensaje));
			} else {
				setRespMessage(json.mensaje);
				setColorMessage('error');
			}
			cleanFieldsNewCompMod();
			cleenMessage();

			setLoading(false)
		});
	}


	useEffect(()=>{
		if(_idCompanyBranch === '' || _idComputerTbl === '')return;
		agregarRelCompBranCompu();
	},[_idComputerTbl])

	function addComputer(idComputer, serialNumber, description) {
		set_idComputerTbl(idComputer);
		set_serialNumberTbl(serialNumber);
		set_descriptionTbl(description);
	}

	const headers = [
		{ label: 'No. Serie', key: 'serialNumber' },
		{ label: 'Descripción', key: 'description' },
	]
	function exportCompBranch(){
		let dataOrginal = _filteredData;

		dataOrginal.forEach((elemOri) => {
			elemOri.serialNumber = elemOri.serialNumber.toString();
		});
		set_filteredData(dataOrginal)
	}

	// Funciones para abrir los modales
	function showNewComputer() {
		loadCatComputer();
		setOpenModNewComputer(true);
		cleanFieldsNewRelCompu();
	}
	function closeNewComputer(){
		setOpenModNewComputer(false)
		cleanFieldsNewRelCompu();		
	};


	// HANDLRES NUEVA COMPUTADORA
	function serialNumbHndlrNewComp(e) {
		set_serialNumberNew(e.target.value);
	}
	function descHndlrNewComp(e) {
		set_descripcionNew(e.target.value)
	}
	const companyHandler = (value) => {
		
		toggleBranches(false)
		if (value.key === '' || value.key === 0) {
			set_idCompanyBranch('');
			setItemsBranches([]);
			setItemsRelBranchComputer([]);
		} else {
			loadBranches(value.key);
		}
	}


	function cleanFieldsNewRelCompu() {
		set_idComputerTbl('');
		set_serialNumberTbl('');
		set_descriptionTbl('');
	}

	function cleanFieldsNewCompMod() {
		set_serialNumberNew('');
		set_descripcionNew('');

	}

	const toggleBranches = (checked) => {
		set_showAllBranches(checked)
		setItemsRelBranchComputer([]);
	};

	useEffect(()=>{
		validaAllBranches();
	},[_showAllBranches]);

	function validaAllBranches(){
		if (_showAllBranches === true) {
			loadAllBranches();
			set_idCompanyBranch('');
		} else {
			setItemsBranches([]);
			set_idCompanyBranch('');
		}
	};


	const toggleShowComputers = (checked) => {set_showAllComputers(checked);};
	useEffect(()=>{loadComputerToggle();},[_showAllComputers])

	function loadComputerToggle(){
		if (_showAllComputers === true) {
			loadCatAllComputers();
		} else {
			loadCatComputer();
		}
	};

	function tipoMensaje(numMensaje){
		switch (numMensaje) {
			case 0: {
				return 'success';
			}
			case 1: {
				return 'warning';
			}
			case 2: {
				return 'error';
			}
			default: {
				return 'success';
			}
		}
	};

	function cleenMessage(){
		setTimeout(() => {
			setRespMessage('');
			setColorMessage('');
		}, 6000);
	};

	//Filtrar
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	  };
	const handleReset = (clearFilters) => {
	clearFilters();
	setSearchText('');
	};
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
		<div style={{ padding: 8, }}	onKeyDown={(e) => e.stopPropagation()} >
			<Input
			ref={searchInput}
			placeholder={`Buscar ${dataIndex}`}
			value={selectedKeys[0]}
			onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
			onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
			style={{ marginBottom: 8, display: 'block', }}
			/>
			<Space>
			<ButtonUI
				type="primary"
				onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
				icon={<SearchOutlined />}
				size="small"
				style={{
				width: 90,
				}}
				className="bg-[#1677ff]"
				text="Buscar"
			/>

			<ButtonUI
				onClick={() => {clearFilters && handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex)}}
				size="small"
				style={{
				width: 90,
				}}
				text="Borrar"
			/>
			
			<ButtonUI
				type="link"
				size="small"
				onClick={() => {
				confirm({
					closeDropdown: false,
				});
				setSearchText(selectedKeys[0]);
				setSearchedColumn(dataIndex);
				}}
				text="Filtrar"
			/>
				
			<ButtonUI
				type="link"
				size="small"
				onClick={() => {
				close();
				}}
				text="Cerrar"
			/>
				
			</Space>
		</div>
		),
		filterIcon: (filtered) => (
			filtered ?
			(<Badge className="animate-pulse" dot>
			  <SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />
			</Badge>):(<SearchOutlined
				className='w-[20px] h-[20px] text-[18px]'
				style={{
				color: filtered ? '#1890ff' : undefined,
				}}
			  />)
		),
		onFilter: (value, record) =>
		{
			return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
		},
		onFilterDropdownOpenChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput.current?.select(), 100);
		}
		},
		render: (text) =>
		searchedColumn === dataIndex ? (
			<Highlighter
			highlightStyle={{
				backgroundColor: '#ffc069',
				padding: 0,
			}}
			searchWords={[searchText]}
			autoEscape
			textToHighlight={text ? text.toString() : ''}
			/>
		) : (
			text
		),
	});

	const columnsRelBranchComputer = [
		{
			title: 'No. Serie',
			dataIndex: 'serialNumber',
			ellipsis: true,
			...getColumnSearchProps('serialNumber'),
		},
		{
			title: 'Descripción',
			dataIndex: 'description',
			ellipsis: true,
			...getColumnSearchProps('description'),
		},
		{
			title: 'Desligar',
			key: 'desligar',
			align:'center',
			className:'p-0 ',
			width:100,
			ellipsis: true,
			render: (text,record, index) => {
				return (
					<div className='flex justify-center p-0'>
						{findUserRights('5') ? (
							<Popconfirm
								placement="top" 
								title="¿ Desea desligar la siguiente computadora ?" 
								description={record.serialNumber} 
								onConfirm={()=>{
									eliminarComputadora()
								}} 
								okText="Confirmar"
								cancelText="Cancelar"
								okButtonProps={{'className':'bg-[#1677ff]'}}
							>
								<Button className="p-1 flex justify-center items-center bg-red-200 w-3/4" 
								onClick={(event)=>{
									//G9N0CV13K475388
									set_idCompanyBranchComputer(record.idCompanyBranchComputer)
									set_serialNumberTbl(record.serialNumber);
								}}>
									<ApiOutlined className='3xl:text-xl 2xl:text-xl xl:text-xl lg:text-xl md:text-lg sm:text-md'/>
								</Button>
							</Popconfirm>
						) : null}
					</div>
				);
			},
		},
	];

	const columnsComputers = [
		{
			title: 'No. Serie',
			dataIndex: 'serialNumber',
			key: 'serialNumber',		
			ellipsis: true,
			...getColumnSearchProps('serialNumber'),
		
		},
		{
			title: 'Descripción',
			dataIndex: 'description',
			key: 'description',
			ellipsis: true,
			...getColumnSearchProps('description'),
		},
		{
			title: 'Asociar',
			className:'flex justify-center items-center',
			align:'center',
			width:100,
			ellipsis: true,
			render: (text, record, index ) => {
				return (
					<div className='flex justify-center'>
						<ButtonUI
							icon={<AppstoreAddOutlined className='text-lg'/>}
							className="flex justify-center items-center bg-blue-200"
							onClick={() =>{
								addComputer(
									record.idComputer,
									record.serialNumber,
									record.description
								)
							}}
						/>
					</div>
				);
			},
		},
	];

	const onRowClickBranches = (rowInfo) => {
		set_nameBranch(rowInfo.description)
		set_idBranch(rowInfo.idBranch);
		set_idCompanyBranch(rowInfo.idCompanyBranch);
		loadComputers(rowInfo.idCompanyBranch);
	};

	let clearDropDown = () => {
		toggleBranches(false);
		setItemsBranches([]);
		setItemsRelBranchComputer([]);
		set_idCompanyBranch('');
	};

	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};
	
	const items = {
		items:itemsCompanies,
		onClick:companyHandler
	}

    const alertMessage = (type) =>{
      switch(type){
        case 'success': return 'Correcto';
        case 'warning': return 'Advertencia';
        case 'error': return 'Error';
      }
    }
	
	function openNotification () {
		api[colorMessage]({
		  message: alertMessage(colorMessage),
		  description: respMessage,
		  placement:'bottomRight',
		});
		setColorMessage('')
		setRespMessage('')
	}

	return (
		<div  className='' >
			{contextHolder}
			<Spin  spinning={loading} size='large'>
			<div className='flex justify-center gap-2'>
				<div className='flex gap-2 flex-wrap justify-center
					3xl:min-w-[500px] 2xl:min-w-[500px] xl:min-w-[500px] lg:min-w-[300px] 
				'>
					{findUserRights('2') ?(
						<ClientTable onRowClickBranches={onRowClickBranches} clearDropDown={clearDropDown}/>
					):(
						<NotAllow/>
					)}
					<Card title="Computadoras ligadas al cliente" size='small'
						className="3xl:min-w-[500px] 2xl:min-w-[500px] xl:min-w-[500px] lg:min-w-[300px] w-8/9"
					>
						{findUserRights('2')?(
						<Table
							bordered={true}
							columns={columnsRelBranchComputer}
							dataSource={itemsRelBranchComputer}
							className='
								headerTable branchComputer
								3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[1000px] md:min-w-[500px]
								3xl:text-[14px] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[10px]
								md:max-w-[400px] sm:max-w-[350px]
								'
							size='small'
							rowKey={(r, i) => 'bcom_'+i}
							onRow={(record,i)=>{
								return{
									onClick:(event) => {
										$('.branchComputer tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
										$(`[data-row-key="bcom_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
									}
								}
							}}	
						/>):(
							<NotAllow/>
						)}

						<div className="row padding-btn-agregar margin-agregar-msn flex gap-4">
							{findUserRights('3') ? (
								<ButtonUI
									className='bg-[#1677ff] flex justify-center items-center'
									type='primary'
									icon={<PlusSquareOutlined className='text-lg'/>}
									disabled={!_idCompanyBranch}
									onClick={showNewComputer}
									text="Asignar computadora"
								/>
							) : null}
							{findUserRights('3') ? (
								<CSVLink filename={_nameBranch} data={_filteredData} headers={headers} onClick={exportCompBranch} className='flex w-fit'>
									<ButtonUI
										className='bg-[#1677ff] flex justify-center items-center'
										type='primary'
										icon={<DownloadOutlined className='text-lg'/>}
										disabled={!_idCompanyBranch}
										text="Exportar"
									/>
								</CSVLink>
							) : null}
						</div>
					</Card>
				</div>
			</div>
				
			{/* MODAL ASIGNAR NUEVA COMPUTADORA */}
			<Modal 
				open={openModNewComputer} 
				onCancel={()=>{setOpenModNewComputer(false)}} 
				title="Asignar una computadora" 
				className='3xl:min-w-[900px] 2xl:min-w-[900px] xl:min-w-[900px] lg:min-w-[600px] '
				okButtonProps={{className:'bg-[#1677ff]'}}
			>
				<div className='flex flex-col gap-2'>
					<div className=''>
						<Card title="Agregar computadora"> 
							<div className="flex gap-2 flex-wrap">
								<div className='flex flex-col w-3/4 gap-2'>
									<div className=''>
										<label htmlFor="serialNumber">Número de serie</label>
										<Input
											name='serialNumber'
											placeholder="Número de serie de la PC"
											maxLength={100}
											value={_serialNumberNew}
											onChange={(e)=>{serialNumbHndlrNewComp(e)}}
										/>
									</div>

									<div className=''>
										<label htmlFor="description">Descripción</label>
										<Input
											placeholder="ej. PC-1 de RRHH"
											value={_descripcionNew}
											onChange={(val) => {descHndlrNewComp(val);}}
										/>
									</div>
								</div>
								<div className='flex justify-end items-end'>
									{findUserRights('31') ? (
										<ButtonUI
											className='bg-[#1677ff]'
											type='primary'
											onClick={addNewComputer}
											text="Agregar PC"
										/>
									) : null}
								</div>
							</div>
						</Card>
					</div>
					<div>
						<Card title="Lista de computadoras" className='flex flex-col' >
							<div className=' flex flex-col gap-2'>
								<div className=' flex gap-2'>
									<Switch onChange={toggleShowComputers} className='bg-slate-200'/>
									<label className="">Mostrar todas las computadoras</label>
								</div>
								<Table 
									bordered={true}
									className='min-w-[100px] headerTable computerList w-8/9 sm:max-w-[300px] xs:max-w-[300px]'
									columns={columnsComputers}
									dataSource={itemsCatComputers}
									size='small'
									rowKey={(r, i) => 'comList_'+i}
									onRow={(record,i)=>{
										return{
											onClick:(event) => {
												$('.computerList tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
												$(`[data-row-key="comList_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
											}
										}
									}}	
								/>
							</div>
						</Card>
					</div>
				</div>
			</Modal>
			</Spin>
		</div>
	);
}
export default React.memo(RelBranchComputer);
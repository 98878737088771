import React from "react";
import { Card, Row, Col } from "antd";

function NotAllowMsj(){
    
    return(
        <div className="flex justify-center items-center">
            <Card columns={3}>
                <Row className="row-notAllowed">
                    <Col className="column-text">
                        <label className="texto-notAllowed">No Permitido</label>
                        <br />
                        <br />
                        <label className="texto-error">Usted no tiene acceso a esta ruta</label>
                        <br />
                        <br />
                        <label className="texto-mensaje">
                            Póngase en contacto con el administrador de la web para que le otorgue
                            los permisos necesarios
                        </label>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default React.memo(NotAllowMsj);
import React, { useEffect, useState } from 'react';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { CSVLink, CSVDownload } from 'react-csv';
import { Card, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import Table from '../../@ui/Table';
import Button from '../../@ui/Button';
import { CloseOutlined, DownloadOutlined, FilterFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
import NotAllow from '../../@ui/NotAllow';

function BranchReport(){
	
	const[ itemsBranches, setItemsBranches] =  useState([]);
	const[ itemsCorporate, setItemsCorporate] =  useState([]);

	// PROPIEDADES ONROWCLICK
	const [rowSelectedBranch, setRowSelectedBranch] = useState(-1);

	// VARIABLE PARA ALMACENAR EL VALOR DEL DROPDOWN SELECCIONADO
	const [dropEndDateFilterValueSelected, setDropEndDateFilterValueSelected] = useState('');
	const [dropNumEmployFilterValueSelected, setDropNumEmployFilterValueSelected] = useState('');
	const [dropCorpFilterValSelected, setDropCorpFilterValSelected] = useState('');

	// VARIABLE PARA FILTRAR POR NOTIFICACIÓN MENSUAL
	const [_filterNotMonth, set_filterNotMonth] = useState(false);
	const [_filteredData, set_filteredData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [ _exportData, set_exportData ] = useState([])
	const [ rights, setRights] = useState([])

	const headers = [
		{ label: 'Id', key: 'idBranch' },
		{ label: 'Descripción', key: 'description' },
		{ label: 'Inicio Contrato', key: 'startDate' },
		{ label: 'Fin Contrato', key: 'endDate' },
		{ label: '#Empleados Vigentes', key: 'numEmployees' },
		{ label: 'Max Empleados Permitidos', key: 'maxEmployees' },
		{ label: 'Bloquear', key: 'lock' },
		{ label: 'Activo', key: 'active' },
		{ label: 'Notificación Mensual', key: 'monthlyNotify' },
	]

	useEffect(()=>{
		getBranch();
		loadCorporate();
		let stringUserRights = localStorage.getItem('userRights')
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
	},[]);
	
	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	function getBranch() {
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'branches/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut == undefined) {
				if (json.aut == false) logout();
				if (json.status) {
					setItemsBranches(json.data[0]);
				} 
			} else {
				logout();
			}
			setLoading(false)
		});
	}

	function filterBranches() {
		let token = localStorage.getItem('x-access-token');
		let id = dropCorpFilterValSelected;
		if (id == undefined || id == '') id=0;
		setLoading(true)
		fetch(urlAPI.url + `companyBranchReport/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
			.catch((err) => console.log(err))
			.then((res) => res.json())
			.then((json) => {
				if (json.aut === undefined) {
					if (json.aut === false) logout();
					if (json.status) {
						setItemsBranches(json.data[0])
						var filteredData = JSON.parse(JSON.stringify(json.data[0]));
						set_filteredData(filteredData)
					}
				} else {
					logout();
				}
				setLoading(false)
			});
	}

	function loadCorporate() {
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'companiesView/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					let arrCorporate = [];
					let data = json.data[0];
					data.forEach((element) => {
						arrCorporate.push({
							key: element.idCompany,
							value: element.idCompany,
							label: element.codeClient,
						});
					});
					setItemsCorporate(arrCorporate);
				} 
			} else {
				logout();
			}
			setLoading(false)
		});
	}

	function getReportsEndDate(filterType) {
		if(filterType === '')return
		let token = localStorage.getItem('x-access-token');
		let idCompany = dropCorpFilterValSelected;
		if (idCompany == undefined || idCompany == '') idCompany = 0;
		setLoading(true)
		fetch(urlAPI.url + `reportingEndDate/${filterType}/${idCompany}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsBranches(json.data[0]);
				var filteredData = JSON.parse(JSON.stringify(json.data[0]));
				set_filteredData(filteredData)
			}
			setLoading(false)
		});
	}

	function getReportsNumEmployees(filterType) {
		if(filterType === '')return
		let token = localStorage.getItem('x-access-token');
		let idCompany = dropCorpFilterValSelected;
		if (idCompany == undefined || idCompany == '') idCompany = 0;
		setLoading(true)
		fetch(urlAPI.url + `reportingNumEmployees/${filterType}/${idCompany}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setItemsBranches(json.data[0]);
				var filteredData = JSON.parse(JSON.stringify(json.data[0]));
				set_filteredData(filteredData);
			}
			setLoading(false)
		});
	}

	/**Filtrar por corporativo*/
	useEffect(()=>{  filterBranches(); },[dropCorpFilterValSelected]);
	function dropCorpFilterHandl( value ) { setDropCorpFilterValSelected(value); }
	function clearDropDownCorporate(){ setDropCorpFilterValSelected(''); };
	
	/**Filtrar por contratos vencidos */	
	const endDateFilters = [
		{ key: 1, value: 1, label: 'Vencen hoy', },
		{ key: 2, value: 2, label: 'Todos los vencidos', },
		{ key: 3, value: 3, label: 'Vencen en 15 días', },
		{ key: 4, value: 4, label: 'Vencen en 30 días', },
		{ key: 5, value: 5, label: 'Vencen este mes', },
	];
	
	useEffect(()=>{getReportsEndDate(dropEndDateFilterValueSelected);},[dropEndDateFilterValueSelected]);
	function dropdownEndDateFilterHandler(value) { setDropEndDateFilterValueSelected(value); }
	function clearDropDownEndDateFilter(){ setDropEndDateFilterValueSelected(''); filterBranches(); };

	/**Filtrar por numero de empleados */
	const NumEmployesFilters = [
		{ key: 1, value: 1, label: 'Están en el limite', },
		{ key: 2, value: 2, label: 'Pasaron el limite', },
		{ key: 3, value: 3, label: 'Proximos a llegar al limite (5 empleados)', },
		{ key: 4, value: 4, label: 'Proximos a llegar al limite (10 empleados)', },
	];

	useEffect(()=>{getReportsNumEmployees(dropNumEmployFilterValueSelected);},[dropNumEmployFilterValueSelected])
	function dropdownNumEmployFilterHandler(value) { setDropNumEmployFilterValueSelected(value); }
	function clearDropNumEmployFilter(){ setDropNumEmployFilterValueSelected(''); filterBranches(); };

	const corporateOptions = itemsCorporate;
	let filteredData = [];

	const columnas = [
		{
			title: 'Descripción',
			dataIndex: 'description',
			ellipsis: true,
		},
		{
			title: 'Inicio Contrato',
			dataIndex: 'startDate',
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
		{
			title: 'Fin Contrato',
			dataIndex: 'endDate',
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return convertToDate(props);
			},
		},
		{
			title: '#Emple Vig',
			dataIndex: 'numEmployees',
			align:'center',
			ellipsis: true,
		},
		{
			title: 'Max Emple Perm',
			dataIndex: 'maxEmployees',
			align:'center',
			ellipsis: true,
		},
		{
			title: 'Bloquear',
			dataIndex: 'lock',
			align:'left',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return validLock(props);
			},
		},
		{
			title: 'Activo',
			dataIndex: 'active',
			align:'center',
			ellipsis: true,
			render: (props) => {
				return validaBlocking(props);
			},
		},
		{
			title: 'Noti. Mens.',
			dataIndex: 'monthlyNotify',
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (props) => {
				return notiMensToVarchar(props);
			},
		},
	];

	function notiMensToVarchar(campo){
		switch (campo) {
			case 1: {
				return 'SI';
			}
			case 0: {
				return 'NO';
			}
			default: {
				return 'N/A';
			}
		}
	};

	function validaBlocking(campo){
		switch (campo) {
			case 0: {
				return 'NO';
			}
			case 1: {
				return 'SI';
			}
			default: {
				return 'N/A';
			}
		}
	};

	function convertToDate(date){
		return dayjs(date).format('DD/MM/YYYY'); 
	};

	function validLock(campo){
		switch (campo) {
			case 0: {
				return 'No bloquear';
			}
			case 1: {
				return 'RRHH';
			}
			case 2: {
				return 'Nómina';
			}
			case 3: {
				return 'Todo';
			}
			default: {
				return 'N/A';
			}
		}
	};

	useEffect(()=>{converData()},[_filteredData])
	function converData(){
		let dataOrginal = _filteredData;
		
		dataOrginal.forEach((elemOri) => {
			elemOri.startDate = convertToDate(elemOri.startDate);
			elemOri.endDate = convertToDate(elemOri.endDate);
			elemOri.lock = validLock(elemOri.lock);
			elemOri.active = validaBlocking(elemOri.active);
			elemOri.monthlyNotify = notiMensToVarchar(elemOri.monthlyNotify);
		});
		filteredData = dataOrginal;
	}
	function parseReport(){
		set_exportData(filteredData);
	};

	let findUserRights = (findRight) => {		
		let userRights = rights;

		for (let i in userRights) {			
			let x = userRights[i].idOperation;

			if(x === findRight){					
				return true
			}
		}
	}

	return (
		<div>
			<Spin spinning={loading} size='large'>
				<Card size='small'>
					{findUserRights(70) ? <div className='flex flex-col gap-4' >
						<div className='flex flex-wrap gap-16 items-center
						3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-col'>
							{/* FILTRO POR FIN DE CORPORATIVO */}
							<div className="flex gap-4 justify-center items-center 
							3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
								<label>Filtro por corporativo</label>
								<Select
									allowClear
									options={corporateOptions}
									value={dropCorpFilterValSelected}
									onChange={dropCorpFilterHandl}
									className="w-[150px]"
									placeholder="Selecciona un corporativo"
									clearIcon={<CloseOutlined className='text-[14px] text-black' />}
									onClear={clearDropDownCorporate}
								/>
							</div>
						
							{/* FILTRO POR FIN DE CONTRATO */}
							<div className="flex gap-4 justify-center items-center 
							3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
								<label>Filtro por contratos vencidos</label>
								<Select
									allowClear
									value={dropEndDateFilterValueSelected}
									options={endDateFilters}
									onChange={dropdownEndDateFilterHandler}
									className="w-[150px]"
									placeholder="Selecciona un filtro"
									clearIcon={<CloseOutlined className='text-[14px] text-black' />}
									onClear={clearDropDownEndDateFilter}
								/>
							</div>
							
							{/* FILTRO POR NUMERO DE TRABAJADORES */}
							<div className="flex gap-4 justify-center items-center 
							3xl:w-1/4 2xl:w-1/4 xl:w-1/4 lg:w-1/4 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12">
								<label>Filtro por No. de empleados</label>
								<Select
									allowClear
									value={dropNumEmployFilterValueSelected}
									options={NumEmployesFilters}
									onChange={dropdownNumEmployFilterHandler}
									className="w-[150px]"
									placeholder="Selecciona un filtro"
									clearIcon={<CloseOutlined className='text-[14px] text-black' />}
									onClear={clearDropNumEmployFilter}
								/>
							</div>
							
						</div>
						<div>
							<Table
								columns={columnas}
								dataSource={itemsBranches}
								className="headerTable w-full branchReport"
								size="small"
								rowKey={(r,i) => 'branchReport_'+i}
								onRow={(record,i)=>{
									return{
										onClick:(event) => {
											$('.branchReport tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
											$(`[data-row-key="branchReport_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
										}
									}
								}}	
							/>
						</div>
						<CSVLink data={_exportData} headers={headers} onClick={parseReport} filename='Reporte de sucursales.csv' className='flex w-fit'>
							<Button 
								text='Exportar'
								icon={<DownloadOutlined className='text-xl'/>}
								className='text-white bg-[#1890ff] flex justify-center items-center'
							/>
						</CSVLink>
					</div>: <NotAllow />}
				</Card>
			</Spin>
		</div>
	);
}

export default React.memo(BranchReport)
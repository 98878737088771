import React, { useEffect, useState } from 'react';
import urlAPI from '../urlAPI/urlAPI.json';
import { Input, notification, Descriptions,  Tooltip, Popconfirm, Card, Button, Spin, Table } from "antd";
import { userService } from '../services/services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import NotPermission from '../notPermission';
import NotAllow from '../../@ui/NotAllow';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

function ConfigAdmin(){
  const [_userTry, set_userTry] = useState(1);
  const [_ipTry, set_ipTry] = useState(1);
  const [_phoneTry, set_phoneTry] = useState(1);
  const [_dataTry, set_dataTry] = useState([]);
  const [_isEdit, set_isEdit] = useState(false);
  const [rights, setRights] = useState([]);
	// VARIABLES PARA MOSTRAR MENSAJES
	const [respMessage, setRespMessage] = useState('');
	const [colorMessage, setColorMessage] = useState('');
  const [loading, setLoading] = useState(false);

	let stringUserRights, userRights;

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	useEffect(() => {
		if (localStorage.getItem('x-access-token') === null) {logout ()}
		stringUserRights = localStorage.getItem('userRights');
		userRights = JSON.parse(stringUserRights);
		setRights(userRights);
    loadTry();
	},[]);

	let findUserRights = (findRight) => {
		let userRights = rights;
		for (let i in userRights) {
			let x = userRights[i].idOperation;
			if (x === findRight) {
				return true;
			}
		}
	};

  function loadTry(){
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'loginRecord/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
        set_userTry(json.data.userTry)
        set_ipTry(json.data.ipTry)
        set_phoneTry(json.data.phoneTry)
        set_dataTry(json.data.register)
			}
			setLoading(false)
		});
  }
  const verifyTry = () => {
   if((_ipTry > 0 && _ipTry < 100) && (_userTry > 0 && _userTry < 100)) return true
   else return false 
  }

  const saveChanges = () => {
    if(!verifyTry) return
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'loginRecord/', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
      body: JSON.stringify({ userTry:_userTry, ipTry:_ipTry, phoneTry:_phoneTry })
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.status) {
				setRespMessage(json.data[0].mensaje)
				setColorMessage('success')
        set_isEdit(false)
			}else{
				setRespMessage(json.data[0].mensaje)
				setColorMessage('error')
      }

			setLoading(false)
		});
  }

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'nameUser',
      key: 'nameUser',
    },
    {
      title: 'Fecha',
      dataIndex: 'tryDate',
      key: 'tryDate',
      render:(date)=>moment(date).format('DD/MM/YYYY')
    },
    {
      title: 'Navegador',
      dataIndex: 'browser',
      key: 'browser',
    },
    {
      title: 'Ip',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Accesso',
      dataIndex: 'access',
      key: 'access',
      render: access => {
        return (<div className='flex justify-center items-center'>
        {access ? 
          <CheckCircleOutlined className='text-lg text-green-300'/> 
          : 
          <CloseCircleOutlined  className='text-lg text-red-300'/>}
        </div> )
      }
    },
  ]
  //Alert message
  const alertMessage = (type) =>{
    switch(type){
      case 'success': return 'Correcto';
      case 'warning': return 'Advertencia';
      case 'error': return 'Error';
    }
  }
    
  const [api, contextHolder] = notification.useNotification();
  useEffect(()=>{if(respMessage!='')openNotification()},[respMessage])

  function openNotification () {
    api[colorMessage]({
    message: alertMessage(colorMessage),
    description: respMessage,
    placement:'bottomRight',
    });
    setColorMessage('')
    setRespMessage('')
  }

  return (
    <div>
    {contextHolder}
    {findUserRights(72) ? <Spin spinning={loading} size='large'>	
      <div className=''>
        <div className='config_off'>
          <Descriptions 
            bordered
            title="Configuraciones de intentos"
            extra={
              <Button type="primary" 
                className='bg-[#1677ff] max-w-[250px]' 
                disabled={!findUserRights(72)}
                onClick={()=>_isEdit ? saveChanges() : set_isEdit(true)}>
                  {_isEdit ? 'Guardar' : 'Editar'}
              </Button>
            }
          >
            <Descriptions.Item 
              key= 'userTry'
              label= 'Intentos por usuario'
              className=""
            >
              {_isEdit ? <Input value={_userTry} onChange={(e)=>set_userTry(e.target.value)}/> : _userTry}
            </Descriptions.Item>
            <Descriptions.Item 
              key= 'phoneTry'
              label= 'Intentos para clave de telefono'
              className=""
            >
              {_isEdit ? <Input value={_phoneTry} onChange={(e)=>set_phoneTry(e.target.value)}/> :  _phoneTry}
            </Descriptions.Item>
            <Descriptions.Item 
              key= 'ipTry'
              label= 'Intentos por IP'
              className=""
            >
              {_isEdit ? <Input value={_ipTry} onChange={(e)=>set_ipTry(e.target.value)}/> :  _ipTry}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Card title="Registros del login" className='' >
            <Table 
              columns={columns} 
              dataSource={_dataTry} 
              size='small' 
              className='headerTable' 
            />
        </Card>
      </div>
    </Spin> : <NotAllow />	}
    </div>
  );
}

export default React.memo(ConfigAdmin)
import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';


const { RangePicker } = DatePicker;
function DateRangerPicker(props){
    
    return(
        <div className='flex flex-col gap-2'>
            <label>{props.title}</label>
            <RangePicker
                className={props.className||''}
                placeholder={props.placeholder||''}
                disabled={props.disabled||false}
                format={props.format||''}
                value={props.value||[dayjs(),dayjs()]}
                onChange={props.onChange}
            />
        </div>
    );
}

export default React.memo(DateRangerPicker)
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import urlAPI from '../urlAPI/urlAPI.json';
import { userService } from '../services/services';
import { CSVLink, CSVDownload } from 'react-csv';
//import { Grid, Button, Icon } from 'semantic-ui-react';
import { Card, Spin } from 'antd';
import Button from '../../@ui/Button';
import { DownloadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import NotAllow from '../../@ui/NotAllow';

function ExportReports() {

	//Arreglo donde se guarda la infor de la consulta 1
	const [_arrClientesWebService, set_arrClientesWebService] = useState([]);
	const [_arrClientesWebServiceNoData, set_arrClientesWebServiceNoData] = useState([]);

	//Arreglo donde se guarda la infor de la consulta 2
	const [_arrClientesWebServiceRFC, set_arrClientesWebServiceRFC] = useState([]);
	const [_arrClientesWebServiceRFCNoData, set_arrClientesWebServiceRFCNoData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [ rights, setRights] = useState([])

	//header del xml 1
	let headersClientesWebService = [
		{ label: 'Corporativo', key: 'Corporativo' },
		{ label: 'Descripción Corporativo', key: 'DescripCorporativo' },
		{ label: 'Fecha de Captura', key: 'dateCapture' },
		{ label: 'Sucursal', key: 'Sucursal' },
		{ label: 'Fecha Inicio', key: 'FechaInicio' },
		{ label: 'Fecha Fin', key: 'FechaFin' },
		{ label: 'Empresa Vigente Día', key: 'EmpVigDia' },
		{ label: 'Activo', key: 'Activo' },
		{ label: 'Notificación Mensual', key: 'NotificacionMensual' },
		{ label: 'País', key: 'Pais' },
		{ label: 'Lenguaje', key: 'Lenguaje' },
		{ label: 'Revisar Tope', key: 'RevisarTope' },
	]

	//header del xml 2
	let headersClientesWebServiceRFC = [
		{ label: 'Corporativo', key: 'Corporativo' },
		{ label: 'Descripción Corporativo', key: 'DescripCorporativo' },
		{ label: 'Fecha de Captura', key: 'dateCapture' },
		{ label: 'Sucursal', key: 'Sucursal' },
		{ label: 'Fecha Inicio', key: 'startDate' },
		{ label: 'Fecha Fin', key: 'endDate' },
		{ label: 'Activo', key: 'active' },
		{ label: 'RFC', key: 'RFC' },
		{ label: 'País', key: 'country' },
	]

	useEffect(() => {
		getClientesWebService();		
		getClientesWebServiceRFC();
		let stringUserRights = localStorage.getItem('userRights')
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
	},[]);


	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};
	// Obtiene los clientes del webService
	function getClientesWebService() {
		let token = localStorage.getItem('x-access-token');
		setLoading(true)
		fetch(urlAPI.url + 'clientesWebService/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {

					let dataOrginal = json.data[0]

					dataOrginal.forEach((elemOri) => {
								elemOri.dateCapture = convertToDate(elemOri.dateCapture);
								elemOri.FechaInicio = convertToDate(elemOri.FechaInicio);
								elemOri.FechaFin = convertToDate(elemOri.FechaFin);
								elemOri.Activo = validaBlocking(elemOri.Activo);
								elemOri.NotificacionMensual = notiMensToVarchar(elemOri.NotificacionMensual);
								elemOri.RevisarTope = validaBlocking(elemOri.RevisarTope);
							});

							set_arrClientesWebService(dataOrginal)


				} else {
					set_arrClientesWebServiceNoData(json.mensaje)
				}
			} else {
				logout();
			}
			setLoading(false)
		});
			
	}

	// Obtiene los clientes del webService
	function getClientesWebServiceRFC() {
		let token = localStorage.getItem('x-access-token');
		setLoading(true)

		fetch(urlAPI.url + 'clientesWebServiceRFC/', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
		})
		.catch((err) => console.log(err))
		.then((res) => res.json())
		.then((json) => {
			if (json.aut === undefined) {
				if (json.aut === false) logout();
				if (json.status) {
					let dataOrginal2 = json.data[0];

					dataOrginal2.forEach((elemOri) => {
						elemOri.dateCapture = convertToDate(elemOri.dateCapture);
						elemOri.startDate = convertToDate(elemOri.startDate);
						elemOri.endDate = convertToDate(elemOri.endDate);
						elemOri.active = validaBlocking(elemOri.active);
					});

					set_arrClientesWebServiceRFC(dataOrginal2)
				} else {
					set_arrClientesWebServiceRFCNoData(json.mensaje)
				}
			} else {
				logout();
			}
			setLoading(false)
		});
	}

	function convertToDate(date) {
		let fecha = moment(date).format('DD/MM/YYYY');
		return fecha;
	}

	function validaBlocking(campo) {
		switch (campo) {
			case 0: {
				return 'NO';
			}
			case 1: {
				return 'SI';
			}
			default: {
				return 'N/A';
			}
		}
	}

	function notiMensToVarchar(campo) {
		switch (campo) {
			case 1: {
				return 'SI';
			}
			case 0: {
				return 'NO';
			}
			default: {
				return 'N/A';
			}
		}
	}

	let clientesWebService = _arrClientesWebService;
	let clientesWebServiceRFC = _arrClientesWebServiceRFC;

	let findUserRights = (findRight) => {		
		let userRights = rights;

		for (let i in userRights) {			
			let x = userRights[i].idOperation;

			if(x === findRight){					
				return true
			}
		}
	}
	
	return (
		<div className='flex justify-center items-center w-full'>
			<Spin spinning={loading} size='large'>
				<Card title="" className='flex w-full justify-center items-center flex-wrap p-0'
				bodyStyle={{padding:'5px'}}>
					{findUserRights(71) ? <div className='flex justify-center flex-wrap gap-2 w-full
					3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col flex-row
					3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[900px] md:min-w-[700px] sm:min-w-[350px] xs:min-w-[350px]'>
						<div className="flex justify-center
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12 ">
							<CSVLink
								data={clientesWebService}
								headers={headersClientesWebService}
								onClick={getClientesWebService}
								filename={"ClientesWebService.csv"}
								className='flex w-full'
							>
								<Button 
									className="text-white bg-[#1890ff] flex justify-center items-center w-full"
									icon={<DownloadOutlined className='text-xl'/>}
									text="clientesWebService"
								/>
							</CSVLink>
						</div>

						<div className="flex justify-center
						3xl:w-1/2 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-11/12 xs:w-11/12 w-11/12 ">
							<CSVLink
								data={clientesWebServiceRFC}
								headers={headersClientesWebServiceRFC}
								onClick={getClientesWebServiceRFC}
								filename={"ClientesWebServiceRFC.csv"}
								className='flex w-full'
							>
								<Button animated className="text-white bg-[#1890ff] flex justify-center items-center w-full"
									icon={<DownloadOutlined className='text-xl'/>}
									text="clientesWebServiceRFC"
								/>
							</CSVLink>
						</div>
					</div> : <NotAllow />}
				</Card>
			</Spin>
		</div>
	);
}

export default React.memo(ExportReports)
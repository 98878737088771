import React from 'react';
import { Card, Descriptions, Tag } from 'antd';
import Table from '../../@ui/Table';
import $ from 'jquery';
import moment from 'moment';
import { SafetyOutlined } from '@ant-design/icons';

function Worker(props){
	const arrData = props.data.movement;
	const country = props.country;
	const checkConfig = (country) => {
		switch(country){
			case 'MEX': return 'RFC';
			case 'CHL': return 'RFC';
			case 'CRI': return 'RFC';
			case 'JAM': return 'RFC';
			case 'DOM': return 'Cedula'; 
			case 'COL': return 'RFC';
			case 'PER': return 'RFC';
			case 'LCA': return 'RFC';
			case 'GRD': return 'RFC';
			default: return 'RFC';
		}
	}

	const columns = [
		{
			title: 'Empresa',
			dataIndex: 'branch',
			key: 'branch',
			className:'p-2 text-sm',
			width:200,
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'Puesto',
			dataIndex: 'jobPosition',
			key: 'jobPosition',
			className:'p-2 text-sm',
			width:200,
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
		},
		{
			title: 'Fecha de ingreso',
			dataIndex: 'admissionDate',
			key: 'admissionDate',
			className:'p-2 text-sm',
			width:200,
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (prop) => {
				return moment(prop).format('DD/MM/YYYY')
			},
		},
		{
			title: 'Fecha de baja',
			dataIndex: 'leavingDate',
			key: 'leavingDate',
			className:'p-2 text-sm',
			width:200,
			align:'center',
			ellipsis: true,
			responsive: ['xxl', 'xl', 'lg'],
			render: (prop) => {
				return moment(prop).format('DD/MM/YYYY')
			},
		},
		{
			title: 'Tipo de baja',
			dataIndex: 'lowType',
			key: 'lowType',
			className:'p-2 text-sm',
			width:200,
			align:'center',
			ellipsis: true,
		},
		{
			title: 'Comentario',
			dataIndex: 'reason',
			key: 'reason',
			className:'p-2 text-sm',
			align:'center',
			with:400,
			ellipsis: true,
		},
		{
			title: 'Reingresable',
			key: 'reentry',
			className:'p-2 text-sm',
			align:'center',
			width:100,
			ellipsis: true,
			render: (prop) => {
			let color = prop.reentry === 'Sí' ? 'cyan' : 'volcano'
				return (<div>
				<Tag color={color} key={props} onClick={()=>{props.openModal(prop)}} title='Modificar Reingreso'>{prop.reentry.toUpperCase()}</Tag>
				{prop.autorization ? <Tag color='cyan' key={'delete_aut_'+prop?.autorization} onClick={()=>{props.openModalDelete(prop)}} title='Tiene Autorización de Reingreso'><SafetyOutlined /></Tag> : null}
				</div>)
			},
		},
	]

  return (
    <div className='flex'>
			<Card title="" className=''>
				<Descriptions className=''>
					<Descriptions.Item label="Nombre" key="name" className="">
            {props.data.worker?.name}
          </Descriptions.Item>
					<Descriptions.Item label="Apellido Paterno" key="firstSurname" className="">
            {props.data.worker?.firstSurname}
          </Descriptions.Item>
					<Descriptions.Item label="Apellido Materno" key="secondSurname" className="">
            {props.data.worker?.secondSurname}
          </Descriptions.Item>
					{country === 'DOM' ? '' : <Descriptions.Item label="CURP" key="curp" className="">
            {props.data.worker?.CURP}
          </Descriptions.Item>}
					<Descriptions.Item label={checkConfig(country)} key="rfc" className="">
            {props.data.worker?.RFC}
          </Descriptions.Item>
					{country === 'DOM' ? '' : <Descriptions.Item label="NSS" key="nss" className="">
            {props.data.worker?.NSS}
          </Descriptions.Item>}
				</Descriptions>
					<Table
						bordered={true}
						columns={columns}
						dataSource={arrData}
						className="headerTable workerMovements
						3xl:min-w-[1000px] 2xl:min-w-[1000px] xl:min-w-[1000px] lg:min-w-[700px] md:min-w-[300px]  sm:min-w-[300px] min-w-[300px] "
						rowClassName={'cursor-pointer p-2 text-sm'}
						rowKey={(r,i) => 'wm_'+i}
						size='small'
						onRow={(record,i)=>{
							return{
								onClick:(event) => {
									$('.workerMovements tr').removeClass('bg-blue-400 hover:bg-blue-500 ');
									$(`[data-row-key="wm_${i}"]`).addClass('bg-blue-400 hover:bg-blue-500 ');						
								}
							}
						}}	
						
					/>
			</Card>
		</div>
  );
}
export default React.memo(Worker); 
import React, { useState, useEffect } from 'react';
import CompaniesTable from '../companiesAdmin/CompaniesAdmin';
import ComputersTable from '../computersAdmin/ComputersAdmin';
import BranchesTable from '../branchesAdmin/BranchesAdmin';
import CatRFC from '../RFCAdmin';
// import BillingConceptsTable from '../billingConceptsAdmin/BillingConceptsAdmin';
import MessagesTable from '../messagesAdmin/MessagesAdmin';
import Structure from '../structure/structure';
import MainMenu from '../menu/Menu';
import './Catalogue.css';
import { Tabs, Card } from 'antd';
import NotAllow from '../../@ui/NotAllow';
import NotPermission from '../notPermission';


function Catalogue() {
	const [rights, setRights] = useState([]);
	let stringUserRights, userRights, userType;

	useEffect(() => {
		stringUserRights = localStorage.getItem('userRights');
		userType = localStorage.getItem('type');
		userRights = JSON.parse(stringUserRights);
		setRights(userRights);
	},[])

	let findUserRights = (findRight) => {
		let userRights = rights;
		for (let i in userRights) {
			let x = userRights[i].idOperation;
			if (x == findRight) {
				return true;
			}
		}
	};
	const paneles = [
		{
			label: 'Estructura',
			key: 'Estructura',
			children:
				<div className="">
					{findUserRights('11') ? (
						<Structure />
					) : (
						<NotAllow />
					)}
				</div>
		},
		{
			label: 'Empresa',
			key: 'Empresa',
			children:
				<div className="sinBordes">
					{findUserRights('16') ? 
					<div>{<CompaniesTable />}</div> :
					<NotAllow />
					}
				</div>
		},
		{
			label: 'Clientes',
			key: 'Clientes',
			children: 
				<div className="sinBordes">
					{findUserRights('21') ? <BranchesTable /> : <NotAllow />}
				</div>
		},
		{
			label: 'RFC',
			key: 'RFC',
			children: 
				<div className="sinBordes">
					{findUserRights('21') ? <CatRFC /> : <NotAllow />}
				</div>
		},
		{
			label: 'Computadoras',
			key: 'Computadoras',
			children:
				<div className="sinBordes">
					{findUserRights('29') ? <ComputersTable /> : <NotAllow />}
				</div>
		},
		{
			label: 'Mensajes',
			key: 'Mensajes',
			children:
				<div className="sinBordes">
					{findUserRights('39') ? <MessagesTable/> : <NotAllow />}
				</div>
		},
	];
	
	return (
		<div className='w-full flex flex-col gap-4'>
			{findUserRights('10') ? 
			<div className='w-full flex flex-col gap-2'>
				<MainMenu activeItem={'catalogos'} />
				<div className='w-full flex justify-center'>
					<Card title="Administración de Catalogos" className="flex flex-col w-11/12">
						<Tabs items={paneles} destroyInactiveTabPane={true} />
					</Card>
				</div>
			</div> : <NotPermission />}
		</div>
	);
}
export default React.memo(Catalogue);
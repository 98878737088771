import React, { useEffect } from 'react';
import './UsersAdmin.css';
import UsersTable from './UsersTable';
import MainMenu from '../menu/Menu';
import RolesAdmin from '../rolesAdmin/RolesAdmin';
import ConfigAdmin from '../ConfigAdmin'
import { Card, Tabs } from 'antd';
import { userService } from '../services/services';
import { useNavigate } from 'react-router-dom';

function UsersAdmin(){

	const navigate = useNavigate();
	function logout (){
		userService.logout();
		navigate("/login");
	};

	useEffect(() => {		
		if (localStorage.getItem('x-access-token') === null) {logout ()}
	},[]);

	const panels = [
		{
			label:'Usuarios',
			key:'Usuarios',
			children:
				<div className='w-full flex justify-center'>
					<Card title="Administración de Usuarios" className='flex flex-col w-full' >
						<UsersTable  className="flex w-11/12"/>
					</Card>
				</div>
		},
		{
			label:'Roles',
			key:'Roles',
			children:<RolesAdmin />
		},
		{
			label:'Configuraciones',
			key:'config',
			children:<ConfigAdmin />
		}
	];
	return (
		<div className='w-full flex flex-col gap-5'>
			<div>
				<MainMenu activeItem={'administracion'} />
				<div className="w-full flex justify-center">
					<Tabs items={panels} className='flex p-2 rounded-lg w-11/12' /> 
				</div>
			</div>
		</div>
	);

}

export default React.memo(UsersAdmin)

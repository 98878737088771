import React, { Component, useEffect, useState } from 'react';
//import { Button, Grid } from 'semantic-ui-react';
import './NotAllowed.css';
import { userService } from '../services/services';
import { Button, Grid } from 'antd';


function NotAllowed() {
	const [ rights, setRights ] = useState ([]);

	useEffect(() => {
		let stringUserRights = localStorage.getItem('userRights');
		let userRights = JSON.parse(stringUserRights);
		setRights(userRights);
	},[])

	function redirect(newUrl) {
		let oldRoute = this.props.location.state.from.pathname;
		this.props.history.replace({ pathname: oldRoute, pathname: newUrl });
	}

	function logout(newUrl) {
		userService.logout();
		let oldRoute = this.props.location.state.from.pathname;
		this.props.history.replace({ pathname: oldRoute, pathname: newUrl });
	}

	function goT0(goTo) {
		switch (goTo) {
			case 1: {
				return this.redirect('/startPage');
			}
			case 2: {
				return this.redirect('/');
			}
			case 3: {
				return this.redirect('/catalogue');
			}
			case 4: {
				return this.redirect('/usersAdmin');
			}
			case 5: {
				return this.redirect('/reporting');
			}
			case 6: {
				return this.logout('/login');
			}
		}
	}

	let findUserRights = (findRight) => {
		let userRights = rights;

		for (let i in userRights) {
			let x = userRights[i].idOperation;

			if (x == findRight) {
				return true;
			}
		}
	};

	return (
		<div>
			<Grid columns={3}>
				<Grid.Row className="row-texto-Bienvenido">
					<Grid.Column className="column-text">
						<label className="texto-notAllowed">No Permitido</label>
						<br />
						<br />
						<label className="texto-error">Usted no tiene acceso a esta ruta</label>
						<br />
						<br />
						<label className="texto-mensaje">
							Póngase en contacto con el administrador de la web para que le otorgue los permisos
							necesarios
						</label>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="">
					<Grid.Column className="columna-botones">
						<label className="texto-botones">Ir a:</label>
						<br />
						<br />
						<Button.Group compact>
							<Button onClick={(e) => goT0(1)}>Inicio</Button>
							{findUserRights('1') ? (
								<Button onClick={(e) => goT0(2)}>Módulo</Button>
							) : null}
							{findUserRights('10') ? (
								<Button onClick={(e) => goT0(3)}>Catalogo</Button>
							) : null}
							{findUserRights('44') ? (
								<Button onClick={(e) => goT0(4)}>Administración</Button>
							) : null}
							{findUserRights('49') ? (
								<Button onClick={(e) => goT0(5)}>Reportes</Button>
							) : null}
							<Button negative onClick={(e) => goT0(6)}>
								Salir
							</Button>
						</Button.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
}

export default React.memo(NotAllowed)